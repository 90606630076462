import styled from '@emotion/styled';

import { Flex } from 'antd';

export const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

export const Description = styled.span`
  width: 445px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;
