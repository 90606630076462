import { FC } from 'react';

import { Flex } from 'antd';

import { StatusCircle } from '@entities';

import * as S from './styled';

type DocumentExpenseFormProps = {
  isEqual: boolean;
};

const DocumentExpenseForm: FC<DocumentExpenseFormProps> = ({ isEqual }) => (
  <Flex gap={8}>
    <StatusCircle status={isEqual ? 'completed' : 'errorsFound'} />
    <S.Status>{isEqual ? 'Amounts match' : 'Amounts do not match'}</S.Status>
  </Flex>
);

export default DocumentExpenseForm;
