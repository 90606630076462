import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 20px 4px;
  position: relative;
`;

export const Edit = styled.a`
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 10px;
  right: 20px;
`;
