//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminAccountsController_getAccounts,
  Response$AdminAccountsController_getAccounts$Status$200,
  ResponseContentType$AdminAccountsController_getAccounts,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminAccountsController_getAccounts = (options: {
  params: Params$AdminAccountsController_getAccounts['parameter'];
  config?: QueryOptions<
    Response$AdminAccountsController_getAccounts$Status$200[ResponseContentType$AdminAccountsController_getAccounts]
  >;
}) =>
  useApiClient<
    Params$AdminAccountsController_getAccounts,
    Response$AdminAccountsController_getAccounts$Status$200[ResponseContentType$AdminAccountsController_getAccounts]
  >({
    method: 'AdminAccountsController_getAccounts',
    params: { parameter: options.params },
    config: options.config,
  });
