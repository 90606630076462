//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminAuthController_login,
  Response$AdminAuthController_login$Status$200,
  ResponseContentType$AdminAuthController_login,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminAuthController_login = () =>
  useApiClientMutation<
    Params$AdminAuthController_login,
    Response$AdminAuthController_login$Status$200[ResponseContentType$AdminAuthController_login]
  >({ method: 'AdminAuthController_login' });
