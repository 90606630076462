import styled from '@emotion/styled';
import { Button, Flex } from 'antd';

export const EmptyList = styled(Flex)`
  width: 100%;
  height: 554px;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

export const EmptyPreview = styled.div`
  width: 497px;
  height: 622px;
  position: relative;
`;

export const MoreIcon = styled.div`
  color: ${({ theme }) => theme.colors.blackSecondary};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Actions = styled.div`
  width: 192px;
`;

export const Edit = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue200};
  }
`;

export const Remove = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.red};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue200};
  }
`;

export const ModalTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const UploadButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: ${({theme}) => theme.colors.primary};
  
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
`

export const ModalButton = styled(Button)<{isCancel?: boolean}>`
  padding: 13px 28px;
  height: 48px;
  border-color: ${({theme, isCancel}) => isCancel ? theme.colors.link : ''};
  border-width: 2px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  
  color: ${({theme, isCancel}) => isCancel ? theme.colors.link : theme.colors.white};
`
