import { FC, ReactNode, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconArrowLeft } from '@assets';

import * as S from './styled';

type GoBackProps = {
  text?: ReactNode;
};

const GoBack: FC<GoBackProps> = ({ text }) => {
  const navigate = useNavigate();

  const handleBack = (e: MouseEvent) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <S.Link onClick={handleBack}>
      <IconArrowLeft /> {text || 'Go Back'}
    </S.Link>
  );
};

export default GoBack;
