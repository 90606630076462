import { Button, Flex, Form, Input, Modal, type ModalProps } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAdminTaskController_create } from '@api-client/generated/AdminTaskController/create';
import { useAdminUserController_getUsers } from '@api-client/generated/AdminUserController/getUsers';
import { Schemas } from '@api-client/generated/types';
import { Select } from '@components';
import { useTasksStore } from '@store';

import * as S from './styled';

type ChatModalCreateNewProps = {
  onCancel: () => void;
} & ModalProps;

const ChatModalCreateNew: FC<ChatModalCreateNewProps> = ({
  open,
  onCancel,
  ...rest
}) => {
  const { companyId, id: taskId } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { addTask } = useTasksStore();

  const { data: users = [], isPending: loadingUsers } =
    useAdminUserController_getUsers();

  const { mutate: createTask } = useAdminTaskController_create();

  const handleSubmit = (values: Schemas.AdminTaskDto) =>
    createTask(
      {
        requestBody: values,
      },
      {
        onSuccess: (task) => {
          navigate(`/chat-tasks/${companyId}/chats/${taskId}`);

          addTask(task);

          form.resetFields();
          onCancel();
        },
      }
    );

  return (
    <Modal
      width={504}
      open={open}
      title="Create new chat"
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <S.Inner gap={24} vertical>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            isPrivate: false,
          }}
        >
          {/* <Form.Item
            label="Topic"
            name="topic"
            rules={[
              {
                required: true,
                message: 'Select topic from the list',
              },
            ]}
          >
            <Select
              options={[
                {
                  label: 'Taxes',
                  value: 'taxes',
                },
                {
                  label: 'System Error',
                  value: 'system_error',
                },
                {
                  label: 'VAT',
                  value: 'vat',
                },
                {
                  label: 'Payroll',
                  value: 'payroll',
                },
              ]}
              size="large"
            />
          </Form.Item> */}

          <Form.Item label="Title" name="title" rules={[{ required: true }]}>
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Comment message for client"
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} size="large" />
          </Form.Item>

          <Form.Item label="Assignee" name="assigneeId">
            <Select
              options={users.map((user) => ({
                label: user.fullName,
                value: user.id,
              }))}
              size="large"
              loading={loadingUsers}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>

          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={onCancel} size="large">
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" size="large">
                Create chat and notify client
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </S.Inner>
    </Modal>
  );
};

export default ChatModalCreateNew;
