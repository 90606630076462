import { Layout, type LayoutProps } from 'antd';

import styled from '@emotion/styled';

export const Header = styled(Layout.Header)<LayoutProps>`
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const UserName = styled.span`
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.white};
`;

export const CompanyName = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.white};
`;

export const Logout = styled.a`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 80px;

  &:hover {
    opacity: 0.75;
  }
`;

export const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.textGrey};
`;
