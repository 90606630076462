import { Button, Flex, Modal, type ModalProps } from 'antd';
import { FC, useState } from 'react';

import { RadioGroup } from '@components';

import { reportErrors } from '@constants';

import * as S from './styled';

type DocumentExpenseReportProps = {
  onCancel: () => void;
  onRequest: (code: string) => void;
} & ModalProps;

const defaultSelectedReportError = reportErrors[0].value;

const DocumentExpenseReport: FC<DocumentExpenseReportProps> = ({
  open,
  onCancel,
  onRequest,
  ...rest
}) => {
  const [errorCode, setErrorCode] = useState<string>(
    defaultSelectedReportError
  );

  const handleRequest = () => {
    errorCode && onRequest(errorCode);
    handleCancel();
  };

  const handleCancel = () => {
    setErrorCode(defaultSelectedReportError);
    onCancel();
  };

  return (
    <Modal
      width={768}
      open={open}
      title="Report error"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <RadioGroup
        value={errorCode}
        controls={reportErrors.map((error) => ({
          title: error.label,
          value: error.value,
        }))}
        onChange={setErrorCode}
        vertical
      />

      <S.Submit>
        <Flex justify="flex-end" gap={12}>
          <Button onClick={handleCancel} size="large">
            Cancel
          </Button>

          <Button type="primary" size="large" onClick={handleRequest}>
            Submit
          </Button>
        </Flex>
      </S.Submit>
    </Modal>
  );
};

export default DocumentExpenseReport;
