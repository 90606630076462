//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminIncorporationController_findOneById,
  Response$AdminIncorporationController_findOneById$Status$200,
  ResponseContentType$AdminIncorporationController_findOneById,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminIncorporationController_findOneById = (options: {
  params: Params$AdminIncorporationController_findOneById['parameter'];
  config?: QueryOptions<
    Response$AdminIncorporationController_findOneById$Status$200[ResponseContentType$AdminIncorporationController_findOneById]
  >;
}) =>
  useApiClient<
    Params$AdminIncorporationController_findOneById,
    Response$AdminIncorporationController_findOneById$Status$200[ResponseContentType$AdminIncorporationController_findOneById]
  >({
    method: 'AdminIncorporationController_findOneById',
    params: { parameter: options.params },
    config: options.config,
  });
