import styled from '@emotion/styled';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 20px 20px 2px 20px;
`;

export const Submit = styled.div`
  margin-top: 24px;
`;
