import { Tabs } from 'antd';
import { FC, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import {
  CompanyDetailsEdit,
  CompanyDetailsFeatures,
  CompanyDetailsFeaturesEdit,
  CompanyDetailsInfo,
  CompanyDetailsPerson,
  CompanyDetailsPersonEdit,
  CompanyDetailsServiceInfo,
  CompanyDetailsServiceInfoEdit,
} from '@entities';

import * as S from './styled';

type CompanyDetailsCardProps = {
  company: Schemas.AdminCompanyDto;
  onAfterEdit: () => void;
};

const CompanyDetailsCard: FC<CompanyDetailsCardProps> = ({
  company,
  onAfterEdit,
}) => {
  const [isVisibleModalDetailsEdit, setIsVisibleModalDetailsEdit] =
    useState(false);

  const [isVisibleModalPersonEdit, setIsVisibleModalPersonEdit] =
    useState(false);

  const [isVisibleModalFeaturesEnabled, setIsVisibleModalFeaturesEnabled] =
    useState(false);

  const [isVisibleModalServiceInfo, setIsVisibleModalServiceInfo] =
    useState(false);

  return (
    <S.Container vertical>
      <CompanyDetailsEdit
        company={company}
        open={isVisibleModalDetailsEdit}
        onCancel={() => setIsVisibleModalDetailsEdit(false)}
        onAfterEdit={onAfterEdit}
      />

      <CompanyDetailsPersonEdit
        company={company}
        open={isVisibleModalPersonEdit}
        onCancel={() => setIsVisibleModalPersonEdit(false)}
        onAfterEdit={onAfterEdit}
      />

      <CompanyDetailsFeaturesEdit
        featuresStatus={company.featuresStatus}
        open={isVisibleModalFeaturesEnabled}
        onCancel={() => setIsVisibleModalFeaturesEnabled(false)}
        onAfterEdit={onAfterEdit}
      />

      <CompanyDetailsServiceInfoEdit
        company={company}
        open={isVisibleModalServiceInfo}
        onCancel={() => setIsVisibleModalServiceInfo(false)}
        onAfterEdit={onAfterEdit}
      />

      <S.Title>{company.name}</S.Title>

      <Tabs
        defaultActiveKey="details"
        size="large"
        items={[
          {
            key: 'details',
            label: 'Company details',
            children: (
              <CompanyDetailsInfo
                name={company.name}
                referenceId={company.referenceId}
                details={company.details}
                onEdit={() => setIsVisibleModalDetailsEdit(true)}
              />
            ),
          },
          {
            key: 'person',
            label: 'Contact person',
            children: (
              <CompanyDetailsPerson
                details={company.details?.contactPerson}
                onEdit={() => setIsVisibleModalPersonEdit(true)}
              />
            ),
          },
          {
            key: 'featuresEnabled',
            label: 'Features enabled',
            children: (
              <CompanyDetailsFeatures
                details={company.featuresStatus}
                onEdit={() => setIsVisibleModalFeaturesEnabled(true)}
              />
            ),
          },
          {
            key: 'serviceInfo',
            label: 'Service Info',
            children: (
              <CompanyDetailsServiceInfo
                company={company}
                onEdit={() => setIsVisibleModalServiceInfo(true)}
              />
            ),
          },
        ]}
      />
    </S.Container>
  );
};

export default CompanyDetailsCard;
