import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Label = styled(Flex)<FlexProps>`
  font-weight: 600;
  font-size: 16px;
  padding: 0 4px;
`;

export const Count = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
`;
