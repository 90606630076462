//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminUserController_updateCurrentUser,
  Response$AdminUserController_updateCurrentUser$Status$200,
  ResponseContentType$AdminUserController_updateCurrentUser,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminUserController_updateCurrentUser = () =>
  useApiClientMutation<
    Params$AdminUserController_updateCurrentUser,
    Response$AdminUserController_updateCurrentUser$Status$200[ResponseContentType$AdminUserController_updateCurrentUser]
  >({ method: 'AdminUserController_updateCurrentUser' });
