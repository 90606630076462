//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminBillingPeriodController_updateOneById,
  Response$AdminBillingPeriodController_updateOneById$Status$200,
  ResponseContentType$AdminBillingPeriodController_updateOneById,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminBillingPeriodController_updateOneById = () =>
  useApiClientMutation<
    Params$AdminBillingPeriodController_updateOneById,
    Response$AdminBillingPeriodController_updateOneById$Status$200[ResponseContentType$AdminBillingPeriodController_updateOneById]
  >({ method: 'AdminBillingPeriodController_updateOneById' });
