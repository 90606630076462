//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Params$AdminProcessTemplateController_unpublish } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminProcessTemplateController_unpublish = () =>
  useApiClientMutation<Params$AdminProcessTemplateController_unpublish, never>({
    method: 'AdminProcessTemplateController_unpublish',
  });
