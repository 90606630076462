import styled from '@emotion/styled';

export const Container = styled.div``;

export const List = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 0 12px 12px 12px;
  padding: 24px;
`;

export const Label = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 12px 12px 0 0;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  padding: 8px 24px;
`;
