import styled from '@emotion/styled';

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px 16px;
  height: 48px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};

    .ui-btn {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 12px;

  span {
    color: ${({ theme }) => theme.colors.grayDark};
  }

  strong {
    font-weight: 600;

    .ui-typography {
      color: ${({ theme }) => theme.colors.dark};
      font-size: 16px;
    }
  }
`;
