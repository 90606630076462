import { FC } from 'react';

import { Flex, Typography } from 'antd';

import { LegalContent } from '@entities';

const { Title } = Typography;

const Legal: FC = () => (
  <Flex gap={24} vertical>
    <LegalContent title={<Title>Legal</Title>} />
  </Flex>
);

export default Legal;
