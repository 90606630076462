import { Form } from 'antd';
import { Link, type LinkProps } from 'react-router-dom';

import styled from '@emotion/styled';

export const Container = styled.div`
  padding-bottom: 76px;
`;

export const TransactionId = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

export const DocumentName = styled(Link)<LinkProps>`
  font-weight: 600;
`;

export const FormItemClear = styled(Form.Item)`
  margin-bottom: 0;
`;
