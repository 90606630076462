//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Params$AdminAccountsController_syncConnection } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminAccountsController_syncConnection = () =>
  useApiClientMutation<Params$AdminAccountsController_syncConnection, never>({
    method: 'AdminAccountsController_syncConnection',
  });
