import { forwardRef } from 'react';

import * as S from './styled';

const ChatPanelUnread = forwardRef<HTMLDivElement>((_, ref) => (
  <S.Panel align="center" justify="center" ref={ref}>
    Unread messages
  </S.Panel>
));

export default ChatPanelUnread;
