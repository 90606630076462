//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminProcessTemplateController_findOneById,
  Response$AdminProcessTemplateController_findOneById$Status$200,
  ResponseContentType$AdminProcessTemplateController_findOneById,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminProcessTemplateController_findOneById = (options: {
  params: Params$AdminProcessTemplateController_findOneById['parameter'];
  config?: QueryOptions<
    Response$AdminProcessTemplateController_findOneById$Status$200[ResponseContentType$AdminProcessTemplateController_findOneById]
  >;
}) =>
  useApiClient<
    Params$AdminProcessTemplateController_findOneById,
    Response$AdminProcessTemplateController_findOneById$Status$200[ResponseContentType$AdminProcessTemplateController_findOneById]
  >({
    method: 'AdminProcessTemplateController_findOneById',
    params: { parameter: options.params },
    config: options.config,
  });
