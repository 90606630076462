import { List, type ListProps } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { getAddressLine } from '@utils';

import * as S from './styled';

type BobContact = Schemas.BobContact;

type ContactsSimpleListProps = {
  selectedId?: string | null;
  dataSource?: Schemas.BobContact[];
  onSelect: (contact: BobContact) => void;
} & ListProps<BobContact>;

const ContactsSimpleList: FC<ContactsSimpleListProps> = ({
  selectedId,
  dataSource,
  onSelect,
  ...rest
}) => (
  <List
    dataSource={dataSource}
    renderItem={(item) => (
      <S.Contact
        gap={4}
        selected={selectedId === item.id}
        onClick={() => onSelect(item)}
        vertical
      >
        <S.ContactName>{item.name}</S.ContactName>

        <div>VAT ID: {item.vatId || '-'}</div>

        <div>{getAddressLine(item.address)}</div>
      </S.Contact>
    )}
    locale={{
      emptyText: <div />,
    }}
    {...rest}
  />
);

export default ContactsSimpleList;
