//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminIncorporationController_approveKyc,
  Response$AdminIncorporationController_approveKyc$Status$200,
  ResponseContentType$AdminIncorporationController_approveKyc,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminIncorporationController_approveKyc = () =>
  useApiClientMutation<
    Params$AdminIncorporationController_approveKyc,
    Response$AdminIncorporationController_approveKyc$Status$200[ResponseContentType$AdminIncorporationController_approveKyc]
  >({ method: 'AdminIncorporationController_approveKyc' });
