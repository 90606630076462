import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)<FlexProps>`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
  flex: 1;
  padding: 20px;
`;
