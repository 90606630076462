import {
  Breadcrumb,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Table,
  type TableColumnType,
  message,
} from 'antd';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { useAdminTransactionController_findOneById } from '@api-client/generated/AdminTransactionController/findOneById';
import { useAdminTransactionController_updateOneById } from '@api-client/generated/AdminTransactionController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { IconArrowRight } from '@assets';
import { Card, InfoCell, Loader } from '@components';
import { Amount, GoBack, StatusProcessBar, StatusTag } from '@entities';
import { useAccount } from '@hooks';

import * as S from './styled';

type Status = Schemas.Transaction['adminStatus'];

const columns: TableColumnType<Schemas.Document>[] = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    render: (name, record) => (
      <S.DocumentName to={`/documents/${record.id}`}>
        {name || 'No name'}
      </S.DocumentName>
    ),
  },
  {
    key: 'createdAt',
    dataIndex: 'createdAt',
    title: 'Date',
    render: (date) => format(date, 'dd.MM.yyyy'),
  },
  {
    key: 'type',
    dataIndex: 'type',
    title: 'Type',
    render: (type) => type || 'No type',
  },
];

const TransactionDetails: FC = () => {
  const { id: transactionId } = useParams();
  const { companyId } = useAccount();

  const [statusProcess, setStatusProcess] = useState<Status | null>(null);

  const { data: transaction, isPending: loading } =
    useAdminTransactionController_findOneById({
      params: {
        id: transactionId!,
        companyId: companyId!,
      },
    });

  const { mutate: updateTransaction } =
    useAdminTransactionController_updateOneById();

  useEffect(() => {
    if (transaction) {
      setStatusProcess(transaction.adminStatus);
    }
  }, [transaction]);

  const handleUpdateDetails = (field: string, value: string) => {
    if (transaction) {
      updateTransaction(
        {
          parameter: {
            id: transactionId!,
            companyId: companyId!,
          },
          requestBody: {
            referenceId: transaction.referenceId,
            adminNotes: transaction.adminNotes,
            [field]: value,
          },
        },
        {
          onSuccess: () => {
            message.open({
              type: 'success',
              content: 'Transaction information has been successfully updated',
            });
          },
        }
      );
    }
  };

  const handleUpdateStatus = (status: Status) => {
    handleUpdateDetails('adminStatus', status);
    setStatusProcess(status);
  };

  const handleUpdate = useDebouncedCallback((e) => {
    const { value, id } = e.target;

    handleUpdateDetails(id, value);
  }, 800);

  if (!transaction || loading) {
    return <Loader />;
  }

  return (
    <S.Container>
      <Flex gap={24} vertical>
        <StatusProcessBar
          status={statusProcess!}
          entityType="transaction"
          entityId={transactionId!}
          onPause={() => handleUpdateStatus('paused')}
          onProcess={() => handleUpdateStatus('processing')}
          onFinish={() => handleUpdateStatus('processed')}
          onRequest={() => handleUpdateStatus('requested_changes')}
        />

        <Breadcrumb
          separator={<IconArrowRight />}
          items={[
            {
              title: <Link to="/transactions">Transactions</Link>,
            },
            {
              title: (
                <>
                  <S.TransactionId>ID#{transaction.id}</S.TransactionId>
                </>
              ),
            },
          ]}
        />

        <GoBack />

        <Flex gap={30} vertical>
          <Form
            layout="vertical"
            colon={false}
            onChange={handleUpdate}
            requiredMark={false}
            initialValues={{
              referenceId: transaction.referenceId,
              adminNotes: transaction.adminNotes,
            }}
          >
            <Row gutter={[48, 30]}>
              <Col span={15}>
                <Card title="Transaction Details">
                  <Row gutter={[30, 30]}>
                    <Col span={8}>
                      <InfoCell
                        label="Amount"
                        value={
                          <Amount
                            amount={transaction.amount}
                            currencyCode={transaction.currencyCode}
                          />
                        }
                      />
                    </Col>

                    <Col span={8}>
                      <InfoCell
                        label="Contact"
                        value={transaction.contact?.name}
                      />
                    </Col>

                    <Col span={8}>
                      <InfoCell
                        label="Date"
                        value={format(transaction.bookingDate, 'dd.MM.yyyy')}
                      />
                    </Col>

                    <Col span={8}>
                      <InfoCell
                        label="Account"
                        value={
                          transaction.account.connection?.bank?.name ||
                          transaction.account.accountName
                        }
                      />
                    </Col>

                    <Col span={8}>
                      <InfoCell
                        label="Transaction Details"
                        value={transaction.details}
                      />
                    </Col>

                    <Col span={8}>
                      <InfoCell
                        label="Status"
                        value={<StatusTag status={statusProcess!} />}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={9}>
                <Form.Item name="referenceId" label="Reference ID">
                  <Input placeholder="Add reference ID" size="large" />
                </Form.Item>

                <S.FormItemClear name="adminNotes" label="Notes">
                  <Input.TextArea
                    placeholder="Add note..."
                    rows={5}
                    size="large"
                  />
                </S.FormItemClear>
              </Col>
            </Row>
          </Form>

          <Card title="Documents">
            <Table
              rowKey={({ id }) => id}
              dataSource={transaction.documents || []}
              columns={columns}
              loading={false}
              pagination={false}
              scroll={{ x: 720 }}
            />
          </Card>
        </Flex>
      </Flex>
    </S.Container>
  );
};

export default TransactionDetails;
