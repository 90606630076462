import styled from '@emotion/styled';
import { Button, Flex, Typography } from 'antd';

const { Text } = Typography;

export const Root = styled(Flex)`
  width: 333px;
  height: calc(100% - 56px);
`;

export const StepNumber = styled('span', {
  shouldForwardProp: (propName) => propName !== 'isCurrent',
})<{
  isCurrent?: boolean;
}>`
  flex-shrink: 0;
  background-color: ${({ theme, isCurrent }) =>
  isCurrent ? theme.colors.primary : theme.colors.strokeGrey};
  color: ${({ theme, isCurrent }) =>
  isCurrent ? theme.colors.white : theme.colors.textBlue400};
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

export const StepDragHandler = styled.span`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.text400};
  display: none;
  width: 20px;
  height: 20px;
  cursor: grab;
  align-items: center;
  justify-content: center;
`;

export const DeleteButton = styled(Button)`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const Step = styled(Flex)`
  padding: 14px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover ${StepNumber} {
    display: none;
  }

  &:hover ${StepDragHandler} {
    display: inline-flex;
  }
  
  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;

export const Title = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

