import { FC } from 'react';

import * as S from './styled';

type ChatTabsLabelProps = {
  label: string;
  unseenMessagesCount: number;
};

const ChatTabsLabel: FC<ChatTabsLabelProps> = ({
  label,
  unseenMessagesCount,
}) => (
  <S.Label align="center" gap={8}>
    {label}
    {unseenMessagesCount ? <S.Count>{unseenMessagesCount}</S.Count> : null}
  </S.Label>
);

export default ChatTabsLabel;
