import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 14px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`;

export const Form = styled.div`
  .ui-form {
    .ui-form-item {
      margin-bottom: 12px;

      .ui-form-item-label {
        label {
          font-size: 12px;
        }
      }

      .ui-picker,
      .ui-input-number,
      .ui-upload {
        width: 100%;
      }
    }
  }
`;

export const FormItemSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 38px;
`;

export const Download = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 18px 0;
  padding-bottom: 18px;
`;

export const File = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px;
`;

export const FileName = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 16px;
  font-weight: 600;
`;

export const Submit = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  .ui-btn {
    height: 48px;
    font-size: 16px;
  }
`;

export const Upload = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.textBlue400};
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const UploadLabel = styled.div`
  color: ${({ theme }) => theme.colors.textBlue400};
  font-size: 16px;
  font-weight: 600;
`;
