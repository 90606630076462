import { FC, ReactNode } from 'react';

import { Flex } from 'antd';
import { CopyButton } from '@components';

import * as S from './styled';

type CompanyDetailsInfoProps = {
  label: string;
  value: ReactNode;
  copyText?: string;
  isVisible: unknown;
};

const CompanyDetailsInfo: FC<CompanyDetailsInfoProps> = ({
  label,
  value,
  copyText,
  isVisible,
}) => (
  <Flex vertical>
    <S.InfoLabel>{label}</S.InfoLabel>
    <S.InfoValue vertical>
      <>
        {isVisible ? value : '-'}
        {copyText && isVisible && (
          <S.Copy className="ui-btn-copy-info-cell">
            <CopyButton
              tooltipPlacement="bottom"
              icon="copy"
              value={copyText}
              type="link"
            />
          </S.Copy>
        )}
      </>
    </S.InfoValue>
  </Flex>
);

export default CompanyDetailsInfo;
