//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Params$AdminProcessTemplateController_delete } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminProcessTemplateController_delete = () =>
  useApiClientMutation<Params$AdminProcessTemplateController_delete, never>({
    method: 'AdminProcessTemplateController_delete',
  });
