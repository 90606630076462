import { Flex } from 'antd';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type ProcessTemplateListResponseDto = Schemas.ProcessTemplateListResponseDto;

type ProcessesTemplatesListItemProps = {
  template: ProcessTemplateListResponseDto;
  isSelected: boolean;
  onSelect: () => void;
};

const ProcessesTemplatesListItem = ({
  template,
  isSelected,
  onSelect,
}: ProcessesTemplatesListItemProps) => (
  <S.Root gap={9} isSelected={isSelected} onClick={onSelect} vertical>
    <Flex gap={5} vertical>
      <Flex align="center" justify="space-between">
        <S.Title>{template.name}</S.Title>
        {template.isPublished && <S.Status>Published</S.Status>}
      </Flex>
      <S.Description>{template.description}</S.Description>
    </Flex>
    <S.EstimatedTime>Estimated time</S.EstimatedTime>
  </S.Root>
);

export default ProcessesTemplatesListItem;
