import { FC, ReactNode } from 'react';

import {
  type ModalProps,
  type ButtonProps,
  Modal,
  Flex,
  Button,
  Typography,
} from 'antd';

import * as S from './styled';

const { Paragraph } = Typography;

type PopupDeleteProps = {
  title?: ReactNode;
  description?: ReactNode;
  confirmParams: { text: string } & ButtonProps;
  onRemove: () => void;
  onCancel: () => void;
} & ModalProps;

const PopupDelete: FC<PopupDeleteProps> = ({
  open,
  title,
  description,
  confirmParams,
  onRemove,
  onCancel,
}) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      width={504}
      open={open}
      footer={null}
      onCancel={onCancel}
      closeIcon={null}
      destroyOnClose
      centered
    >
      <Flex gap={12} vertical>
        <Flex vertical>
          {title && <S.Title level={3}>{title}</S.Title>}

          {description && <Paragraph>{description}</Paragraph>}
        </Flex>

        <Flex align="center" justify="flex-end" gap={12}>
          <Button onClick={onCancel} size="large">
            Cancel
          </Button>

          <Button
            type="primary"
            onClick={onRemove}
            size="large"
            danger
            {...confirmParams}
          >
            {confirmParams.text}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default PopupDelete;
