import { Divider as DividerBase, Flex, Typography } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

const { Text } = Typography;

export const Root = styled(Flex)`
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.black, 0.1)};
  overflow: hidden;
  border-radius: 10px;
`;

export const Header = styled(Flex)`
  padding: 16px 30px;
  background-color: ${({ theme }) => theme.colors.strokeDarkGrey};
  border-radius: 10px 10px 0 0;
`;

export const Body = styled(Flex)`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  padding: 28px 0 28px 32px;
  border-radius: 0 0 10px 10px;
`;

export const EmptyBody = styled(Body)`
  text-align: center;
`;

export const Title = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const Divider = styled(DividerBase)`
  height: 100%;
`;
