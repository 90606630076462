export const downloadFile = (
  fileLink: string,
  downloadFileName: string,
  isTarget: boolean = false
) => {
  const link = document.createElement('a');

  link.href = fileLink;
  link.download = downloadFileName;

  if (isTarget) {
    link.target = '_blank';
  }

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
