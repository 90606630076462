import { useEffect, useState } from 'react';

const usePDFScaling = (
  initialWidth: number,
  stepSize: number,
  magnifierMax: number
) => {
  const [scale, setScale] = useState(1.0);
  const [newScale, setNewScale] = useState(1.0);
  const [pageVisible, setPageVisible] = useState(true);

  const minWidth = initialWidth / magnifierMax;
  const maxWidth = initialWidth * magnifierMax;

  const scaleUp = () => {
    setNewScale((prevScale) =>
      Math.min(prevScale + stepSize, maxWidth / initialWidth)
    );
  };

  const scaleDown = () => {
    setNewScale((prevScale) =>
      Math.max(prevScale - stepSize, minWidth / initialWidth)
    );
  };

  const scaleReset = () => {
    const resetScale = 1.0;
    setNewScale(resetScale);
    setScale(resetScale);
  };

  useEffect(() => {
    scaleReset();
  }, []);

  const togglePageVisibility = () => {
    if (newScale !== scale) {
      setPageVisible(!pageVisible);
      setScale(newScale);
    }
  };

  return {
    scale,
    newScale,
    pageVisible,
    scaleUp,
    scaleDown,
    scaleReset,
    togglePageVisibility,
  };
};

export default usePDFScaling