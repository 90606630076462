import { Button, type ButtonProps, Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Item = styled(Flex)<FlexProps>`
  height: 66px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  cursor: pointer;
  padding: 8px;
  margin-bottom: 4px;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgGrey};

    .service-amount {
      display: none;
    }

    .service-button-edit {
      display: block;
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  flex: 1;
`;

export const ButtonEdit = styled(Button)<ButtonProps>`
  && {
    color: ${({ theme }) => theme.colors.blackSecondary};
    display: none;
  }
`;
