import styled from '@emotion/styled';

export const ShowAll = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease-in;

  :hover {
    opacity: 0.3;
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;
`