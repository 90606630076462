import {
  Button,
  Col,
  Flex,
  Form,
  List,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import type { PartialDeep } from 'type-fest';

import { useAdminIncorporationController_updateOneById } from '@api-client/generated/AdminIncorporationController/updateOneById';
import { useAdminOnfidoDataController_create } from '@api-client/generated/AdminOnfidoDataController/create';
import { Schemas } from '@api-client/generated/types';
import {
  IconCheck,
  IconLegal,
  IconUser,
  IconWarningTransparent,
} from '@assets';
import { Loader } from '@components';
import { countries } from '@constants';
import { PersonFileModal } from '@entities';
import { useAccount } from '@hooks';

import * as S from './styled';

type IncorporationKYCProps = {
  people: Schemas.Person[];
  updateIncorporation: (details: IncorporationWithDto) => void;
};

type Person = Schemas.Person;
type CompanyFile = Schemas.CompanyFile;
type OnfidoData = Schemas.OnfidoData;

type Incorporation = PartialDeep<Schemas.Incorporation>;
type IncorporationDto = PartialDeep<Schemas.AdminIncorporationDto>;

type IncorporationWithDto = Incorporation | IncorporationDto;

type StepWithFormErrors = 'tin' | 'wealth';

const { Title } = Typography;
const IncorporationKYC: React.FC<IncorporationKYCProps> = ({
  people,
  updateIncorporation,
}) => {
  const { companyId } = useAccount();
  const [activeFile, setActiveFile] = useState<Schemas.CompanyFile | null>(
    null
  );
  const [files, setFiles] = useState<Schemas.CompanyFile[] | undefined>([]);
  const [isOpenPersonFileModal, setIsOpenPersonFileModal] = useState(false);
  const [selectedStepValue, setSelectedStepValue] = useState('country');

  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);

  const [modal, contextHolder] = Modal.useModal();

  const [onfidoData, setOnfidoData] = useState<OnfidoData | null>(null);

  const { mutate: updateIncorporationById } =
    useAdminIncorporationController_updateOneById();
  const { mutate: getOnfidoData, isPending: loadingOnfido } =
    useAdminOnfidoDataController_create();

  const [isRequestChangesFormVisible, setIsRequestChangesFormVisible] =
    useState(false);

  const KYCStepsLegal = {
    country: 'Registered country',
    extract: 'Extract from commercial register',
    aoa: 'Articles of Association (AoA) or deed',
    annual_accounts: 'Last annual accounts',
    ownership_chart: 'Chart with ownership and %',
    tin: 'TIN',
    activity_details: 'Activity details',
  } as Record<string, string>;

  const KYCStepsNatural = {
    nationality: 'Nationality & Surname',
    id: 'Biometric check',
    address_proof: 'Proof of address',
    wealth: 'Source of wealth',
    cv: 'CV',
    tax: 'Tax information',
    funds: 'Funds',
  } as Record<string, string>;

  const activeSteps =
    selectedType === 'legal' ? KYCStepsLegal : KYCStepsNatural;

  const closePersonFile = () => {
    setActiveFile(null);
    setIsOpenPersonFileModal(false);
  };

  const selectFile = (file: Schemas.CompanyFile) => {
    setActiveFile(file);
    setIsOpenPersonFileModal(true);
  };

  const getStatusDescription = (file: Schemas.CompanyFile) => {
    if (file.isApproved) {
      return 'Approved';
    }

    if (file.hasError) {
      return 'Has error, waiting for client';
    }

    return 'Needs to be checked';
  };

  const handleSelectPerson = (person: Person) => {
    setSelectedPerson(person);
    setSelectedType(person.type);
    setIsRequestChangesFormVisible(false);
    setSelectedStepValue(person.type === 'legal' ? 'country' : 'nationality');
  };

  const handleSelectStep = (step: string) => {
    setIsRequestChangesFormVisible(false);
    setSelectedStepValue(step);
    setFiles(selectedPerson?.files[step]);
    maybeGetOnfidoData(step);
  };

  const maybeGetOnfidoData = (step: string) => {
    if (step !== 'id') {
      return;
    }

    getOnfidoData(
      {
        parameter: {
          companyId: companyId!,
          personId: selectedPerson!.id,
        },
      },
      {
        onSuccess: (response) => {
          setOnfidoData(response);
        },
      }
    );
  };

  const getPersonRole = (person: Person) => {
    const roles: string[] = [];

    if (person.isShareholder) {
      roles.push('Shareholder');
    }

    if (person.isDirector) {
      roles.push('Manager');
    }

    return roles.join(', ');
  };

  const hasErrorStep = (step: string) => {
    if (step === 'id' && selectedPerson?.kycData?.hasFailedBiometricCheck) {
      return true;
    }

    if (step === 'wealth') {
      const incomeWithFileRequestedButNoFileOrError =
        selectedPerson?.kycData?.incomes?.find((income) => {
          if (income) {
            const step = `wealth_${income.type}`;
            return (
              income.isRequiredConfimation === 'yes' &&
              (!selectedPerson.files[step] ||
                selectedPerson.files[step].length === 0 ||
                selectedPerson.files[step].some(
                  (file: CompanyFile) => file.hasError
                ))
            );
          }
        });

      if (incomeWithFileRequestedButNoFileOrError) {
        return true;
      }
    }

    if (['tin', 'wealth'].includes(step) && selectedPerson?.kycData) {
      return selectedPerson.kycData[`${step as StepWithFormErrors}ErrorCode`];
    }
    const errors = (selectedPerson?.files[step] || [])
      .map((file: CompanyFile) => file.hasError)
      .filter((status: boolean) => status);

    return !!errors.length;
  };

  const allApprovedOnStep = (step: string) => {
    const errors = (selectedPerson?.files[step] || [])
      .map((file: CompanyFile) => !file.isApproved)
      .filter((status: boolean) => status);

    return errors.length === 0;
  };

  const hasErrorPerson = (person: Person) => {
    const incomeWithFileRequestedButNoFile = person?.kycData?.incomes?.find(
      (income) => {
        if (income) {
          const step = `wealth_${income.type}`;
          return (
            income.isRequiredConfimation === 'yes' &&
            (!person.files[step] || person.files[step].length === 0)
          );
        }
      }
    );

    if (incomeWithFileRequestedButNoFile) {
      return true;
    }

    if (
      person?.kycData?.tinErrorCode ||
      person?.kycData?.wealthErrorCode ||
      person?.kycData?.hasFailedBiometricCheck
    ) {
      return true;
    }

    const errors = Object.keys(person.files)
      .flatMap((key) => person.files[key])
      .map((file: CompanyFile) => file.hasError)
      .filter((status: boolean) => status);

    return !!errors.length;
  };

  const requestDocsForIncome = (incomeType: string) => {
    modal.confirm({
      title: 'Are you sure?',
      onOk: () => {
        const currentKycData = selectedPerson!.kycData || {};
        const incomes = currentKycData.incomes || [];
        const income = incomes.find((income) => income.type === incomeType);
        income!.isRequiredConfimation = 'yes';

        selectedPerson!.kycData = {
          ...currentKycData,
          incomes: incomes,
        };

        updateIncorporationById({
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            people: people,
          },
        });
        updateIncorporation({ people: people });
      },
    });
  };

  const getCountryNameByCode = (code: string | string[]) => {
    let values = [];
    if (Array.isArray(code)) {
      values = code;
    } else {
      values = [code];
    }
    return values
      .map((val) => {
        const countryData = countries.find((pair) => pair.code === val);
        return countryData?.name || val;
      })
      .join(', ');
  };

  const showForm = () => {
    setIsRequestChangesFormVisible(true);
  };

  const hideForm = (event: React.MouseEvent) => {
    event.preventDefault();

    setIsRequestChangesFormVisible(false);
  };

  const markFailedBiometricCheck = () => {
    modal.confirm({
      title: 'Are you sure?',
      onOk: () => {
        const currentKycData = selectedPerson!.kycData || {};
        selectedPerson!.kycData = {
          ...currentKycData,
          hasFailedBiometricCheck: true,
          hasCompletedBiometricCheck: false,
        };

        updateIncorporationById({
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            people: people,
          },
        });
        updateIncorporation({ people: people });
      },
    });
  };

  const onFinish = (values: Schemas.KYCData) => {
    const currentKycData = selectedPerson!.kycData || {};
    selectedPerson!.kycData = {
      ...currentKycData,
      ...values,
    };

    updateIncorporationById({
      parameter: {
        companyId: companyId!,
      },
      requestBody: {
        people: people,
      },
    });

    updateIncorporation({ people: people });
  };

  const errorForm = (field: string) => (
    <>
      {!isRequestChangesFormVisible && (
        <Flex gap={20}>
          <Button type="link" onClick={showForm}>
            Request changes
          </Button>
        </Flex>
      )}

      {isRequestChangesFormVisible && (
        <Flex gap={20} vertical>
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={selectedPerson?.kycData}
          >
            <Form.Item label="Reason" name={`${field}ErrorCode`}>
              <Select
                variant="outlined"
                placeholder="Select type of issue"
                options={[
                  {
                    label: 'Wrong value',
                    value: 'wrong_value',
                  },
                  {
                    label: 'Needs detailed description',
                    value: 'missing_description',
                  },
                  {
                    label: 'Needs more detailed description',
                    value: 'not_clear_description',
                  },
                ]}
                size="large"
              />
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Request changes
            </Button>
            <Button type="link" onClick={(event) => hideForm(event)}>
              Cancel
            </Button>
          </Form>
        </Flex>
      )}
    </>
  );

  return (
    <>
      {activeFile && (
        <PersonFileModal
          open={isOpenPersonFileModal}
          file={activeFile!}
          title={selectedStepValue && activeSteps[selectedStepValue]}
          onCancel={closePersonFile}
        />
      )}
      <Flex gap={24}>
        <S.People>
          {people.map((person) => (
            <S.PeopleWrap key={person.id}>
              <S.Person
                onClick={() => handleSelectPerson(person)}
                selected={selectedPerson?.id === person.id}
              >
                <Flex gap={16}>
                  {person.type === 'legal' ? <IconLegal /> : <IconUser />}

                  <Flex vertical>
                    <S.PersonName>{person.name}</S.PersonName>
                    <S.PersonDetails>{getPersonRole(person)}</S.PersonDetails>
                  </Flex>
                </Flex>

                <Flex align="center" gap={4}>
                  {hasErrorPerson(person) && (
                    <S.StepNumber status="errorsFound">
                      <IconWarningTransparent />
                    </S.StepNumber>
                  )}
                </Flex>
              </S.Person>
            </S.PeopleWrap>
          ))}
        </S.People>

        <S.Files>
          <Row gutter={[24, 0]}>
            <Col span={11}>
              <S.Steps>
                {(selectedType === 'legal'
                  ? Object.keys(KYCStepsLegal)
                  : Object.keys(KYCStepsNatural)
                ).map((step) => (
                  <S.Step
                    key={step}
                    selected={selectedStepValue === step}
                    onClick={() => handleSelectStep(step)}
                  >
                    {selectedPerson?.files[step] && allApprovedOnStep(step) && (
                      <S.StepNumber
                        status="completed"
                        selected={selectedStepValue === step}
                      >
                        <IconCheck />
                      </S.StepNumber>
                    )}

                    {hasErrorStep(step) && (
                      <S.StepNumber status="errorsFound">
                        <IconWarningTransparent />
                      </S.StepNumber>
                    )}

                    {selectedPerson?.files[step] &&
                      !allApprovedOnStep(step) &&
                      !hasErrorStep(step) && (
                        <S.StepNumber status={null}>?</S.StepNumber>
                      )}

                    <S.StepName>{activeSteps[step]}</S.StepName>
                  </S.Step>
                ))}
              </S.Steps>
            </Col>

            <Col span={12}>
              <Space direction="vertical">
                {selectedPerson && (
                  <>
                    <Title level={5}>
                      {selectedType === 'legal'
                        ? KYCStepsLegal[selectedStepValue]
                        : KYCStepsNatural[selectedStepValue]}
                    </Title>
                    {selectedStepValue === 'country' &&
                      selectedPerson.kycData?.countryCode &&
                      getCountryNameByCode(selectedPerson.kycData?.countryCode)}

                    {selectedStepValue === 'tin' && (
                      <>
                        <div>
                          {selectedPerson.kycData?.tin}{' '}
                          {selectedPerson.kycData?.tinErrorCode &&
                            `- ${selectedPerson.kycData?.tinErrorCode}`}
                        </div>
                        {errorForm('tin')}
                      </>
                    )}

                    {selectedStepValue === 'nationality' && (
                      <>
                        <div>
                          Nationalities:{' '}
                          {selectedPerson.kycData?.nationality &&
                            getCountryNameByCode(
                              selectedPerson.kycData?.nationality
                            )}
                        </div>

                        <div>
                          Had another surname?:{' '}
                          {selectedPerson.kycData?.isUsedAnotherName}
                        </div>

                        <div>
                          Another surname reasons:{' '}
                          {selectedPerson.kycData?.anotherNameReasons}
                        </div>
                      </>
                    )}

                    {selectedStepValue === 'id' && (
                      <>
                        <div>
                          Completed check:{' '}
                          {selectedPerson.kycData?.hasCompletedBiometricCheck
                            ? 'yes'
                            : 'no'}
                        </div>

                        {loadingOnfido && <Loader />}

                        {onfidoData && (
                          <>
                            <div>Status: {onfidoData.workflowRunStatus}</div>

                            <div>
                              <a
                                href={`https://dashboard.onfido.com/results/${onfidoData.workflowRunId}`}
                                target="_blank"
                              >
                                View on Onfido
                              </a>
                            </div>

                            <div>
                              <Button
                                type="primary"
                                onClick={markFailedBiometricCheck}
                              >
                                Mark as failed for client
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {selectedStepValue === 'address_proof' && (
                      <>
                        <div>
                          Residence country:{' '}
                          {selectedPerson.kycData?.countryCode &&
                            getCountryNameByCode(
                              selectedPerson.kycData?.countryCode
                            )}
                        </div>

                        <div>
                          Address: {selectedPerson.kycData?.address},{' '}
                          {selectedPerson.kycData?.zip}{' '}
                          {selectedPerson.kycData?.city}
                        </div>
                      </>
                    )}

                    {selectedStepValue === 'wealth' && (
                      <>
                        <div>
                          Total wealth: {selectedPerson.kycData?.totalWealth}
                        </div>

                        {selectedPerson.kycData?.professionalIncome && (
                          <div>
                            Professional income:{' '}
                            {selectedPerson.kycData?.professionalIncome}
                          </div>
                        )}

                        {selectedPerson.kycData?.incomes &&
                          selectedPerson.kycData?.incomes
                            .filter((el) => el)
                            .map((income) => (
                              <>
                                <div>
                                  {income.type}: {income.sumAndDescription}
                                  {income.isRequiredConfimation === 'yes' ? (
                                    ' - Doc requested'
                                  ) : (
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        requestDocsForIncome(income.type)
                                      }
                                    >
                                      Request docs
                                    </Button>
                                  )}
                                  {(
                                    selectedPerson.files?.[
                                      `wealth_${income.type}`
                                    ] || []
                                  ).map((file: CompanyFile) => (
                                    <Button
                                      onClick={() => {
                                        selectFile(file);
                                      }}
                                      type="link"
                                    >
                                      View
                                    </Button>
                                  ))}
                                </div>
                              </>
                            ))}
                        <div>{selectedPerson.kycData?.wealthErrorCode}</div>
                        {errorForm('wealth')}
                      </>
                    )}

                    {selectedStepValue === 'cv' && (
                      <>
                        <div>
                          Considered a Politically Exposed Person (PEP)?:{' '}
                          {selectedPerson.kycData?.pep}{' '}
                          {selectedPerson.kycData?.pep === 'yes'
                            ? selectedPerson.kycData?.pepInfo
                            : ''}
                        </div>

                        <div>
                          Works or has companies in countries blacklisted by the
                          FATF, UN, EU, OECD or perceived as corrupt?:{' '}
                          {selectedPerson.kycData?.blacklist}{' '}
                          {selectedPerson.kycData?.blacklist === 'yes'
                            ? selectedPerson.kycData?.blacklistInfo
                            : ''}
                        </div>

                        <div>
                          Personal phone:{' '}
                          {selectedPerson.kycData?.personalPhone}
                        </div>

                        <div>
                          Personal email:{' '}
                          {selectedPerson.kycData?.personalEmail}
                        </div>

                        <div>
                          Activity sector:{' '}
                          {selectedPerson.kycData?.activitySector}
                        </div>
                      </>
                    )}

                    {selectedStepValue === 'tax' && (
                      <>
                        <div>
                          Country of tax residence:{' '}
                          {selectedPerson.kycData?.taxResidenceCountryCode &&
                            getCountryNameByCode(
                              selectedPerson.kycData?.taxResidenceCountryCode
                            )}
                        </div>

                        <div>
                          Tax number: {selectedPerson.kycData?.taxNumber}
                        </div>

                        <div>
                          Country of professional income:{' '}
                          {selectedPerson.kycData
                            ?.professionalIncomeCountryCode &&
                            getCountryNameByCode(
                              selectedPerson.kycData
                                ?.professionalIncomeCountryCode
                            )}
                        </div>

                        <div>
                          Has any links with the USA?:{' '}
                          {selectedPerson.kycData?.usaLink}{' '}
                          {selectedPerson.kycData?.usaLink === 'yes'
                            ? selectedPerson.kycData?.usaLinkType
                            : ''}
                        </div>
                      </>
                    )}

                    {selectedStepValue === 'annual_accounts' && (
                      <>
                        <div>
                          Filed annual accounts before?:{' '}
                          {selectedPerson.kycData?.hasFiledAnnualAccounts}
                        </div>
                      </>
                    )}

                    {selectedStepValue === 'funds' && (
                      <>
                        <div>
                          Has external funding?:{' '}
                          {/* @ts-expect-error-next-line */}
                          {selectedPerson.kycData?.hasExternalFunds}
                        </div>

                        <div>
                          External funding description:{' '}
                          {/* @ts-expect-error-next-line */}
                          {selectedPerson.kycData?.externalFundsBackground}
                        </div>
                      </>
                    )}

                    {selectedStepValue === 'activity_details' && (
                      <>
                        <div>
                          Purporse of establishment:{' '}
                          {selectedPerson.kycData?.purposeOfEstablishment}
                        </div>

                        <div>
                          Assesment of incoming funds:{' '}
                          {selectedPerson.kycData?.assessmentOfIncomingFunds}
                        </div>

                        <div>
                          Assesment of outgoing funds:{' '}
                          {selectedPerson.kycData?.assessmentOfOutgoingFunds}
                        </div>

                        <div>
                          Clients of company:{' '}
                          {selectedPerson.kycData?.clientsOfCompany}
                        </div>

                        <div>
                          Counries involved:{' '}
                          {selectedPerson.kycData?.countriesInvolved}
                        </div>

                        <div>
                          Long term goals:{' '}
                          {selectedPerson.kycData?.longTermGoals}
                        </div>
                      </>
                    )}

                    {files?.length ? (
                      <List
                        dataSource={files}
                        renderItem={(file, index) => (
                          <List.Item>
                            {index + 1}. {getStatusDescription(file)}
                            <Button
                              onClick={() => {
                                selectFile(file);
                              }}
                              type="link"
                            >
                              View
                            </Button>
                            {file.events.length ? (
                              <List
                                dataSource={file.events}
                                renderItem={(event) => (
                                  <List.Item>
                                    {event.type.toLocaleUpperCase()} by{' '}
                                    {[
                                      event.user.firstName,
                                      event.user.lastName,
                                    ].join(' ')}{' '}
                                    on{' '}
                                    {dayjs(event.createdAt).format(
                                      'DD.MM.YYYY HH:mm'
                                    )}
                                  </List.Item>
                                )}
                              />
                            ) : null}
                          </List.Item>
                        )}
                      />
                    ) : null}
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </S.Files>

        {contextHolder}
      </Flex>
    </>
  );
};

export default IncorporationKYC;
