import { FC, PropsWithChildren } from 'react';

import { IconArrowLeft } from '@assets';

import * as S from './styled';

const ChatTabsNotSelectedAlert: FC<PropsWithChildren> = ({ children }) => (
  <S.Container align="center" justify="center" gap={4}>
    <IconArrowLeft width={14} height={14} />
    {children}
  </S.Container>
);

export default ChatTabsNotSelectedAlert;
