import { FC } from 'react';
import _ from 'lodash';

import { List } from 'antd';

import { Amount, BillingServiceItem } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type InvoiceItem = Schemas.InvoiceItem;

type BillingServicesListProps = {
  dataSource: InvoiceItem[];
  onEdit: (details: InvoiceItem) => void;
};

const BillingServicesList: FC<BillingServicesListProps> = ({
  dataSource,
  onEdit,
}) => (
  <S.Container>
    <S.Header align="center" justify="space-between">
      <S.HeaderColumn>Description</S.HeaderColumn>
      <S.HeaderColumn>Price</S.HeaderColumn>
    </S.Header>

    <List
      dataSource={dataSource}
      renderItem={(item) => (
        <BillingServiceItem details={item} onEdit={onEdit} />
      )}
      locale={{
        emptyText: <div />,
      }}
    />

    <S.Total align="center" justify="flex-end" gap={16}>
      <S.TotalTitle>Total</S.TotalTitle>

      <Amount
        currencyCode="EUR"
        amount={_.sumBy(dataSource, 'totalPrice')}
        withoutType
      />
    </S.Total>
  </S.Container>
);

export default BillingServicesList;
