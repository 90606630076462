import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { AccessDeniedImage } from '@assets';

import * as S from './styled';
import { useAccount } from '@hooks';

const AccessDenied = () => {
  const navigate = useNavigate();
  const {isLogged} = useAccount()

  if(!isLogged) return;

  return (
    <S.Wrapper align="center" justify="center" gap={24} vertical>
      <img src={AccessDeniedImage} alt="access-denied" />
      <S.Title>You can’t visit this page</S.Title>
      <S.Description>
        If you need to get the access to it, ask the administrator for changing
        access settings
      </S.Description>
      <Button type="primary" size='large' onClick={() => navigate(-1)}>
        Go back to the previous page
      </Button>
    </S.Wrapper>
  );
};

export default AccessDenied;
