import { FC } from 'react';

import { Modal } from 'antd';

import { LegalContent } from '@entities';

type LegalModalProps = {
  companyId?: string;
  open: boolean;
  onCancel: () => void;
};

const LegalModal: FC<LegalModalProps> = ({ companyId, open, onCancel }) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      width={1160}
      title="Legal"
      open={open}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <LegalContent externalCompanyId={companyId} />
    </Modal>
  );
};

export default LegalModal;
