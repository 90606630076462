//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminProcessTemplateController_create,
  Response$AdminProcessTemplateController_create$Status$201,
  ResponseContentType$AdminProcessTemplateController_create,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminProcessTemplateController_create = () =>
  useApiClientMutation<
    Params$AdminProcessTemplateController_create,
    Response$AdminProcessTemplateController_create$Status$201[ResponseContentType$AdminProcessTemplateController_create]
  >({ method: 'AdminProcessTemplateController_create' });
