//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminBobContactController_create,
  Response$AdminBobContactController_create$Status$201,
  ResponseContentType$AdminBobContactController_create,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminBobContactController_create = () =>
  useApiClientMutation<
    Params$AdminBobContactController_create,
    Response$AdminBobContactController_create$Status$201[ResponseContentType$AdminBobContactController_create]
  >({ method: 'AdminBobContactController_create' });
