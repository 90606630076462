import { Schemas } from '@api-client/generated/types';

type ChatMessage = Schemas.ChatMessage;
type User = Schemas.User;

type GroupedMessages<T, U> = {
  author: U;
  unreadMessages: boolean;
  messages: T[];
};

export const getGroupedMessagesById = <T extends ChatMessage, U extends User>(
  messages: T[],
  accountId?: string
): GroupedMessages<T, U>[] => {
  const groupedMessages: GroupedMessages<T, U>[] = [];

  let currentGroup: T[] = [];

  messages.forEach((message, messageIndex) => {
    if (
      messageIndex === 0 ||
      message?.author?.id === messages[messageIndex - 1]?.author?.id
    ) {
      currentGroup.push(message);
    } else {
      groupedMessages.push({
        author: currentGroup.at(0)?.author as U,
        unreadMessages: !!currentGroup
          .at(-1)
          ?.seenData.map((message) => message.userId)
          .includes(accountId!),
        messages: currentGroup,
      });

      currentGroup = [message];
    }
  });

  if (currentGroup.length) {
    groupedMessages.push({
      author: currentGroup.at(0)?.author as U,
      unreadMessages: !!currentGroup
        .at(-1)
        ?.seenData.map((message) => message.userId)
        .includes(accountId!),
      messages: currentGroup,
    });
  }

  return groupedMessages;
};
