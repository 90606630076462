import styled from '@emotion/styled';

type StatusProps = {
  bgColor: string;
  textColor: string;
};

export const Status = styled.span<StatusProps>`
  height: 24px;
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.colors.bgGrayLight};
  color: ${({ theme, textColor }) => textColor || theme.colors.black};
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 12px;
  line-height: 16px;
`;
