//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminProcessTemplateController_update,
  Response$AdminProcessTemplateController_update$Status$200,
  ResponseContentType$AdminProcessTemplateController_update,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminProcessTemplateController_update = () =>
  useApiClientMutation<
    Params$AdminProcessTemplateController_update,
    Response$AdminProcessTemplateController_update$Status$200[ResponseContentType$AdminProcessTemplateController_update]
  >({ method: 'AdminProcessTemplateController_update' });
