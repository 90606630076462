import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Container = styled.div`
  width: 410px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 20px;
  margin-top: 24px;
`;

export const Header = styled(Flex)<FlexProps>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  padding: 8px;
  margin-bottom: 4px;
`;

export const HeaderColumn = styled.div`
  color: ${({ theme }) => theme.colors.blackSecondary};
  font-size: 12px;
  font-weight: 600;
`;

export const Total = styled(Flex)<FlexProps>`
  padding-top: 16px;

  .ui-typography {
    font-size: 16px;
  }
`;

export const TotalTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;
