import { FC } from 'react';

import { Card } from '@components';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type TaxPreviewProps = {
  file: Schemas.Document;
};

const TaxPreview: FC<TaxPreviewProps> = ({ file }) => (
  <Card title="Preview">
    <S.Preview overlay={!!file}>
      {file && (
        <>
          {file.mimeType.includes('image') ? (
            <img src={file.url} alt={file.name} />
          ) : (
            <iframe src={file.url} />
          )}
        </>
      )}
    </S.Preview>
  </Card>
);

export default TaxPreview;
