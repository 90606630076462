//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminCompanyController_updateOneById,
  Response$AdminCompanyController_updateOneById$Status$200,
  ResponseContentType$AdminCompanyController_updateOneById,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminCompanyController_updateOneById = () =>
  useApiClientMutation<
    Params$AdminCompanyController_updateOneById,
    Response$AdminCompanyController_updateOneById$Status$200[ResponseContentType$AdminCompanyController_updateOneById]
  >({ method: 'AdminCompanyController_updateOneById' });
