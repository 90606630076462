import { FC } from 'react';

import { Flex } from 'antd';

import * as S from './styled';

const BillingDetailsSkeleton: FC = () => (
  <S.Container gap={24} vertical>
    <Flex align="flex-start" justify="space-between">
      <Flex gap={4} vertical>
        <S.Skeleton width={263} height={28} radius={8} />
        <S.Skeleton width={263} height={22} radius={6} />
      </Flex>

      <S.Skeleton width={150} height={42} radius={8} />
    </Flex>

    <S.Skeleton width={400} height={450} radius={10} />
  </S.Container>
);

export default BillingDetailsSkeleton;
