//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminBobDaybookController_findAll,
  Response$AdminBobDaybookController_findAll$Status$200,
  ResponseContentType$AdminBobDaybookController_findAll,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminBobDaybookController_findAll = (options: {
  params: Params$AdminBobDaybookController_findAll['parameter'];
  config?: QueryOptions<
    Response$AdminBobDaybookController_findAll$Status$200[ResponseContentType$AdminBobDaybookController_findAll]
  >;
}) =>
  useApiClient<
    Params$AdminBobDaybookController_findAll,
    Response$AdminBobDaybookController_findAll$Status$200[ResponseContentType$AdminBobDaybookController_findAll]
  >({
    method: 'AdminBobDaybookController_findAll',
    params: { parameter: options.params },
    config: options.config,
  });
