import { useState, useEffect } from 'react';

type InfiniteScrollProps = {
  isLastPage: boolean;
};

type InfiniteScrollParams = {
  currentPage: number;
  resetScroll: () => void;
};

const useInfiniteScrollByPage = ({
  isLastPage,
}: InfiniteScrollProps): InfiniteScrollParams => {
  const [isReachedBottom, setIsReachedBottom] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrolledTo = window.scrollY + window.innerHeight;
      const threshold = 300;

      if (document.body.scrollHeight - threshold <= scrolledTo) {
        setIsReachedBottom(true);
      } else {
        setIsReachedBottom(false);
      }
    };

    if (isLastPage) {
      window.removeEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLastPage]);

  useEffect(() => {
    if (isReachedBottom && !isLastPage) {
      setCurrentPage((prevState) => prevState + 1);
    }
  }, [isReachedBottom, isLastPage]);

  const resetScroll = () => {
    setCurrentPage(1);
    setIsReachedBottom(false);
  };

  return {
    currentPage,
    resetScroll,
  };
};

export default useInfiniteScrollByPage;
