import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import _ from 'lodash';

import { Amount } from '@entities';

import { Flex } from 'antd';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type BillingItemProps = {
  selectedId: string;
  details: Schemas.BillingPeriod;
};

const BillingItem: FC<BillingItemProps> = ({ selectedId, details }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleSelect = (id: string) => {
    navigate(`/billing/${id}${search}`);
  };

  const getDatePeriod = (period: string) => dayjs(period).format('DD.MM.YYYY');

  return (
    <S.Item
      gap={6}
      selected={selectedId === details.id}
      onClick={() => handleSelect(details.id)}
      vertical
    >
      <Flex align="flex-start" justify="space-between" gap={8}>
        <S.Title>
          {getDatePeriod(details.startDate)} - {getDatePeriod(details.endDate)}
        </S.Title>

        <Amount
          currencyCode="EUR"
          amount={_.sumBy(details.items, 'totalPrice')}
          withoutType
        />
      </Flex>

      <S.ServicesCount>{details.items.length} services</S.ServicesCount>
    </S.Item>
  );
};

export default BillingItem;
