export const formatFileSize = (size: number): string => {
  const units = ['b', 'kb', 'mb', 'gb', 'tb'];

  if (size === 0) {
    return '0 b';
  }

  const unitIndex = Math.floor(Math.log(size) / Math.log(1024));
  const adjustedSize = size / Math.pow(1024, unitIndex);

  return `${adjustedSize.toFixed(2)} ${units[unitIndex]}`;
};
