import { Button, Flex } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAdminBillingPeriodController_findAll } from '@api-client/generated/AdminBillingPeriodController/findAll';
import { useAdminBillingPeriodController_findOneById } from '@api-client/generated/AdminBillingPeriodController/findOneById';
import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import { Scrollbar } from '@components';
import {
  BillingAction,
  BillingActionPeriod,
  BillingDetails,
  BillingList,
  BillingListEmpty,
} from '@entities';
import { useAccount } from '@hooks';

import * as S from './styled';

type BillingPeriod = Schemas.BillingPeriod;
type InvoiceItem = Schemas.InvoiceItem;

type ActionType = 'add' | 'edit';

const Billing: FC = () => {
  const { id: billingId } = useParams();
  const { companyId } = useAccount();

  const [modalActionVisible, setModalActionVisible] = useState(false);
  const [modalActionType, setModalActionType] = useState<ActionType>('add');
  const [selectedInvoiceService, setSelectedInvoiceService] =
    useState<InvoiceItem | null>(null);

  const [modalActionPeriodVisible, setModalActionPeriodVisible] =
    useState(false);
  const [modalActionPeriodType, setModalActionPeriodType] =
    useState<ActionType>('add');

  const [billingPeriodsList, setBillingPeriodsList] = useState<BillingPeriod[]>(
    []
  );

  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod | null>(
    null
  );

  const { data: periodsList, refetch: refetchBillingPeriods } =
    useAdminBillingPeriodController_findAll({
      params: {
        companyId: companyId!,
      },
    });

  useEffect(() => {
    if (periodsList) {
      setBillingPeriodsList(periodsList);
    }
  }, [periodsList]);

  const { data: period, isFetching: isFetchingBillingPeriod } =
    useAdminBillingPeriodController_findOneById({
      params: {
        companyId: companyId!,
        id: billingId!,
      },
      config: {
        enabled: !!billingId,
        refetchOnWindowFocus: false,
      },
    });

  useEffect(() => {
    if (period) {
      setBillingPeriod(period);
    }
  }, [period]);

  const handleOpenModal = (
    type: ActionType,
    invoice: InvoiceItem | null = null
  ) => {
    setModalActionVisible(true);
    setModalActionType(type);

    if (invoice) {
      setSelectedInvoiceService(invoice);
    }
  };

  const handleCloseModal = () => {
    setModalActionVisible(false);
    setModalActionType('add');
    setSelectedInvoiceService(null);
  };

  const handleOpenPeriodModal = (type: ActionType) => {
    setModalActionPeriodVisible(true);
    setModalActionPeriodType(type);
  };

  const handleClosePeriodModal = () => {
    setModalActionPeriodVisible(false);
    setModalActionPeriodType('add');
  };

  const handleAfterRemove = (id: string) => {
    if (billingId === id) {
      setBillingPeriod(null);
    }

    setBillingPeriodsList((prevState) =>
      prevState.filter((period) => period.id !== id)
    );
  };

  const handleAfterUpdate = (updatedPeriod: BillingPeriod) => {
    setBillingPeriod((prevState) => ({ ...prevState, ...updatedPeriod }));

    setBillingPeriodsList((prevState) =>
      prevState.map((period) =>
        period.id === updatedPeriod.id
          ? { ...period, ...updatedPeriod }
          : period
      )
    );
  };

  return (
    <Flex gap={24} vertical>
      <BillingAction
        details={billingPeriod}
        selected={selectedInvoiceService}
        type={modalActionType}
        open={modalActionVisible}
        onAfterUpdate={handleAfterUpdate}
        onCancel={handleCloseModal}
      />

      <BillingActionPeriod
        details={billingPeriod}
        type={modalActionPeriodType}
        open={modalActionPeriodVisible}
        onAfterCreate={refetchBillingPeriods}
        onAfterUpdate={handleAfterUpdate}
        onCancel={handleClosePeriodModal}
      />

      <Flex align="flex-start" gap={30}>
        <S.Inner>
          <S.Header align="center" justify="space-between">
            <S.Title>Billing</S.Title>

            <Button
              icon={<IconPlus width={24} />}
              type="secondary"
              onClick={() => handleOpenPeriodModal('add')}
              size="large"
            />
          </S.Header>

          <Scrollbar height="calc(100vh - 272px)" withoutPadding>
            {!billingPeriodsList.length ? (
              <BillingListEmpty onAdd={() => handleOpenPeriodModal('add')} />
            ) : (
              <BillingList dataSource={billingPeriodsList} />
            )}
          </Scrollbar>
        </S.Inner>

        <S.Details>
          <BillingDetails
            details={billingPeriod}
            isLoading={isFetchingBillingPeriod}
            onAdd={() => handleOpenModal('add')}
            onEdit={() => handleOpenPeriodModal('edit')}
            onEditService={(invoice) => handleOpenModal('edit', invoice)}
            onAfterRemove={handleAfterRemove}
          />
        </S.Details>
      </Flex>
    </Flex>
  );
};

export default Billing;
