import styled from '@emotion/styled';
import { type FlexProps, Flex } from 'antd';

export const Inner = styled(Flex)<FlexProps>`
  .ui-form-item {
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
`;

export const Details = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrayLight};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 32px;

  .ui-picker {
    width: 100%;
  }
`;
