import { FC } from 'react';
import dayjs from 'dayjs';

import { Flex, Row, Col } from 'antd';
import { Schemas } from '@api-client/generated/types';

import { Amount, CompanyDetailsInfoCell } from '@entities';
import { getAddressLine } from '@utils';

import { IconEditUnderline } from '@assets';
import { legalList, taxPeriodsList } from '@constants';

import * as S from './styled';

type CompanyDetailsInfoProps = {
  name?: string;
  referenceId?: string;
  details?: Schemas.CompanyDetailsDto;
  onEdit: () => void;
};

const getCompanyAndLegalDetails = (name?: string, legalForm?: string) => {
  const fullLabel: string[] = [];

  if (name) {
    fullLabel.push(name);
  }

  if (legalForm) {
    const selectedLegal = legalList.filter(
      (item) => item.value === legalForm
    )[0];

    fullLabel.push(selectedLegal.label);
  }

  return fullLabel.join(', ');
};

const getTaxPeriodLabel = (period: string) => {
  const taxPeriod = taxPeriodsList.filter((item) => item.value === period)[0];

  return taxPeriod.label.toLowerCase();
};

const CompanyDetailsInfo: FC<CompanyDetailsInfoProps> = ({
  name,
  referenceId,
  details,
  onEdit,
}) => (
  <S.Container>
    <S.Edit onClick={onEdit}>
      <IconEditUnderline width={16} height={16} /> Edit
    </S.Edit>

    <Row gutter={[20, 0]}>
      <Col span={12}>
        <Flex gap={16} vertical>
          <CompanyDetailsInfoCell
            label="Company name & legal form"
            value={getCompanyAndLegalDetails(name, details?.legalForm)}
            isVisible={name}
            copyText={name!}
          />

          <CompanyDetailsInfoCell
            label="Client reference"
            value={referenceId}
            isVisible={referenceId}
            copyText={referenceId!}
          />

          <CompanyDetailsInfoCell
            label="Business license"
            value={
              <>
                <span>{details?.businessLicenseNumber}</span>
                <span>{details?.businessLicenceActivityDescription}</span>
              </>
            }
            isVisible={
              details?.businessLicenseNumber ||
              details?.businessLicenceActivityDescription
            }
            copyText={`${details?.businessLicenseNumber} ${details?.businessLicenceActivityDescription}`}
          />

          <CompanyDetailsInfoCell
            label="Share capital"
            value={
              details?.shareCapital && (
                <Amount
                  amount={details?.shareCapital}
                  currencyCode="EUR"
                  withoutType
                />
              )
            }
            isVisible={details?.shareCapital}
            copyText={`€${details?.shareCapital}`}
          />

          <CompanyDetailsInfoCell
            label="Tax number"
            value={details?.taxNumber}
            isVisible={details?.taxNumber}
            copyText={details?.taxNumber}
          />

          <CompanyDetailsInfoCell
            label="VAT details"
            value={
              <>
                <span>{details?.vatId}</span>
                {details?.vatPeriodicity && (
                  <span>Paid {getTaxPeriodLabel(details?.vatPeriodicity)}</span>
                )}
              </>
            }
            isVisible={details?.vatId || details?.vatPeriodicity}
            copyText={`${details?.vatId} ${details?.vatPeriodicity ? `Paid ${getTaxPeriodLabel(details?.vatPeriodicity)}` : ''}`}
          />
        </Flex>
      </Col>

      <Col span={12}>
        <Flex gap={16} vertical>
          <CompanyDetailsInfoCell
            label="RCS information"
            value={
              <>
                <span>{details?.rscNumber}</span>
                <span>
                  {dayjs(details?.incorporationDate).format('DD.MM.YYYY')}
                </span>
              </>
            }
            isVisible={details?.rscNumber || details?.incorporationDate}
            copyText={`${details?.rscNumber} ${dayjs(details?.incorporationDate).format('DD.MM.YYYY')}`}
          />

          <CompanyDetailsInfoCell
            label="Financial year"
            value={details?.financialYear}
            isVisible={details?.financialYear}
            copyText={details?.financialYear}
          />

          <CompanyDetailsInfoCell
            label="First financial year"
            value={details?.firstFinancialYear}
            isVisible={details?.firstFinancialYear}
            copyText={details?.firstFinancialYear}
          />

          <CompanyDetailsInfoCell
            label="Legal address"
            value={details?.address && getAddressLine(details.address)}
            isVisible={details?.address}
            copyText={details?.address ? getAddressLine(details.address) : ''}
          />

          <CompanyDetailsInfoCell
            label="Bank details"
            value={
              <>
                <span>{details?.bankData?.bankName}</span>
                <span>{details?.bankData?.iban}</span>
              </>
            }
            isVisible={details?.bankData?.bankName || details?.bankData?.iban}
            copyText={`${details?.bankData?.bankName} ${details?.bankData?.iban}`}
          />
        </Flex>
      </Col>
    </Row>
  </S.Container>
);

export default CompanyDetailsInfo;
