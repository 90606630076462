import { capitalize } from "lodash";

export const transformToOptions = <T extends Record<string, string>>(dynamicEnum: T): { value: string; label: string }[] =>
    Object.values(dynamicEnum).map((value) => {
        const label = value
            .split('_')
            .map((word) => capitalize(word))
            .join(' ');

        return { value, label };
    });
