import { z } from 'zod';

import { Schemas } from '@api-client/generated/types';

type StepRequestDto = Schemas.StepRequestDto;

export const Step = z.object({
  id: z.string().uuid(),
  name: z.string(),
  type: z.enum(['action', 'notification']),
  assigneeId: z.string().uuid(),
  hoursToComplete: z.number(),
  reminderInterval: z.number(),
  result: z.string(),
  isRequiredNotes: z.boolean(),
  isRequiredFiles: z.boolean(),
});

export const isFullStep = (
  step: Partial<StepRequestDto>
): step is StepRequestDto => Step.safeParse(step).success;
