import { downloadFile } from './downloadFile';
import { message } from 'antd';

type DownloadZipProps = {
  url: string;
  headers: RequestInit;
  fileName: string;
};

export const downloadZip = async ({ url, headers, fileName }: DownloadZipProps) => {
  const response = await fetch(url, headers);

  if(!response.ok) {
    message.error('No files mathing your request');
    return;
  }

  const file = await response.blob();

  const newUrl = window.URL.createObjectURL(file);
  downloadFile(newUrl, fileName)
};