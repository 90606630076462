import { Col, Flex, Row } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconEditUnderline } from '@assets';

import * as S from './styled';
import { CompanyDetailsInfoCell } from '@entities';
import { capitalize } from 'lodash';

type CompanyDetailsServiceProps = {
  company: Schemas.AdminCompanyDto;
  onEdit: () => void;
};

const CompanyDetailsServiceInfo: FC<CompanyDetailsServiceProps> = ({
  company,
  onEdit,
}) => (
  <Row>
    <Col span={12}>
      <S.Container>
        <S.Edit onClick={onEdit}>
          <IconEditUnderline width={16} height={16} /> Edit
        </S.Edit>

        <Flex gap={16} vertical>
          <CompanyDetailsInfoCell
            label="Status"
            value={capitalize(company.status?.split("_").join(" "))}
            isVisible={true}
          />
        </Flex>
      </S.Container>
    </Col>
  </Row>
);

export default CompanyDetailsServiceInfo;
