import styled from '@emotion/styled';
import { Button, type ButtonProps } from 'antd';

export const UploadButton = styled(Button)<ButtonProps>`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
