//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminTaxController_updateOneById,
  Response$AdminTaxController_updateOneById$Status$200,
  ResponseContentType$AdminTaxController_updateOneById,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminTaxController_updateOneById = () =>
  useApiClientMutation<
    Params$AdminTaxController_updateOneById,
    Response$AdminTaxController_updateOneById$Status$200[ResponseContentType$AdminTaxController_updateOneById]
  >({ method: 'AdminTaxController_updateOneById' });
