import { Typography } from 'antd';

import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
`;

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 20px;
    margin-bottom: 12px;
  }
`;

export const Details = styled(Typography.Paragraph)`
  &.ui-typography {
    font-size: 16px;
  }
`;
