import { FC, PropsWithChildren, ReactNode } from 'react';

import * as S from './styled';

type CardProps = {
  title?: ReactNode;
  padding?: number;
  containerId?: string;
  minHeight?: number;
  minHeightSuffix?: 'vh' | 'px' | '%';
};

const Card: FC<PropsWithChildren<CardProps>> = ({
  title,
  children,
  padding,
  minHeight,
  containerId = undefined,
  minHeightSuffix = 'px',
}) => (
  <S.Container
    padding={padding}
    id={containerId}
    minHeight={minHeight}
    minHeightSuffix={minHeightSuffix}
  >
    {title && <S.Title>{title}</S.Title>}
    {children}
  </S.Container>
);

export default Card;
