import { FC } from 'react';

import { type SelectProps } from 'antd';

import { Select } from '@components';
import { typesList } from '@constants';

const FilterTypes: FC<SelectProps> = ({ ...rest }) => (
  <Select
    width={140}
    placeholder="Type"
    options={typesList}
    size="large"
    {...rest}
  />
);

export default FilterTypes;
