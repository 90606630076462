import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)<FlexProps>`
  width: 300px;
  height: calc(100vh - 175px);
  background-color: ${({ theme }) => theme.colors.textDisabled};
  border-radius: 10px 0 0 10px;
  padding: 20px;
`;
