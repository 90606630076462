//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminCompanyFileController_create,
  Response$AdminCompanyFileController_create$Status$201,
  ResponseContentType$AdminCompanyFileController_create,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminCompanyFileController_create = () =>
  useApiClientMutation<
    Params$AdminCompanyFileController_create,
    Response$AdminCompanyFileController_create$Status$201[ResponseContentType$AdminCompanyFileController_create]
  >({ method: 'AdminCompanyFileController_create' });
