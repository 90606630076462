import React, { useState } from 'react';
import { List, Flex, Button } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { PersonFileModal } from '@entities';

type IncorporationKYCProps = {
  address: Schemas.Address | null;
  files: Schemas.CompanyFile[];
};

const AddressKYC: React.FC<IncorporationKYCProps> = ({ address, files }) => {
  const [activeFile, setActiveFile] = useState<Schemas.CompanyFile | null>(
    null
  );
  const [isOpenPersonFileModal, setIsOpenPersonFileModal] = useState(false);

  const closePersonFile = () => {
    setActiveFile(null);
    setIsOpenPersonFileModal(false);
  };

  const selectFile = (file: Schemas.CompanyFile) => {
    setActiveFile(file);
    setIsOpenPersonFileModal(true);
  };

  const getStatusDescription = (file: Schemas.CompanyFile) => {
    if (file.isApproved) {
      return 'Approved';
    }

    if (file.hasError) {
      return 'Has error, waiting for client';
    }

    return 'Needs to be checked';
  };

  return (
    <>
      {activeFile && (
        <PersonFileModal
          open={isOpenPersonFileModal}
          file={activeFile!}
          title="Address proof"
          onCancel={closePersonFile}
        />
      )}
      <Flex>
        {address && (
          <div>
            {address?.houseNumber} {address?.addressLine},{address?.postalCode}{' '}
            {address?.city},{address?.countryCode}
          </div>
        )}

        <List
          dataSource={files}
          renderItem={(file, index) => (
            <List.Item>
              {index + 1}. {getStatusDescription(file)}
              <Button
                onClick={() => {
                  selectFile(file);
                }}
                type="link"
              >
                View
              </Button>
            </List.Item>
          )}
        />
      </Flex>
    </>
  );
};

export default AddressKYC;
