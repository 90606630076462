import { FC } from 'react';

import { Button } from 'antd';

import * as S from './styled';

type BillingEmptyProps = {
  onAdd?: () => void;
};

const BillingEmpty: FC<BillingEmptyProps> = ({ onAdd }) => (
  <S.Container>
    <S.Title>There are no billings yet</S.Title>

    <S.Description>
      Use billings to generate invoices and other payment documents
    </S.Description>

    <Button onClick={onAdd} size="large" ghost>
      Add billing period
    </Button>
  </S.Container>
);

export default BillingEmpty;
