import styled from '@emotion/styled';
import { Flex} from 'antd';

export const Root = styled(Flex)`
  height: calc(100vh - 200px);
  overflow: hidden;
`;

export const Body = styled(Flex)`
  flex: 1 1 100%;
  min-height: 0;
`;
