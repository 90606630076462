import { FC, useState } from 'react';

import { Flex, Space, Dropdown } from 'antd';
import { IconEdit, IconMoreVertical, IconTrash, IconDownload } from '@assets';
import { colors } from '@theme';

import * as S from './styled';

type PayslipsListItemProps = {
  name: string;
  url: string;
  isSelected: boolean;
  onClick: VoidFunction;
  onChangeName: (name: string) => void;
  onDelete: () => void;
};

const PayslipsListItem: FC<PayslipsListItemProps> = ({
  name,
  url,
  isSelected,
  onClick,
  onChangeName,
  onDelete,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [documentName, setDocumentName] = useState(name);

  const handleChangeName = () => {
    onChangeName(documentName!);
    setIsEdit(false);
  };

  return (
    <S.Container isSelected={isSelected} onClick={onClick}>
      <Flex justify="space-between" gap={20}>
        {isEdit ? (
          <S.InputDocumentName
            size="large"
            value={documentName}
            onPressEnter={handleChangeName}
            onChange={(e) => setDocumentName(e.target.value)}
            onBlur={handleChangeName}
          />
        ) : (
          <Space size={6} align="center">
            <S.Label>Attached: {name}</S.Label>
          </Space>
        )}

        <Flex align="center" gap={10}>
          <a href={url} download={name} target="_blank" rel="noreferrer">
            <IconDownload color={colors.blackSecondary} />
          </a>

          <Dropdown
            menu={{
              items: [
                {
                  key: 'edit',
                  label: 'Change name',
                  icon: <IconEdit />,
                  onClick: () => setIsEdit(true),
                },
                {
                  key: 'delete',
                  label: 'Delete',
                  icon: <IconTrash />,
                  danger: true,
                  onClick: onDelete,
                },
              ],
            }}
          >
            <IconMoreVertical />
          </Dropdown>
        </Flex>
      </Flex>
    </S.Container>
  );
};

export default PayslipsListItem;
