import { FC } from 'react';

import { Flex } from 'antd';

import { Amount } from '@entities';

import { IconEditUnderline } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type InvoiceItem = Schemas.InvoiceItem;

type BillingServiceItemProps = {
  details: InvoiceItem;
  onEdit: (details: InvoiceItem) => void;
};

const BillingServiceItem: FC<BillingServiceItemProps> = ({
  details,
  onEdit,
}) => (
  <S.Item gap={6} justify="center" vertical>
    <Flex align="center" justify="space-between" gap={16}>
      <Flex vertical>
        <S.Title>{details.name}</S.Title>
        {details.description}
      </Flex>

      <Amount
        className="service-amount"
        currencyCode="EUR"
        amount={details.totalPrice}
        withoutType
      />

      <S.ButtonEdit
        className="service-button-edit"
        type="link"
        icon={<IconEditUnderline />}
        size="large"
        onClick={() => onEdit(details)}
      />
    </Flex>
  </S.Item>
);

export default BillingServiceItem;
