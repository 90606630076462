import { FC, useEffect, useState } from 'react';

import { Flex, Modal, Pagination } from 'antd';

import { DocumentsContent } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type DocumentsModalProps = {
  companyId?: string;
  open: boolean;
  onCancel: () => void;
};

type FilterOptions = Record<string, unknown>;

const DocumentsModal: FC<DocumentsModalProps> = ({
  companyId,
  open,
  onCancel,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [metaData, setMetaData] = useState<Schemas.PaginationMetadata | null>(
    null
  );

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});

  useEffect(() => {
    setCurrentPage(1);
  }, [filterOptions]);

  const handleCancel = () => {
    setCurrentPage(1);
    setMetaData(null);
    setFilterOptions({});

    onCancel();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      width={1280}
      title="Documents"
      open={open}
      onCancel={handleCancel}
      footer={null}
      centered
    >
      <Flex vertical>
        <DocumentsContent
          externalCompanyId={companyId}
          paginationCurrentPage={currentPage}
          withSearchParams={false}
          onPagination={setMetaData}
          onFilter={setFilterOptions}
        />

        <S.Divider />

        <Pagination
          current={metaData?.currentPage}
          pageSize={metaData?.perPage}
          total={metaData?.totalRecords}
          onChange={setCurrentPage}
        />
      </Flex>
    </Modal>
  );
};

export default DocumentsModal;
