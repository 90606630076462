import styled from '@emotion/styled';
import { Button, Flex } from 'antd';

export const Viewer = styled.div`
  position: relative;
`;

export const Wrapper = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => (width ? `${width}` : '100%')};
  max-width: ${({ width }) => (width ? `${width}` : '100%')};
  height: ${({ height }) => (height ? `${height}` : '100%')};
  max-height: ${({ height }) => (height ? `${height}` : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
`;

export const DocumentContainer = styled.div`
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
`;

export const Panel = styled(Flex)`
  width: 258px;
  height: 66px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  background: ${({ theme }) => theme.colors.badgeGrey};
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);

  position: absolute;
  bottom: 30px;
  left: calc(100% - 80%);
  z-index: 2;
`;
export const PanelButton = styled(Button)`
  width: 86px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 12px 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  gap: 4px;
  color: ${({ theme }) => theme.colors.text400};

  && svg {
    width: 18px;
    height: 18px;
  }
`;

export const Visible = styled.div<{ visible: boolean; reverse?: boolean }>`
  display: ${({ visible, reverse }) =>
    visible ? (reverse ? 'none' : 'flex') : reverse ? 'flex' : 'none'};
`;
