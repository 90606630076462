import { FC, useState } from 'react';

import { IconUpload } from '@assets';

import PayslipsModalUpload from '../PayslipsModalUpload';

import * as S from './styled';

type PayslipsUploadButtonProps = {
  onAfterUpload: () => void;
};

const PayslipsUploadButton: FC<PayslipsUploadButtonProps> = ({
  onAfterUpload,
}) => {
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState(false);

  return (
    <>
      <PayslipsModalUpload
        open={isVisibleModalUpload}
        onAfterUpload={onAfterUpload}
        onCancel={() => setIsVisibleModalUpload(false)}
      />

      <S.UploadButton
        type="primary"
        icon={<IconUpload />}
        size="large"
        onClick={() => setIsVisibleModalUpload(true)}
        ghost
      >
        Upload document
      </S.UploadButton>
    </>
  );
};

export default PayslipsUploadButton;
