//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminTaxController_findAll,
  Response$AdminTaxController_findAll$Status$200,
  ResponseContentType$AdminTaxController_findAll,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminTaxController_findAll = (options: {
  params: Params$AdminTaxController_findAll['parameter'];
  config?: QueryOptions<
    Response$AdminTaxController_findAll$Status$200[ResponseContentType$AdminTaxController_findAll]
  >;
}) =>
  useApiClient<
    Params$AdminTaxController_findAll,
    Response$AdminTaxController_findAll$Status$200[ResponseContentType$AdminTaxController_findAll]
  >({
    method: 'AdminTaxController_findAll',
    params: { parameter: options.params },
    config: options.config,
  });
