import { FC, useState } from 'react';

import { Flex, Space } from 'antd';

import { IconDownload, IconArrowDown, IconArrowUp } from '@assets';

import * as S from './styled';

type UploadFileProps = {
  name?: string;
  type?: string;
  url?: string;
};

const UploadFile: FC<UploadFileProps> = ({ url, type, name }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const isTypeImage = type?.includes('image');

  const isTypePDF = type?.includes('pdf');

  return (
    <Flex vertical>
      <S.Container>
        <Space size={6} align="center">
          <S.Label>1 file uploaded</S.Label>

          <S.Download>
            <a href={url} target="_blank">
              <IconDownload />
            </a>
          </S.Download>
        </Space>

        <S.Preview>
          <S.PreviewLink
            onClick={() => setIsPreviewOpen((prevState) => !prevState)}
          >
            Preview {isPreviewOpen ? <IconArrowUp /> : <IconArrowDown />}
          </S.PreviewLink>

          {isPreviewOpen && (
            <S.PreviewContent>
              {isTypeImage && <img src={url} alt={name} />}

              {isTypePDF && <iframe src={url} />}
            </S.PreviewContent>
          )}
        </S.Preview>
      </S.Container>
    </Flex>
  );
};

export default UploadFile;
