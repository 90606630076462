import styled from '@emotion/styled';

export const ActionBar = styled.div`
  height: 76px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.borderSecondary};
  display: flex;
  align-items: center;
  padding: 0 50px;
  position: fixed;
  left: 153px;
  right: 0;
  bottom: 0;
  z-index: 1;

  .ui-btn {
    height: 44px;
    padding-inline: 32px;
  }
`;

export const Container = styled.div`
  padding-bottom: 76px;
`;