import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Space } from 'antd';

import { IconLogout } from '@assets';
import { useAccount } from '@hooks';

import * as S from './styled';

const Header: FC = () => {
  const navigate = useNavigate();

  const { account, company, clearAccount } = useAccount();

  const handleLogout = () => {
    clearAccount();
    navigate('/login');
  };

  return (
    <S.Header>
      <Flex gap={32}>
        {company ? (
          <Space size={8}>
            <S.Text>You now working with:</S.Text>
            <S.CompanyName>{company?.name}</S.CompanyName>
          </Space>
        ) : <div/>}
      </Flex>

      <Flex gap={80}>
        <Space size={8}>
          <S.UserName>{account?.email}</S.UserName>
        </Space>
        <S.Logout onClick={handleLogout}>
          <IconLogout />
        </S.Logout>
      </Flex>
    </S.Header>
  );
};

export default Header;
