import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Root = styled(Flex)`
  max-height: 100%;
  width: 337px;
  flex: 0 0 auto;
  overflow-y: hidden;
`;

export const Body = styled(Flex)`
  flex: 1 1;
  overflow-y: auto;
  padding-bottom: 8px;
`;

export const EmptyScreen = styled(Body)`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 20px;
`;
