import { Link, type LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { type TagProps, Tag } from 'antd';
import { CompanyStatus } from '@constants';

export const CompanyLink = styled(Link)<LinkProps>`
  font-weight: 600;
`;

export const SelectedTag = styled(Tag)<TagProps>`
  margin: 0;
`;

export const FilterWrapper = styled.div`
  .ui-form {
    .ui-flex {
      justify-content: initial;
      gap: 24px;
    }
  }
`;

export const StatusTag = styled(Tag)<TagProps & { status: CompanyStatus }>`
  padding: auto 12px;
  border: none;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case CompanyStatus.Incorporating:
        return theme.colors.strokeGrey;
      case CompanyStatus.Incorporated:
        return theme.colors.badgeSuccess;
      case CompanyStatus.InService:
        return theme.colors.bgGrey;
      default:
        return theme.colors.bgGrey;
    }
  }};
  color: ${({ status, theme }) => {
    if (status === CompanyStatus.Incorporated) {
      return `${theme.colors.textSuccess}`;
    }

    return `${theme.colors.textBlue400}`
  }};
`;



export const TableContainer = styled.div`
  max-width: 720px;
`;
