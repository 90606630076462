//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Params$AdminDocumentController_bulkUpdate } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminDocumentController_bulkUpdate = () =>
  useApiClientMutation<Params$AdminDocumentController_bulkUpdate, never>({
    method: 'AdminDocumentController_bulkUpdate',
  });
