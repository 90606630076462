import styled from '@emotion/styled';

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const Value = styled.div`
  font-size: 15px;

  .ui-typography {
    font-size: 15px;
  }
`;
