import { FC } from 'react';

import { formatNumber } from '@constants';

import * as S from './styled';

type AmountProps = {
  className?: string;
  currencyCode: string;
  amount: number;
  withoutType?: boolean;
};

const Amount: FC<AmountProps> = ({
  className,
  withoutType,
  currencyCode,
  amount,
}) => (
  <S.Amount
    className={className}
    type={!withoutType ? (amount >= 0 ? 'success' : 'danger') : undefined}
  >
    {formatNumber(amount, { style: 'currency', currency: currencyCode })}
  </S.Amount>
);

export default Amount;
