import { RouteObject } from 'react-router-dom';

import { STORAGE_TOKEN_KEY } from '@constants';
import { AccessDenied, NotFound } from '@entities';
import { LayoutProtected, LayoutPublic } from '@layouts';

import {
  Accounts,
  Billing,
  Companies,
  CompanyDetails,
  DocumentDetails,
  DocumentExpenseDetails,
  Documents,
  Incorporation,
  Legal,
  Login,
  Settings,
  Taxes,
  TaxesDetails,
  TransactionDetails,
  Transactions,
  ChatAndTasks,
  Payslips,
  Processes,
} from '@pages';

export interface Route {
  path: string;
  element: JSX.Element;
  children?: { path: string; element: JSX.Element; index?: boolean }[];
  index?: boolean;
}

const chatAndTaskRoutes: RouteObject[] = [
  {
    path: '/chat-tasks',
    element: <ChatAndTasks />,
  },
  {
    path: '/chat-tasks/:companyId/chats',
    element: <ChatAndTasks />,
  },
  {
    path: '/chat-tasks/:companyId/chats/:id',
    element: <ChatAndTasks />,
  },
];

const adminRoutes: RouteObject[] = [
  {
    path: '/',
    element: <LayoutProtected />,
    children: [
      {
        index: true,
        path: '/companies',
        element: <Companies />,
      },
      {
        path: '/companies/:id',
        element: <CompanyDetails />,
      },
      {
        path: '/documents',
        element: <Documents />,
      },
      {
        path: '/documents/bob50/:id',
        element: <DocumentExpenseDetails />,
      },
      {
        path: '/documents/:id',
        element: <DocumentDetails />,
      },
      {
        path: '/transactions',
        element: <Transactions />,
      },
      {
        path: '/transactions/:id',
        element: <TransactionDetails />,
      },
      {
        path: '/settings',
        element: <Settings />,
      },
      {
        path: '/accounts',
        element: <Accounts />,
      },
      {
        path: '/incorporation',
        element: <Incorporation />,
      },
      {
        path: '/legal',
        element: <Legal />,
      },
      {
        path: '/taxes',
        element: <Taxes />,
      },
      {
        path: '/billing',
        element: <Billing />,
      },
      {
        path: '/billing/:id',
        element: <Billing />,
      },
      {
        path: '/processes',
        element: <Processes />,
      },
      ...chatAndTaskRoutes,
      {
        path: '/payslips',
        element: <Payslips />,
      },
      {
        path: '/payslips/:id',
        element: <Payslips />,
      },
    ],
  },
  {
    path: '/',
    element: <LayoutPublic />,
    children: [
      {
        index: true,
        path: '/login',
        element: <Login />,
      },
    ],
  },
  {
    path: '/',
    element: localStorage.getItem(STORAGE_TOKEN_KEY) ? (
      <LayoutProtected />
    ) : (
      <LayoutPublic />
    ),
    children: [
      ...(localStorage.getItem(STORAGE_TOKEN_KEY)
        ? [
          {
            path: '*',
            element: <NotFound />,
          },
        ]
        : [
          {
            path: '*',
            element: <Login />,
          },
        ]),
    ],
  },
];

export const getRoutes = (role?: string): RouteObject[] => {
  if (!role) return adminRoutes;
  switch (role) {
    case 'admin':
      return adminRoutes;
    case 'kyc':
      return [
        {
          path: '/',
          element: <LayoutProtected />,
          children: [
            {
              index: true,
              path: '/companies',
              element: <Companies />,
            },
            {
              path: '/companies/:id',
              element: <CompanyDetails />,
            },
            {
              path: '/documents',
              element: <AccessDenied />,
            },
            {
              path: '/documents/bob50/:id',
              element: <DocumentExpenseDetails />,
            },
            {
              path: '/documents/:id',
              element: <AccessDenied />,
            },
            {
              path: '/transactions',
              element: <AccessDenied />,
            },
            {
              path: '/transactions/:id',
              element: <AccessDenied />,
            },
            {
              path: '/settings',
              element: <AccessDenied />,
            },
            {
              path: '/accounts',
              element: <AccessDenied />,
            },
            {
              path: '/incorporation',
              element: <Incorporation />,
            },
            {
              path: '/legal',
              element: <Legal />,
            },
            {
              path: '/taxes',
              element: <AccessDenied />,
            },
            {
              path: '/taxes/create',
              element: <AccessDenied />,
            },
            {
              path: '/taxes/:id',
              element: <AccessDenied />,
            },
            ...chatAndTaskRoutes,
            {
              path: '/payslips',
              element: <Payslips />,
            },
            {
              path: '/payslips/:id',
              element: <Payslips />,
            },
          ],
        },
        {
          path: '/',
          element: <LayoutPublic />,
          children: [
            {
              index: true,
              path: '/login',
              element: <Login />,
            },
          ],
        },
        {
          path: '/',
          element: localStorage.getItem(STORAGE_TOKEN_KEY) ? (
            <LayoutProtected />
          ) : (
            <LayoutPublic />
          ),
          children: [
            ...(localStorage.getItem(STORAGE_TOKEN_KEY)
              ? [
                {
                  path: '*',
                  element: <NotFound />,
                },
              ]
              : [
                {
                  path: '*',
                  element: <Login />,
                },
              ]),
          ],
        },
      ];
    case 'accountant':
      return [
        {
          path: '/',
          element: <LayoutProtected />,
          children: [
            {
              index: true,
              path: '/companies',
              element: <Companies />,
            },
            {
              path: '/companies/:id',
              element: <CompanyDetails />,
            },
            {
              path: '/documents',
              element: <Documents />,
            },
            {
              path: '/documents/bob50/:id',
              element: <DocumentExpenseDetails />,
            },
            {
              path: '/documents/:id',
              element: <DocumentDetails />,
            },
            {
              path: '/transactions',
              element: <Transactions />,
            },
            {
              path: '/transactions/:id',
              element: <TransactionDetails />,
            },
            {
              path: '/settings',
              element: <AccessDenied />,
            },
            {
              path: '/accounts',
              element: <Accounts />,
            },
            {
              path: '/incorporation',
              element: <AccessDenied />,
            },
            {
              path: '/legal',
              element: <Legal />,
            },
            {
              path: '/taxes',
              element: <Taxes />,
            },
            {
              path: '/taxes/create',
              element: <TaxesDetails type="create" />,
            },
            {
              path: '/taxes/:id',
              element: <TaxesDetails type="view" />,
            },
            ...chatAndTaskRoutes,
            {
              path: '/payslips',
              element: <Payslips />,
            },
            {
              path: '/payslips/:id',
              element: <Payslips />,
            },
          ],
        },
        {
          path: '/',
          element: <LayoutPublic />,
          children: [
            {
              index: true,
              path: '/login',
              element: <Login />,
            },
          ],
        },
        {
          path: '/',
          element: localStorage.getItem(STORAGE_TOKEN_KEY) ? (
            <LayoutProtected />
          ) : (
            <LayoutPublic />
          ),
          children: [
            ...(localStorage.getItem(STORAGE_TOKEN_KEY)
              ? [
                {
                  path: '*',
                  element: <NotFound />,
                },
              ]
              : [
                {
                  path: '*',
                  element: <Login />,
                },
              ]),
          ],
        },
      ];
    default:
      return adminRoutes;
  }
};
