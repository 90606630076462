import styled from '@emotion/styled';

type PreviewProps = {
  overlay?: boolean;
};

export const Preview = styled.div<PreviewProps>`
  background-color: ${({ overlay, theme }) =>
    overlay ? theme.colors.textDisabled : 'transparent'};
  border-radius: 12px;
  height: ${({ overlay }) => (overlay ? 'auto' : '600px')};

  & > iframe {
    width: 100%;
    height: 600px;
    border-radius: 12px;
    border: 0;
  }

  & > img {
    width: 100%;
    border-radius: 12px;
  }
`;
