import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const InfoLabel = styled.div`
  color: ${({ theme }) => theme.colors.textBlue400};
  font-size: 12px;
  padding-left: 16px;
`;

export const InfoValue = styled(Flex)<FlexProps>`
  border-radius: 6px;
  font-weight: 500;
  padding: 8px 32px 8px 16px;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgGrey};

    .ui-btn-copy-info-cell {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const Copy = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
  opacity: 0;
`;
