import { Schemas } from '@api-client/generated/types';

type PaginationNormalizer<T> = {
  records: T[];
  prevData: T[];
  currentPage: number;
  scrollPage: number;
  metadata?: Schemas.PaginationMetadata;
  setCurrentPage: (page: number) => void;
};

export const paginationNormalizer = <T>({
  metadata,
  records,
  prevData,
  currentPage,
  scrollPage,
  setCurrentPage,
}: PaginationNormalizer<T>) => {
  if (metadata?.totalRecords === 0) {
    return [];
  }

  if (!records.length) {
    if (scrollPage === 1) {
      return records;
    }
  } else {
    setCurrentPage(metadata?.currentPage || 1);

    if (scrollPage === 1) {
      return records;
    } else if (currentPage === scrollPage) {
      return prevData;
    } else {
      return [...prevData, ...records];
    }
  }
};
