//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminTaskFileController_create,
  Response$AdminTaskFileController_create$Status$201,
  ResponseContentType$AdminTaskFileController_create,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminTaskFileController_create = () =>
  useApiClientMutation<
    Params$AdminTaskFileController_create,
    Response$AdminTaskFileController_create$Status$201[ResponseContentType$AdminTaskFileController_create]
  >({ method: 'AdminTaskFileController_create' });
