import { Button, Flex, Modal } from 'antd';

import { Schemas } from '@api-client/generated/types';

type OrderedStepResponseDto = Schemas.OrderedStepResponseDto;

type ProcessesTemplatesDeleteStepModalProps = {
  step?: Partial<OrderedStepResponseDto>;
  isOpen: boolean;
  onClose: () => void;
  onDelete: (step: Partial<OrderedStepResponseDto>) => void;
};

const ProcessesTemplatesDeleteStepModal = (
  props: ProcessesTemplatesDeleteStepModalProps
) => {
  const handleDeleteClick = () => {
    if (props.step) {
      props.onDelete(props.step);
    }
  }

  return (
    <Modal
      open={props.isOpen}
      onClose={props.onClose}
      footer={
        <Flex gap={8} justify="flex-end">
          <Button type="secondary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleDeleteClick}
            danger
          >
            Delete
          </Button>
        </Flex>
      }
      destroyOnClose
    >
      Are you sure you want to delete step "{props.step?.name}"?
    </Modal>
  );
};

export default ProcessesTemplatesDeleteStepModal;
