import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Inner = styled(Flex)<FlexProps>`
  padding: 32px 0;
`;

export const ReportLink = styled.a<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
