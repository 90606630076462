import { Flex, type FlexProps } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

import { type MessagePositionUnit } from './ChatMessagesItem';

type MessageProps = {
  status: string;
  position: MessagePositionUnit;
};

export const Message = styled(Flex)<FlexProps & MessageProps>`
  min-width: 160px;
  max-width: 75%;
  background-color: ${({ theme, position, status }) => {
    if (status === 'errorsFound') return theme.colors.bgWarning;
    if (position === 'left') return theme.colors.bgGrey;
    if (position === 'right') return theme.colors.bgLightYellow;

    return theme.colors.bgGrey;
  }};
  border-radius: ${({ position }) => {
    if (position === 'left') return '15px 15px 15px 0';
    if (position === 'right') return '15px 15px 0 15px';

    return '15px 15px 15px 0';
  }};
  padding: 16px;
  margin-top: 8px;
  position: relative;

  &:hover {
    .chat-message-actions {
      opacity: 1;
    }
  }
`;

export const Text = styled(Flex)<FlexProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  p {
    min-height: 6px;
    margin-bottom: 0;
  }

  p + p,
  ul + p {
    margin-top: 8px;
  }

  ul li {
    list-style-type: disc;
    margin-left: 20px;
  }
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;

export const Actions = styled.div<{ position: MessagePositionUnit }>`
  width: 40px;
  height: 20px;
  background: ${({ theme, position }) =>
    position === 'left'
      ? `linear-gradient(to left, ${rgba(theme.colors.bgGrey, 0)}, ${theme.colors.bgGrey}, ${theme.colors.bgGrey})`
      : `linear-gradient(to right, ${rgba(theme.colors.bgLightYellow, 0)}, ${theme.colors.bgLightYellow}, ${theme.colors.bgLightYellow})`};
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 8px;
  opacity: 0;
  transition: 0.1s;
`;

export const DeletedBody = styled.div`
  font-style: italic;
`;
