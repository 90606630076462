import { Button, Checkbox, Flex, Form, Modal, type ModalProps } from 'antd';
import { message } from 'antd';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAdminCompanyController_updateOneById } from '@api-client/generated/AdminCompanyController/updateOneById';
import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type CompanyDetailsFeaturesEditProps = {
  featuresStatus?: Schemas.FeaturesStatusDto;
  onCancel: () => void;
  onAfterEdit: () => void;
} & ModalProps;

const CompanyDetailsFeaturesEdit: FC<CompanyDetailsFeaturesEditProps> = ({
  featuresStatus,
  open,
  onAfterEdit,
  onCancel,
  ...rest
}) => {
  const { id: companyId } = useParams();

  const [form] = Form.useForm();

  const { mutate: updateCompany, isPending: loading } =
    useAdminCompanyController_updateOneById();

  useEffect(() => {
    if (featuresStatus) {
      form.setFieldsValue({
        featuresStatus: Object.entries(featuresStatus)
          .filter(([_, value]) => value)
          .map(([key]) => key),
      });
    }
  }, [featuresStatus, form]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = (values: { featuresStatus: string[] }) => {
    updateCompany(
      {
        parameter: {
          id: companyId!,
        },
        requestBody: {
          featuresStatus: {
            invoiceConstructor:
              values.featuresStatus.includes('invoiceConstructor'),
          },
        },
      },
      {
        onSuccess: () => {
          message.success('Company information has been successfully updated');

          onAfterEdit();
          handleCancel();
        },
      }
    );
  };

  return (
    <Modal
      width={575}
      open={open}
      title="Edit features enabled"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <S.Card>
          <Form.Item name="featuresStatus">
            <Checkbox.Group
              options={[
                {
                  label: 'Invoice constructor',
                  value: 'invoiceConstructor',
                },
              ]}
            />
          </Form.Item>
        </S.Card>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={handleCancel} size="large">
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Save
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </Modal>
  );
};

export default CompanyDetailsFeaturesEdit;
