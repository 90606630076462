//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminTaskController_findAll,
  Response$AdminTaskController_findAll$Status$200,
  ResponseContentType$AdminTaskController_findAll,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminTaskController_findAll = (options: {
  params: Params$AdminTaskController_findAll['parameter'];
  config?: QueryOptions<
    Response$AdminTaskController_findAll$Status$200[ResponseContentType$AdminTaskController_findAll]
  >;
}) =>
  useApiClient<
    Params$AdminTaskController_findAll,
    Response$AdminTaskController_findAll$Status$200[ResponseContentType$AdminTaskController_findAll]
  >({
    method: 'AdminTaskController_findAll',
    params: { parameter: options.params },
    config: options.config,
  });
