import { Button, InputRef } from 'antd';
import { CSSProperties, useEffect, useRef } from 'react';

import { Schemas } from '@api-client/generated/types';
import { SearchControl } from '@entities';
import { useClickOutside } from '@hooks';

import * as S from './styled';

type StepResponseDto = Schemas.StepResponseDto;

type ProcessesTemplatesAddStepModalProps = {
  query: string;
  templateId: string;
  steps: StepResponseDto[];
  style?: CSSProperties;
  onCreateStep: () => void;
  onAddExistingStep: (step: StepResponseDto) => void;
  onQueryChange: (query: string) => void;
  onClose: () => void;
};

const ProcessesTemplatesAddStepModal = (
  props: ProcessesTemplatesAddStepModalProps
) => {
  const ref = useRef<InputRef>(null);

  const modalRef = useClickOutside(props.onClose);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <S.Modal ref={modalRef} gap={12} style={props.style} vertical>
      <S.Body flex="1 1 0" gap={8} vertical>
        <SearchControl
          ref={ref}
          value={props.query}
          onChange={(event) => props.onQueryChange(event.target.value)}
          fitWidth
        />
        <S.Steps gap={4} vertical>
          {props.steps.map((step) => (
            <S.Step key={step.id} onClick={() => props.onAddExistingStep(step)}>
              {step.name}
            </S.Step>
          ))}
        </S.Steps>
      </S.Body>
      <Button size="large" onClick={props.onCreateStep}>
        Create a new step
      </Button>
    </S.Modal>
  );
};

export default ProcessesTemplatesAddStepModal;
