import styled from '@emotion/styled';

import { type ScrollbarProps } from './Scrollbar';

export const Container = styled.div<ScrollbarProps>`
  width: 100%;
  height: ${({ height }) =>
    height ? (typeof height === 'number' ? `${height}px` : height) : '100%'};
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'inherit')};
  scrollbar-color: ${({ theme, isHidden }) =>
    isHidden ? 'transparent' : theme.colors.primary};
  padding-right: ${({ withoutPadding }) => (withoutPadding ? 0 : '16px')};
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme, isHidden }) =>
      isHidden ? 'transparent' : theme.colors.strokeGrey};
    border-radius: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme, isHidden }) =>
      isHidden ? 'transparent' : theme.colors.primary};
    border-radius: 4px;
  }
`;
