import { FC, type ReactNode } from 'react';

import * as S from './styled';

type InfoCellProps = {
  flex?: number;
  label: string;
  value: ReactNode | string;
};

const InfoCell: FC<InfoCellProps> = ({ label, value }) => (
  <S.Cell>
    <S.Label>{label}</S.Label>
    <S.Value>{value}</S.Value>
  </S.Cell>
);

export default InfoCell;
