import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { List } from 'antd';

import { BillingItem } from '@entities';

import { Schemas } from '@api-client/generated/types';

type BillingListProps = {
  dataSource: Schemas.BillingPeriod[];
};

const BillingList: FC<BillingListProps> = ({ dataSource }) => {
  const { id: billingId } = useParams();

  return (
    <List
      dataSource={dataSource}
      renderItem={(item) => (
        <BillingItem selectedId={billingId!} details={item} />
      )}
    />
  );
};

export default BillingList;
