import { Button, Flex, Form, Modal, type ModalProps } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAdminBillingPeriodController_create } from '@api-client/generated/AdminBillingPeriodController/create';
import { useAdminBillingPeriodController_updateOneById } from '@api-client/generated/AdminBillingPeriodController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { RangePicker } from '@entities';
import { useAccount } from '@hooks';

import * as S from './styled';

type BillingPeriod = Schemas.BillingPeriod;

type BillingActionPeriodProps = {
  details: BillingPeriod | null;
  type: 'add' | 'edit';
  onCancel: () => void;
  onAfterCreate: () => void;
  onAfterUpdate: (updatedPeriod: BillingPeriod) => void;
} & ModalProps;

const BillingActionPeriod: FC<BillingActionPeriodProps> = ({
  details,
  type,
  open,
  onCancel,
  onAfterCreate,
  onAfterUpdate,
  ...rest
}) => {
  const navigate = useNavigate();

  const { companyId } = useAccount();

  const [form] = Form.useForm();

  const { mutate: createBilling } = useAdminBillingPeriodController_create();
  const { mutate: updateBilling } =
    useAdminBillingPeriodController_updateOneById();

  useEffect(
    () => {
      if (details && type == 'edit') {
        form.setFieldValue('period', [
          dayjs(details.startDate),
          dayjs(details.endDate),
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [details, type]
  );

  const handleSubmit = (values: { period: [string, string] }) => {
    const body = {
      startDate: values.period[0],
      endDate: values.period[1],
    };

    if (details && type == 'edit') {
      updateBilling(
        {
          parameter: {
            companyId: companyId!,
            id: details.id,
          },
          requestBody: {
            ...details,
            ...body,
          },
        },
        {
          onSuccess: (response) => {
            onAfterUpdate(response);
            onCancel();

            form.resetFields();
          },
        }
      );
    } else {
      createBilling(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            ...body,
            items: [],
          },
        },
        {
          onSuccess: (response) => {
            onAfterCreate();
            onCancel();

            form.resetFields();

            navigate(`/billing/${response.id}`);
          },
        }
      );
    }
  };

  return (
    <Modal
      width={504}
      open={open}
      title={
        <Flex gap={6} vertical>
          {type === 'add' ? (
            <>
              <S.Title>Add new billing period</S.Title>

              <S.Details>
                Select period to add services provided during it
              </S.Details>
            </>
          ) : (
            <S.Title>Edit billing period</S.Title>
          )}
        </Flex>
      }
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <S.Inner gap={24} vertical>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
        >
          <S.Wrapper>
            <Form.Item
              label="Billing period"
              name="period"
              rules={[
                {
                  required: true,
                  message: 'Select a billing period',
                },
              ]}
            >
              <RangePicker variant="outlined" size="large" />
            </Form.Item>
          </S.Wrapper>

          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={onCancel} size="large">
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" size="large">
                {type === 'add' ? 'Add period' : 'Save'}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </S.Inner>
    </Modal>
  );
};

export default BillingActionPeriod;
