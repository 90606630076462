import { Flex } from 'antd';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useAdminCompanyController_findOneById } from '@api-client/generated/AdminCompanyController/findOneById';
import { Loader } from '@components';
import { CompanyDetailsCard, DetailsHeader } from '@entities';

const CompanyDetails: FC = () => {
  const { id: companyId } = useParams();

  const {
    data: company,
    isPending: loading,
    refetch,
  } = useAdminCompanyController_findOneById({
    params: {
      id: companyId!,
    },
  });

  if (!company || loading) {
    return <Loader />;
  }

  return (
    <Flex gap={24} vertical>
      <DetailsHeader></DetailsHeader>

      <Flex gap={30} vertical>
        <CompanyDetailsCard company={company} onAfterEdit={refetch} />
      </Flex>
    </Flex>
  );
};

export default CompanyDetails;
