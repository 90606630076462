import { FC } from 'react';

import {
  type FormItemProps,
  type InputProps,
  type InputNumberProps,
  type DatePickerProps,
  type ColProps,
  Col,
  Input,
  DatePicker,
} from 'antd';

import { type TextAreaProps } from 'rc-textarea/lib/interface';
import { type SelectProps, Select } from '@components';

import * as S from './styled';

type CommonProps = {
  form: FormItemProps;
};

type WrapperProps = {
  span?: ColProps['span'];
};

type InputControlProps = CommonProps & {
  type: 'input';
  control?: InputProps;
};

type TextAreaControlProps = CommonProps & {
  type: 'textarea';
  control?: TextAreaProps;
};

type InputNumberControlProps = CommonProps & {
  type: 'input-number';
  control?: InputNumberProps;
};

type SelectControlProps = CommonProps & {
  type: 'select';
  control?: SelectProps;
};

type DatePickerControlProps = CommonProps & {
  type: 'date-picker';
  control?: DatePickerProps;
};

type FormControlProps =
  | InputControlProps
  | InputNumberControlProps
  | TextAreaControlProps
  | SelectControlProps
  | DatePickerControlProps;

const FormControl: FC<FormControlProps> = ({ type, form, control }) => (
  <S.FormItem {...form}>
    {type === 'input' && <S.InputControl size="large" {...control} />}

    {type === 'input-number' && (
      <S.InputNumberControl size="large" {...control} />
    )}

    {type === 'textarea' && <Input.TextArea size="large" {...control} />}

    {type === 'select' && <Select size="large" showSearch {...control} />}

    {type === 'date-picker' && <DatePicker size="large" {...control} />}
  </S.FormItem>
);

const DocumentExpenseWrapperFormControl: FC<
  FormControlProps & WrapperProps
> = ({ span, ...rest }) => {
  if (span) {
    return (
      <Col span={span}>
        <FormControl {...rest} />
      </Col>
    );
  }

  return <FormControl {...rest} />;
};

export default DocumentExpenseWrapperFormControl;
