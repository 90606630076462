//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Params$AdminDocumentController_runRecognition } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminDocumentController_runRecognition = () =>
  useApiClientMutation<Params$AdminDocumentController_runRecognition, never>({
    method: 'AdminDocumentController_runRecognition',
  });
