import { PropsWithChildren, forwardRef } from 'react';

import * as S from './styled';

export type ScrollbarBaseProps = {
  height?: number | string;
  maxHeight?: number;
  withoutPadding?: boolean;
};

const ScrollbarBase = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ScrollbarBaseProps>
>(({ height, maxHeight, children, withoutPadding }, ref) => (
  <S.Container
    ref={ref}
    height={height}
    maxHeight={maxHeight}
    withoutPadding={withoutPadding}
  >
    {children}
  </S.Container>
));

export default ScrollbarBase;
