import { FC } from 'react';
import dayjs from 'dayjs';

import { type TimeRangePickerProps, DatePicker } from 'antd';

const RangePicker: FC<TimeRangePickerProps> = ({ ...rest }) => {
  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Period Today',
      value: [dayjs(), dayjs()],
    },
    {
      label: 'This Week',
      value: [dayjs().subtract(7, 'days'), dayjs()],
    },
    {
      label: 'This Month',
      value: [dayjs().startOf('month'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'days'), dayjs()],
    },
    {
      label: 'This Quarter',
      value: [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
    },
    {
      label: 'Last Quarter',
      value: [
        dayjs().subtract(1, 'quarter').startOf('quarter'),
        dayjs().subtract(1, 'quarter').endOf('quarter'),
      ],
    },
    {
      label: 'This Year',
      value: [dayjs().startOf('year'), dayjs()],
    },
    {
      label: 'Last Year',
      value: [
        dayjs().subtract(1, 'year').startOf('year'),
        dayjs().subtract(1, 'year').endOf('year'),
      ],
    },
  ];

  return (
    <DatePicker.RangePicker
      presets={rangePresets}
      size="large"
      format="DD.MM.YYYY"
      {...rest}
    />
  );
};

export default RangePicker;
