import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { isRouteErrorResponse } from 'react-router-dom';

export const ErrorFallback = ({
  error,
  componentStack,
  eventId,
}: {
  error: unknown;
  componentStack?: string;
  eventId?: string;
}) => {
  const message = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : error instanceof Error
      ? error.message
      : JSON.stringify(error);

  const stack = error instanceof Error ? error.stack : componentStack;

  useEffect(() => {
    captureException(error, { level: 'fatal' });
  }, [error]);

  console.error('Error:', eventId, error);

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Unexpected Application Error!</h2>
      <h3 style={{ fontStyle: 'italic' }}>{message}</h3>
      {stack && (
        <pre
          style={{
            padding: '0.5rem',
            backgroundColor: 'rgba(200,200,200, 0.5)',
          }}
        >
          {stack}
        </pre>
      )}
    </div>
  );
};
