import { FC } from 'react';

import { IconCheck, IconWarningTransparent, IconClock } from '@assets';

import * as S from './styled';

export type Status =
  | 'inProgress'
  | 'completed'
  | 'errorsFound'
  | 'onReview'
  | null;

export type StatusCircleProps = {
  status?: Status;
  active?: boolean;
  number?: number;
  size?: number;
};

const StatusCircle: FC<StatusCircleProps> = ({
  status,
  active,
  number,
  ...rest
}) => (
  <S.Wrapper>
    {status === 'completed' && (
      <S.Circle status={status} active={active} {...rest}>
        <IconCheck />
      </S.Circle>
    )}

    {status === 'errorsFound' && (
      <S.Circle status={status} {...rest}>
        <IconWarningTransparent />
      </S.Circle>
    )}

    {(status === 'inProgress' || status === 'onReview') && (
      <S.Circle status={status} active {...rest}>
        <IconClock />
      </S.Circle>
    )}

    {!status && (
      <S.Circle status={status} active={active} {...rest}>
        {number}
      </S.Circle>
    )}
  </S.Wrapper>
);

export default StatusCircle;
