import styled from '@emotion/styled';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 16px;

  .ui-btn {
    height: 46px;
    font-size: 16px;
    flex: 1;
  }
`;
