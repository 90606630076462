import { Flex } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { getDateFromNow } from '@utils';

import ChatFilesList from '../../files/ChatFilesList';
import ChatMessagesMenuActions from '../ChatMessagesMenuActions';
import * as S from './styled';

export type MessagePositionUnit = 'left' | 'right';

type ChatMessagesItemProps = {
  position?: MessagePositionUnit;
  status?: string;
  files?: Schemas.TaskFile[] | null;
  onRemove: () => void;
} & Schemas.ChatMessage;

const ChatMessagesItem: FC<ChatMessagesItemProps> = ({
  body,
  createdAt,
  deletedAt,
  status,
  files,
  position = 'left',
  onRemove,
}) => (
  <Flex justify={position === 'left' ? 'flex-start' : 'flex-end'}>
    <S.Message status={status!} position={position} gap={6} vertical>
      <S.Text align="flex-start" justify="space-between" gap={8}>
        {deletedAt ? (
          <S.DeletedBody>The message was deleted</S.DeletedBody>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: body }} />
        )}

        {!deletedAt && (
          <S.Actions position={position} className="chat-message-actions">
            <ChatMessagesMenuActions onRemove={onRemove} />
          </S.Actions>
        )}
      </S.Text>

      {!deletedAt && files && <ChatFilesList dataSource={files} light />}

      {!deletedAt && createdAt && status !== 'errorsFound' && (
        <S.Date>{getDateFromNow(createdAt)}</S.Date>
      )}
    </S.Message>
  </Flex>
);

export default ChatMessagesItem;
