import { AxiosError } from 'axios';

import apiClient from '@api-client';
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';

type ApiErrorData = {
  error: string;
  statusCode: number;
  message: string[];
};

type ApiError = {
  message: string[];
  data: ApiErrorData;
};

export type QueryOptions<R> = Omit<
  UseQueryOptions<R, AxiosError<ApiError, never>, R, QueryKey>,
  'queryKey' | 'queryFn'
>;

type ApiClientProps<P, R> = {
  // Method name as specified in api-client/generated/client.ts
  method: keyof typeof apiClient;
  // A set of parameters required for a specific API request.
  params?: P;
  queryKey?: QueryKey;
  config?: QueryOptions<R>;
};

export const useApiClient = <P = unknown, R = unknown>({
  queryKey,
  method,
  params,
  config = {},
}: ApiClientProps<P, R>) =>
  useQuery<R, AxiosError<ApiError, never>>({
    queryKey: queryKey || [method, params],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryFn: () => apiClient[method](params as any).then((res) => res as R),
    ...config,
  });
