import styled from '@emotion/styled';
import { Input } from 'antd';

export const Container = styled.div<{ isSelected: boolean }>`
  width: 100%;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.bgBrightBlue : theme.colors.bgGray};
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary : theme.colors.strokeGrey};
  border-radius: 12px;
  cursor: pointer;
  padding: 20px;
  margin-bottom: 12px;

  && svg {
    cursor: pointer;
  }
`;

export const InputDocumentName = styled(Input)`
  max-width: 504px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.blackSecondary};
  font-weight: 600;
  font-size: 16px;
  padding: 20px 0;
`;
