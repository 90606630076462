import styled from '@emotion/styled';

import { Typography } from 'antd';

const { Title } = Typography;

export const Inner = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const Heading = styled(Title)`
  && {
    text-align: center;
    margin-bottom: 28px;
  }
`;
