import styled from '@emotion/styled';
import { Button, Checkbox, Flex, Input } from 'antd';

export const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grayDark};
  margin-bottom: 4px;
`;

export const SelectContainer = styled(Flex)`
  width: 300px;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  .ui-category-filter-select {
    .ui-select-selector {
      .ui-select-selection-overflow {
        .ui-select-selection-search { 
          max-width: 240px;
          margin-left: 8px;
        }
      }
    }
  }
`;

export const DropdownRenderWrapper = styled(Flex)`
  padding: 12px;
`

export const SearchInput = styled(Input)`
  margin-bottom: 0;
  border-radius: 100px;
  padding: 8px 24px;
`

export const DropdownButton = styled(Button)`
  width: 100%;
`

export const DisplayedValue = styled.span`
  height: 41px;
  position: absolute;
  bottom: -11px;
  left: 11px;
  cursor: pointer;
`;

export const SingleValue = styled.div`
  margin-left: 6px;
  width: 100%;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ListCheckbox = styled(Checkbox)`
  && .ui-checkbox-inner {
    width: 24px;
    height: 24px;
    
    :after {
      width: 6px;
      height: 13px;
      top: 45%;
      inset-inline-start: 25%;
    }
  }
`

export const Option = styled(Flex)`
  padding: 8px 3px;
`