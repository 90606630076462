import { Flex } from 'antd';
import { FC } from 'react';

import { useAdminAccountsController_getAccounts } from '@api-client/generated/AdminAccountsController/getAccounts';
import { AccountsList } from '@entities';
import { useAccount } from '@hooks';

import * as S from './styled';

const Accounts: FC = () => {
  const { companyId } = useAccount();

  const { data: accounts } = useAdminAccountsController_getAccounts({
    params: {
      companyId: companyId!,
    },
  });

  return (
    <Flex gap={24} vertical>
      <S.Heading>Accounts</S.Heading>

      {accounts && <AccountsList accounts={accounts} />}
    </Flex>
  );
};

export default Accounts;
