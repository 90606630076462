import { Flex } from 'antd';
import { FC } from 'react';

import * as S from './styled';

type ChatSidebarItemProps = {
  name?: string;
  isSelected: boolean;
  date: string;
  countActive: number;
  onClick: () => void;
};

const ChatSidebarItem: FC<ChatSidebarItemProps> = ({
  name,
  date,
  countActive,
  isSelected,
  onClick,
}) => (
  <S.Item gap={8} isSelected={isSelected} onClick={onClick} vertical>
    <Flex align="center" justify="space-between" gap={8}>
      <S.Name>{name}</S.Name>
      {countActive ? <S.UnreadMarker type="chats" /> : null}
    </Flex>

    <Flex align="center" justify="space-between">
      {countActive ? (
        <S.Active>Active: {countActive}</S.Active>
      ) : (
        <S.Resolved>All resolved</S.Resolved>
      )}

      <S.Date>{date}</S.Date>
    </Flex>
  </S.Item>
);

export default ChatSidebarItem;
