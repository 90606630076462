import { Button, Flex, Table, type TableColumnType } from 'antd';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAdminAccountsController_getAccounts } from '@api-client/generated/AdminAccountsController/getAccounts';
import { useAdminTransactionController_findAll } from '@api-client/generated/AdminTransactionController/findAll';
import { useAdminTransactionController_toCSV } from '@api-client/generated/AdminTransactionController/toCSV';
import { Schemas } from '@api-client/generated/types';
import { IconCSV, IconClose } from '@assets';
import { Amount, FilterConstructor } from '@entities';
import { useAccount, useInfiniteScrollByPage } from '@hooks';
import { getSearchParams, paginationNormalizer } from '@utils';

import * as S from './styled';

type Transaction = Schemas.Transaction;
type FilterOptions = Record<string, unknown>;

type TransactionsContentProps = {
  title?: ReactNode;
  withSearchParams?: boolean;
  paginationCurrentPage?: number;
  externalCompanyId?: string;
  onPagination?: (metaData: Schemas.PaginationMetadata) => void;
  onFilter?: (options: FilterOptions) => void;
};

const columns: TableColumnType<Transaction>[] = [
  {
    key: 'amount',
    dataIndex: 'amount',
    title: 'Amount',
    align: 'right',
    width: 140,
    render: (amount, record) => (
      <Amount amount={amount} currencyCode={record.currencyCode} />
    ),
  },
  {
    key: 'contact',
    dataIndex: 'contact',
    title: 'Contact',
    width: 150,
    ellipsis: true,
    render: (contact) => contact?.name,
  },
  {
    key: 'details',
    dataIndex: 'details',
    title: 'Purpose of payment',
    ellipsis: true,
  },
  {
    key: 'account',
    dataIndex: 'account',
    title: 'Bank',
    width: 150,
    ellipsis: true,
    render: (account: Schemas.Account) =>
      account.connection?.bank?.name || account.accountName,
  },
  {
    key: 'bookingDate',
    dataIndex: 'bookingDate',
    title: 'Date',
    width: 110,
    render: (date) => format(date, 'dd.MM.yyyy'),
  },
];

const TransactionsContent: FC<TransactionsContentProps> = ({
  title,
  withSearchParams = true,
  paginationCurrentPage,
  externalCompanyId,
  onPagination,
  onFilter,
}) => {
  const { companyId: accountCompanyId } = useAccount();

  const companyId = externalCompanyId || accountCompanyId;

  const { search } = useLocation();
  const navigate = useNavigate();

  const searchParams = getSearchParams<{ [key: string]: string }>(search);

  const [filterOptions, setFilterOptions] =
    useState<FilterOptions>(searchParams);

  const [transactionsList, setTransactionsList] = useState<Transaction[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { currentPage: currentPageByScroll, resetScroll } =
    useInfiniteScrollByPage({ isLastPage });

  const { data, isPending: loading } = useAdminTransactionController_findAll({
    params: {
      companyId: companyId!,
      page: paginationCurrentPage || currentPageByScroll,
      ...filterOptions,
    },
  });
  useEffect(
    () => {
      if (data) {
        const { metadata, records } = data;

        if (paginationCurrentPage) {
          onPagination?.(metadata);
          onFilter?.(filterOptions);

          setTransactionsList(records);
        } else {
          const normalizeTransactions = paginationNormalizer<Transaction>({
            prevData: transactionsList,
            scrollPage: currentPageByScroll,
            setCurrentPage,
            currentPage,
            records,
            metadata,
          });

          if (metadata.currentPage === metadata.totalPages) {
            setIsLastPage(true);
          }

          if (normalizeTransactions) {
            setTransactionsList(normalizeTransactions);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const { data: downloadResponse = '' } = useAdminTransactionController_toCSV({
    params: {
      companyId: companyId!,
      page: currentPageByScroll,
      ...filterOptions,
    },
  });

  const { data: accounts } = useAdminAccountsController_getAccounts({
    params: {
      companyId: companyId!,
    },
  });

  useEffect(
    () => {
      resetScroll();
      setIsLastPage(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterOptions]
  );

  const handleParseAndDownload = () => {
    const parsedData = Papa.parse(downloadResponse, { header: true });
    const blob = new Blob([Papa.unparse(parsedData.data)], {
      type: 'text/csv;charset=utf-8;',
    });

    saveAs(blob, 'transactions.csv');
  };

  return (
    <Flex gap={24} vertical>
      {title}

      <FilterConstructor<Transaction>
        controls={{
          left: [
            {
              type: 'search',
              formName: 'term',
              params: {
                clearIcon: <IconClose width={16} height={16} />,
                allowClear: true,
              },
            },
          ],
          right: [
            {
              type: 'range-picker',
              formName: 'date',
            },
            {
              type: 'select',
              formName: 'accountId',
              params: {
                width: 180,
                placeholder: 'Bank',
                options: accounts?.map((account) => ({
                  label: account.name,
                  value: account.id,
                })),
              },
            },
            {
              type: 'custom-component',
              component: (
                <Button
                  icon={<IconCSV />}
                  type="secondary"
                  size="large"
                  onClick={handleParseAndDownload}
                />
              ),
            },
          ],
        }}
        onChange={setFilterOptions}
        withSearchParams={withSearchParams}
      />

      <S.TableContainer>
        <Table
          rowKey={({ id }) => id}
          onRow={({ id }) => ({
            onClick: () => navigate(id),
          })}
          dataSource={transactionsList}
          columns={columns}
          loading={loading}
          pagination={false}
          scroll={{ x: 720 }}
        />
      </S.TableContainer>
    </Flex>
  );
};

export default TransactionsContent;
