import { create } from 'zustand';

import { Schemas } from '@api-client/generated/types';

type Task = Schemas.Task;

type TasksState = {
  tasks: Task[];
  fetchTasks: (tasks: Task[]) => void;
  addTask: (task: Task) => void;
  updateTask: (updatedTask: Task) => void;
  resolveTask: (resolvedTask: Task) => void;
  updateUnseenCountOfTask: (id: string, type: 'inc' | 'dec') => void;
  readMessagesOfTask: (id: string) => void;
};

export const useTasksStore = create<TasksState>((set) => ({
  tasks: [],
  fetchTasks: (tasks) => set(() => ({ tasks })),
  addTask: (task) => set((state) => ({ tasks: [...state.tasks, task] })),
  updateTask: (updatedTask) =>
    set((state) => ({
      tasks: state.tasks.map((task) =>
        task.id === updatedTask.id ? updatedTask : task
      ),
    })),
  resolveTask: (resolvedTask) =>
    set((state) => ({
      tasks: state.tasks.map((task) =>
        task.id === resolvedTask.id ? resolvedTask : task
      ),
    })),
  updateUnseenCountOfTask: (id, type) =>
    set((state) => ({
      tasks: state.tasks.map((task) =>
        task.id === id
          ? {
              ...task,
              unseenMessagesCount:
                type === 'inc'
                  ? task.unseenMessagesCount + 1
                  : task.unseenMessagesCount - 1,
            }
          : task
      ),
    })),
  readMessagesOfTask: (id) =>
    set((state) => ({
      tasks: state.tasks.map((task) =>
        task.id === id ? { ...task, unseenMessagesCount: 0 } : task
      ),
    })),
}));
