import styled from '@emotion/styled';
import { colors } from '@theme';

type Status = 'inProgress' | 'completed' | 'errorsFound' | null;

type PersonProps = {
  selected: boolean;
  type?: 'sub';
};

type StepProps = {
  status: Status;
  selected: boolean;
};

const getBadgeColorByStatus = (status?: Status, selected?: boolean) => {
  if (status === 'completed' || status === 'inProgress' || selected) {
    return {
      bg: colors.primary,
      color: colors.white,
    };
  }

  if (status === 'errorsFound') {
    return {
      bg: colors.red,
      color: colors.white,
    };
  }

  return {
    bg: colors.strokeGrey,
    color: colors.blackSecondary,
  };
};

export const People = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PeopleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Person = styled.div<PersonProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.strokeGrey};
  box-shadow: 0 0 0 1px
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : 'transparent'};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 16px;
  margin-left: ${({ type }) => (type === 'sub' ? '40px' : 0)};

  & > svg {
    color: ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.blackSecondary};
  }
`;

export const PersonName = styled.div`
  font-weight: 600;
`;

export const PersonDetails = styled.div`
  color: ${({ theme }) => theme.colors.blackSecondary};
  font-size: 12px;
`;

export const Files = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 16px;
  padding: 24px;
  flex: 1;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Step = styled.div<Partial<StepProps>>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.strokeGrey};
  box-shadow: 0 0 0 1px
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : 'transparent'};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  gap: 8px;
`;

export const StepNumber = styled.div<Partial<StepProps>>`
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ selected, status }) =>
    getBadgeColorByStatus(status, selected).bg};
  color: ${({ selected, status }) =>
    getBadgeColorByStatus(status, selected).color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
`;

export const StepName = styled.div`
  font-weight: 600;
  font-size: 12px;

  .ui-typography {
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.blackSecondary};
  }
`;
