import { FC } from 'react';

import { Space, Flex } from 'antd';

import { IconClock, IconLock } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

export type DocumentStatus = Schemas.Document['adminStatus'];

type DocumentExpenseStatusProps = {
  status: DocumentStatus;
};

const DocumentExpenseStatus: FC<DocumentExpenseStatusProps> = ({ status }) => {
  const isProcessing = status === 'processing';
  const isBooked = status === 'processed';

  return (
    <S.Status status={status}>
      <Space split={<S.Divider />}>
        <S.StatusName status={status}>
          {isProcessing && (
            <Flex align="center" gap={6}>
              Processing <IconClock />
            </Flex>
          )}

          {isBooked && (
            <Flex align="center" gap={6}>
              Booked <IconLock />
            </Flex>
          )}
        </S.StatusName>

        {isProcessing && (
          <S.Details>
            You can still make changes within the next 5 minutes.
          </S.Details>
        )}

        {isBooked && (
          <>
            <S.Details>Book date: TBD</S.Details>
            <S.Details>Time to book: TBD</S.Details>
          </>
        )}
      </Space>
    </S.Status>
  );
};

export default DocumentExpenseStatus;
