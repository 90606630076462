import { Button, Flex, Form, Modal, Select } from 'antd';
import { FC, useState } from 'react';

import { useAdminCompanyFileController_updateOneById } from '@api-client/generated/AdminCompanyFileController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { IconDownload } from '@assets';
import { useAccount } from '@hooks';

import * as S from './styled';

type PersonFilesModalProps = {
  open: boolean;
  file: Schemas.CompanyFile;
  title: string;
  onCancel: () => void;
};

const PersonFileModal: FC<PersonFilesModalProps> = ({
  open,
  file,
  title,
  onCancel,
}) => {
  const { companyId } = useAccount();
  const { mutate: updateCompanyFileById } =
    useAdminCompanyFileController_updateOneById();
  const [isRequestChangesFormVisible, setIsRequestChangesFormVisible] =
    useState(false);

  const isTypeImage = file.mimeType?.includes('image');

  const isTypePDF = file.mimeType?.includes('pdf');

  const approveFile = () => {
    updateCompanyFileById(
      {
        parameter: {
          companyId: companyId!,
          id: file.id,
        },
        requestBody: {
          hasError: false,
          isApproved: true,
          errorCode: '',
        },
      },
      {
        onSuccess: (response) => {
          file.isApproved = true;
          file.hasError = false;
          file.events = response.events;
          onCancel();
        },
      }
    );
  };

  const showForm = () => {
    setIsRequestChangesFormVisible(true);
  };

  const hideForm = (event: React.MouseEvent) => {
    event.preventDefault();

    setIsRequestChangesFormVisible(false);
  };

  const onFinish = (values: Schemas.CompanyFile) => {
    updateCompanyFileById(
      {
        parameter: {
          companyId: companyId!,
          id: file.id,
        },
        requestBody: {
          hasError: true,
          isApproved: false,
          errorCode: values.errorCode,
        },
      },
      {
        onSuccess: (response) => {
          file.hasError = true;
          file.isApproved = false;
          file.errorCode = values.errorCode;
          file.events = response.events;
          onCancel();
        },
      }
    );
  };

  return (
    <Modal
      width={1050}
      title={title}
      open={open}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <Flex vertical gap={20}>
        <S.PreviewContent>
          {isTypeImage && <img src={file.url} />}

          {isTypePDF && <iframe src={file.url} />}
        </S.PreviewContent>
        <a href={file.url} target="_blank">
          Download <IconDownload />
        </a>

        {!isRequestChangesFormVisible && (
          <Flex gap={20}>
            <Button type="primary" onClick={approveFile}>
              Approve
            </Button>
            <Button type="link" onClick={showForm}>
              Request changes
            </Button>
          </Flex>
        )}

        {isRequestChangesFormVisible && (
          <Flex gap={20} vertical>
            <Form layout="vertical" onFinish={onFinish} initialValues={file}>
              <Form.Item label="Reason" name="errorCode">
                <Select
                  variant="outlined"
                  placeholder="Select type of issue"
                  options={[
                    {
                      label: 'Wrong file',
                      value: 'wrong_file',
                    },
                    {
                      label: 'Unreadable',
                      value: 'not_readable',
                    },
                    {
                      label: 'Not fully uploaded',
                      value: 'not_full',
                    },
                    {
                      label: 'No date and/or signature',
                      value: 'no_date_signature',
                    },
                    {
                      label: 'Document is too old',
                      value: 'too_old',
                    },
                    {
                      label: 'Document expired',
                      value: 'expired',
                    },
                  ]}
                  size="large"
                />
              </Form.Item>
              <Button htmlType="submit" type="primary">
                Request changes
              </Button>
              <Button type="link" onClick={(event) => hideForm(event)}>
                Cancel
              </Button>
            </Form>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};

export default PersonFileModal;
