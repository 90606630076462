import { FC } from 'react';
import { Tooltip } from 'antd';

import * as S from './styled';

type ExpandableListItemProps = {
  text: string;
  maxLength?: number;
};

const ExpandableListItem: FC<ExpandableListItemProps> = ({ text, maxLength = 15 }) => {
  const isLongText = text.length > maxLength;
  const displayText = isLongText ? `${text.slice(0, maxLength)}...` : text;

  return (
    <Tooltip title={isLongText ? text : ''}>
      <S.Label>{displayText}</S.Label>
    </Tooltip>
  );
}

export default ExpandableListItem;
