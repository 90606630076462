import { FC, PropsWithChildren, ReactNode } from 'react';

import { GoBack } from '@entities';

import { Space } from 'antd';

import * as S from './styled';

type DetailsHeaderProps = {
  goBackText?: ReactNode;
};

const DetailsHeader: FC<PropsWithChildren<DetailsHeaderProps>> = ({
  children,
  goBackText,
}) => (
  <S.Header>
    <Space split={<S.Divider />}>
      <GoBack text={goBackText} />

      {children}
    </Space>
  </S.Header>
);

export default DetailsHeader;
