import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Wrapper = styled(Flex)`
  height: 100%;
`

export const Title = styled.span`
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
`

export const ColumnTitle = styled.span`
  font-size: 12px;
  line-height: 19px;
  color: ${({theme}) => theme.colors.textBlue400};
`

export const Name = styled.span`
  font-weight: 600;
  line-height: 20px;
`

export const Email = styled.span`
  font-size: 12px;
  line-height: 20px;
`

export const NameWrapper = styled(Flex)`
  width: 300px;
`

export const RoleWrapper = styled.div`
  width: 132px;
`

export const CompaniesWrapper = styled.div`
  width: 300px;
`

