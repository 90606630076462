//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Response$AdminUserController_getUsers$Status$200,
  ResponseContentType$AdminUserController_getUsers,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminUserController_getUsers = (options?: {
  config?: QueryOptions<
    Response$AdminUserController_getUsers$Status$200[ResponseContentType$AdminUserController_getUsers]
  >;
}) =>
  useApiClient<
    never,
    Response$AdminUserController_getUsers$Status$200[ResponseContentType$AdminUserController_getUsers]
  >({
    method: 'AdminUserController_getUsers',
    config: options?.config,
  });
