//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Params$AdminTransactionController_toCSV } from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminTransactionController_toCSV = (options: {
  params: Params$AdminTransactionController_toCSV['parameter'];
  config?: QueryOptions<never>;
}) =>
  useApiClient<Params$AdminTransactionController_toCSV, never>({
    method: 'AdminTransactionController_toCSV',
    params: { parameter: options.params },
    config: options.config,
  });
