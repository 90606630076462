import type { TableProps } from 'antd';
import { Table, message } from 'antd';
import { FC, useEffect, useState } from 'react';

import { useAdminCompanyController_findAll } from '@api-client/generated/AdminCompanyController/findAll';
import { useAdminUserController_getUsers } from '@api-client/generated/AdminUserController/getUsers';
import { useAdminUserController_updateCurrentUser } from '@api-client/generated/AdminUserController/updateCurrentUser';
import { Schemas } from '@api-client/generated/types';
import { Card, Select } from '@components';
import { CompaniesSelect, ExpandableList } from '@entities';

import * as S from './styled';

const roles = [
  { label: 'Admin', value: 'admin' },
  { label: 'Accountant', value: 'accountant' },
  { label: 'KYC', value: 'kyc' },
  { label: 'Support/Sales', value: 'support' },
];

const Settings: FC = () => {
  const { data: users, isPending: usersLoading } =
    useAdminUserController_getUsers();
  const { data: companies } = useAdminCompanyController_findAll({
    params: {},
  });

  const [usersList, setUsersList] = useState<Schemas.User[] | undefined>([]);

  useEffect(
    () => {
      if (!usersLoading) {
        setUsersList(users);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersLoading]
  );

  const { mutate: updateUser, isPending: updateUserLoading } =
    useAdminUserController_updateCurrentUser();

  const handleLinkCompanies = <K extends 'adminCompanyIds' | 'adminRole'>(
    key: K,
    id: string,
    value: Schemas.AdminUpdateUserDto[K]
  ) => {
    updateUser(
      {
        parameter: {
          id: id,
        },
        // @ts-expect-error-next-line
        requestBody: { [key]: value },
      },
      {
        onSuccess: (response) => {
          setUsersList((prev) =>
            prev?.map((user) => (user.id === id ? response : user))
          );
          message.success(`User was updated`);
        },
      }
    );
  };

  if (!usersList?.length) return;

  const columns: TableProps<Schemas.User>['columns'] = [
    {
      title: <S.ColumnTitle>Name</S.ColumnTitle>,
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => (
        <S.NameWrapper vertical>
          <S.Name>{text}</S.Name>
          <S.Email>{record.email}</S.Email>
        </S.NameWrapper>
      ),
    },
    {
      title: <S.ColumnTitle>Role</S.ColumnTitle>,
      dataIndex: 'roles',
      key: 'roles',
      render: (_, record) => (
        <S.RoleWrapper>
          <Select
            size="large"
            defaultValue={record.adminRole}
            options={roles}
            onChange={(value) =>
              handleLinkCompanies('adminRole', record.id, value)
            }
          />
        </S.RoleWrapper>
      ),
    },
    {
      title: <S.ColumnTitle>Companies</S.ColumnTitle>,
      dataIndex: 'companies',
      key: 'companies',
      render: (company, record) => (
        <S.CompaniesWrapper>
          <CompaniesSelect
            placeholder="Select companies"
            value={company.map((item: Schemas.Company) => ({
              label: item.name,
              value: item.id,
            }))}
            options={companies?.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            onChange={(value) =>
              handleLinkCompanies('adminCompanyIds', record.id, value)
            }
            loading={usersLoading || updateUserLoading}
          />
        </S.CompaniesWrapper>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <ExpandableList
          identifier={record.email}
          list={record.companies?.map((item: Schemas.Company) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      ),
    },
  ];

  return (
    <S.Wrapper gap={24} vertical>
      <S.Title>Members access</S.Title>
      <Card>
        <Table
          columns={columns}
          dataSource={usersList}
          pagination={false}
          loading={usersLoading}
          rowKey={(record) => record.id}
        />
      </Card>
    </S.Wrapper>
  );
};

export default Settings;
