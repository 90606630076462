import { Button } from 'antd';
import { FC, KeyboardEvent } from 'react';

import { IconAttach } from '@assets';

import * as S from './styled';

type ChatPanelFormSendProps = {
  selectedId?: string;
  value: string;
  disabled: boolean;
  onAttach?: () => void;
  onChange: (value: string) => void;
  onSend: () => void;
};

const ChatPanelFormSend: FC<ChatPanelFormSendProps> = ({
  selectedId,
  value,
  disabled,
  onAttach,
  onChange,
  onSend,
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <S.Container align="center" gap={16}>
      {onAttach && (
        <S.AttachFile onClick={onAttach} disabled={!selectedId}>
          <IconAttach />
        </S.AttachFile>
      )}

      <S.Field
        variant="borderless"
        size="large"
        value={value}
        placeholder="Write a message…"
        disabled={!selectedId}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        autoSize={{ minRows: 1, maxRows: 5 }}
      />

      <Button type="primary" onClick={onSend} size="large" disabled={disabled}>
        Send
      </Button>
    </S.Container>
  );
};

export default ChatPanelFormSend;
