import { Button, Flex, Form, Input, InputRef, Modal } from 'antd';
import { useEffect, useRef } from 'react';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type ProcessTemplateResponseDto = Schemas.ProcessTemplateResponseDto;

type ProcessesTemplatesModalProps = {
  isOpen: boolean;
  template: ProcessTemplateResponseDto | null;
  onClose: () => void;
  onSubmit: (params: Schemas.ProcessTemplateRequestDto) => void;
};

const ProcessesTemplatesModal = ({
  isOpen,
  template,
  onClose,
  onSubmit,
}: ProcessesTemplatesModalProps) => {
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm<Schemas.ProcessTemplateRequestDto>();

  useEffect(() => {
    if (inputRef.current && isOpen) {
      setTimeout(() => {
        inputRef.current!.focus({ cursor: 'end' });
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (template) {
      form.setFieldsValue(template);
    }
  }, [form, template]);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleFinish = async (values: Schemas.ProcessTemplateRequestDto) => {
    await form.validateFields();
    onSubmit(values);
    handleClose();
  };

  return (
    <Modal
      title="Add new template"
      open={isOpen}
      width={603}
      onCancel={handleClose}
      footer={
        <Flex justify="flex-end" gap={12}>
          <Button type="secondary" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            onClick={() => form.submit()}
          >
            {template ? 'Update' : 'Create'}
          </Button>
        </Flex>
      }
      forceRender
      destroyOnClose
      centered
    >
      <S.FormWrapper>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            name: template?.name,
            description: template?.description,
          }}
          onFinish={handleFinish}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Template name" size="large" ref={inputRef} />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Form>
      </S.FormWrapper>
    </Modal>
  );
};

export default ProcessesTemplatesModal;
