import { Flex } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

export const Modal = styled(Flex)`
  position: absolute;
  padding: 12px;
  width: 400px;
  height: 374px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgWhite};
  box-shadow: 0 4px 21px 0 ${({ theme }) => rgba(theme.colors.black, 0.14)};
  z-index: 1;
`;

export const Body = styled(Flex)`
  min-height: 0;
  overflow: hidden;
`;

export const Steps = styled(Flex)`
  flex: 1 1 auto;
  overflow-y: auto;
`;

export const Step = styled(Flex)`
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgGrey};
  }
`;
