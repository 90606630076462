import styled from '@emotion/styled';

import { Divider as DividerAntd } from 'antd';

export const Divider = styled(DividerAntd)`
  && {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.bgDarkGrey};
  }
`;
