import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { Flex } from 'antd';

export const Empty = styled(Flex)`
  width: 100%;
  height: 712px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  padding: 20px 30px 30px 30px;
`;
