import { FC, ReactNode, PropsWithChildren } from 'react';

import { FileViewer } from '@entities';

import { IconEyeOff } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type File = Schemas.Document;
type TaskFile = Schemas.TaskFile;
type CompanyFile = Schemas.CompanyFile;
type PayslipDocument = Schemas.PayslipDocument;

export type FilePreviewSize = {
  width?: string;
  height?: string;
};

type FilePreviewProps = {
  title?: string;
  headerContent?: ReactNode;
  file: File | TaskFile | CompanyFile | PayslipDocument | null;
} & FilePreviewSize;

const FilePreviewWrapper: FC<
  PropsWithChildren<Omit<FilePreviewProps, 'file'>>
> = ({ title, headerContent, children }) => (
  <S.Preview>
    {title && <S.PreviewTitle>{title}</S.PreviewTitle>}
    {headerContent}
    {children}
  </S.Preview>
);

const FilePreview: FC<FilePreviewProps> = ({
  title,
  file,
  headerContent,
  width,
  height,
}) => {
  const isTypeImage = file?.mimeType.includes('image');
  const isTypePDF = file?.mimeType.includes('pdf');

  if (!file) {
    return (
      <FilePreviewWrapper title={title} headerContent={headerContent}>
        <S.PreviewContent width={width} height={height} />
      </FilePreviewWrapper>
    );
  }

  return (
    <FilePreviewWrapper title={title} headerContent={headerContent}>
      <S.PreviewContent width={width} height={height}>
        {isTypePDF && (
          <FileViewer
            selectedFile={file}
            width={width || '437px'}
            height={height || '622px'}
          />
        )}

        {isTypeImage && <img src={file?.url} />}

        {!isTypePDF && !isTypeImage && (
          <S.Empty>
            <S.EmptyIcon>
              <IconEyeOff width={32} height={32} />
            </S.EmptyIcon>

            <S.EmptyTitle>Preview can't be displayed</S.EmptyTitle>

            <S.EmptyDescription>
              Only PDF files is available for preview
            </S.EmptyDescription>
          </S.Empty>
        )}
      </S.PreviewContent>
    </FilePreviewWrapper>
  );
};

export default FilePreview;
