import { FC } from 'react';

import { AccountItem } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type AccountsListProps = {
  accounts: Schemas.Account[];
};

const AccountsList: FC<AccountsListProps> = ({ accounts }) => (
  <S.Grid>
    {accounts.map((account) => (
      <AccountItem
        key={account.id}
        logo={''}
        // @ts-expect-error-next-line
        account={account}
      />
    ))}
  </S.Grid>
);

export default AccountsList;
