import styled from '@emotion/styled';

type ControlProps = {
  selected?: boolean;
};

export const Control = styled.div<ControlProps>`
  width: 100%;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.bgBrightBlue : theme.colors.bgGrey};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.strokeGrey};
  border-radius: 12px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
  padding: 16px 20px;
  position: relative;
`;

export const Title = styled.div<ControlProps>`
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 600;
  font-size: 16px;
  margin: 0;
`;

export const ControlIcon = styled.div<ControlProps>`
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.textDisabled};
  position: relative;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.primary : 'transparent'};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
