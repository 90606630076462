import { Button, Flex, Space, Upload, UploadProps } from 'antd';
import { FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IconDownload, IconRefresh } from '@assets';
import { Loader } from '@components';
import { colors } from '@theme';

import * as S from './styled';



type UploadFileProps = {
  id?: string;
  name?: string;
  url?: string;
  loading?: boolean;
  setFileAttachment?: (file: File | null) => void;
  additionalIcon?: ReactNode;
  isSelected?: boolean;
  onSelect?: () => void;
  isFileChanging?: boolean;
  onFileNameSave?: (name: string) => void;
};

const UploadFileItem: FC<UploadFileProps> = ({
  url,
  name,
  loading,
  setFileAttachment,
  additionalIcon,
  isSelected = false,
  onSelect,
  isFileChanging,
  onFileNameSave,
}) => {
  const { pathname } = useLocation();

  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState(name);

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    onChange(info) {
      setFile(info.file.originFileObj as unknown as File);
      setFileAttachment?.(info.file.originFileObj as unknown as File);
    },
    showUploadList: false,
  };

  const isLegal = pathname.includes('legal');

  return (
    <Flex vertical>
      {!url && !file && (
        <S.UploadFile {...props}>
          <S.UploadContainer align="center" justify="space-between">
            <S.Label>Drop file here to upload</S.Label>
            <Button size="small">Select file</Button>
          </S.UploadContainer>
        </S.UploadFile>
      )}

      {loading && <Loader noPadding />}

      {url && (
        <S.Container isSelected={isSelected} onClick={onSelect}>
          <Flex justify="space-between" gap={20}>
            {isFileChanging ? (
              <S.FilenameInput
                size="large"
                value={fileName}
                onPressEnter={() => onFileNameSave?.(fileName!)}
                onChange={(e) => setFileName(e.target.value)}
                onBlur={() => onFileNameSave?.(fileName!)}
              />
            ) : (
              <Space size={6} align="center">
                <S.Label>{isLegal ? name : `Attached: ${name}`}</S.Label>
              </Space>
            )}

            <Flex align="center" gap={10}>
              <a href={url} download={name} target="_blank" rel="noreferrer">
                <IconDownload color={colors.blackSecondary} />
              </a>

              {additionalIcon || (
                <Upload {...props}>
                  <S.PreviewLink>
                    <IconRefresh color={colors.blackSecondary} />
                  </S.PreviewLink>
                </Upload>
              )}
            </Flex>
          </Flex>
        </S.Container>
      )}
    </Flex>
  );
};

export default UploadFileItem;
