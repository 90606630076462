import { FC, useState } from 'react';
import { Button, Modal } from 'antd';

import { Select } from '@components';

import { reportErrors } from '@constants';

import * as S from './styled';

type SendRequestModalProps = {
  open: boolean;
  onRequest?: (code?: string) => void;
  onCancel: () => void;
};

const SendRequestModal: FC<SendRequestModalProps> = ({
  open,
  onRequest,
  onCancel,
}) => {
  const [errorCode, setErrorCode] = useState();

  const handleRequest = () => {
    onRequest?.(errorCode);
    setErrorCode(undefined);
  };

  const handleCancel = () => {
    onCancel();
    setErrorCode(undefined);
  };

  return (
    <Modal
      width={504}
      title="Write a request to send the client"
      open={open}
      onCancel={handleCancel}
      footer={null}
      centered
    >
      <Select
        variant="outlined"
        placeholder="Select a request"
        value={errorCode}
        onChange={(value) => setErrorCode(value)}
        options={reportErrors}
        size="large"
      />
      <S.Footer>
        <Button size="large" onClick={handleCancel} block>
          Cancel
        </Button>

        <Button type="primary" size="large" onClick={handleRequest} block>
          Send
        </Button>
      </S.Footer>
    </Modal>
  );
};

export default SendRequestModal;
