import { Button, Flex, Switch, Typography, message } from 'antd';
import { CSSProperties, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDebounce } from 'use-debounce';

import { useAdminProcessTemplateController_publish } from '@api-client/generated/AdminProcessTemplateController/publish';
import { useAdminProcessTemplateController_unpublish } from '@api-client/generated/AdminProcessTemplateController/unpublish';
import { useAdminStepController_create } from '@api-client/generated/AdminStepController/create';
import { useAdminStepController_findAll } from '@api-client/generated/AdminStepController/findAll';
import { useAdminStepController_update } from '@api-client/generated/AdminStepController/update';
import { Schemas } from '@api-client/generated/types';
import { IconEditUnderline, IconPlus, IconTrash } from '@assets';
import { Loader } from '@components';
import { DEFAULT_TIMEOUT_FOR_DEBOUNCE } from '@constants';
import ProcessesTemplatesAddStepModal from '@entities/processes/ProcessesTemplatesAddStepModal';
import ProcessesTemplatesDeleteTemplateModal from '@entities/processes/ProcessesTemplatesDeleteTemplateModal';
import ProcessesTemplatesEditForm from '@entities/processes/ProcessesTemplatesEditForm';
import ProcessesTemplatesEditSteps from '@entities/processes/ProcessesTemplatesEditSteps';

import * as S from './styled';

type StepRequestDto = Schemas.StepRequestDto;
type OrderedStepResponseDto = Schemas.OrderedStepResponseDto;
type ProcessTemplateResponseDto = Schemas.ProcessTemplateResponseDto;

const { Text, Title } = Typography;

type ProcessesTemplatesEditProps = {
  isLoading: boolean;
  template?: ProcessTemplateResponseDto;
  steps: Partial<OrderedStepResponseDto>[];
  currentStep: number;
  onSelectStep: (stepNumber: number) => void;
  onAddStep: (step: Partial<OrderedStepResponseDto>) => void;
  onDeleteStep: (step: Partial<OrderedStepResponseDto>) => void;
  onEditClick: () => void;
  onChangeSteps: (steps: Partial<OrderedStepResponseDto>[]) => void;
  onUpdate: () => void;
  onDelete: () => void;
};

const ProcessesTemplatesEdit = (props: ProcessesTemplatesEditProps) => {
  const headerButtonRef = useRef<HTMLButtonElement>(null);
  const emptyScreenRef = useRef<HTMLButtonElement>(null);

  const [deletingTemplate, setDeletingTemplate] =
    useState<ProcessTemplateResponseDto | null>(null);

  const [query, setQuery] = useState('');
  const [style, setStyle] = useState<CSSProperties>();

  const [term] = useDebounce(query, DEFAULT_TIMEOUT_FOR_DEBOUNCE);

  const { data: allSteps } = useAdminStepController_findAll({
    params: {
      term,
    },
  });

  const { mutateAsync: createStep } = useAdminStepController_create();
  const { mutateAsync: updateStep } = useAdminStepController_update();
  const { mutateAsync: publishTemplate } =
    useAdminProcessTemplateController_publish();
  const { mutateAsync: unpublishTemplate } =
    useAdminProcessTemplateController_unpublish();

  const handleUpdateStep = async (values: StepRequestDto & { id?: string }) => {
    if (values.id) {
      await updateStep({
        parameter: {
          id: values.id,
        },
        requestBody: values,
      });
      message.success('Step updated');
      props.onUpdate();
    } else {
      const newStep = await createStep({
        requestBody: values,
      });

      props.onChangeSteps(
        props.steps.map((step, index) => {
          if (index === props.currentStep) {
            return newStep;
          }
          return step;
        })
      );
    }
  };

  const handleAddStepClick = (position: 'header' | 'emptyScreen') => () => {
    if (!allSteps?.length && query === '') {
      props.onAddStep({});
    } else {
      switch (position) {
        case 'header': {
          const rect = headerButtonRef.current?.getBoundingClientRect();
          if (rect) {
            setStyle({
              top: rect.bottom,
              left: rect.left,
              transform: 'translateX(-100%)',
            });
          }
          break;
        }
        case 'emptyScreen': {
          const rect = emptyScreenRef.current?.getBoundingClientRect();
          if (rect) {
            setStyle({
              top: rect.top + rect.height / 2,
              left: rect.left + rect.width / 2,
              transform: 'translate(-50%, -50%)',
            });
          }
          break;
        }
      }
    }
  };

  const handleTogglePublishClick = async (value: boolean) => {
    if (value) {
      await publishTemplate({
        parameter: {
          id: props.template!.id,
        },
      });
    } else {
      await unpublishTemplate({
        parameter: {
          id: props.template!.id,
        },
      });
    }

    props.onUpdate();
  };

  const handleDeleteClick = () => {
    if (props.template) {
      setDeletingTemplate(props.template);
    }
  };

  if (props.isLoading) {
    return (
      <S.Root flex="1 0 auto" ref={emptyScreenRef}>
        <S.EmptyBody
          gap={24}
          flex="1 0 auto"
          align="center"
          justify="center"
          vertical
        >
          <Loader />
        </S.EmptyBody>
      </S.Root>
    );
  }

  if (!props.template) {
    return (
      <S.Root flex="1 0 auto" ref={emptyScreenRef}>
        <S.EmptyBody
          gap={24}
          flex="1 0 auto"
          align="center"
          justify="center"
          vertical
        >
          <Text>Select a template first</Text>
        </S.EmptyBody>
      </S.Root>
    );
  }

  return (
    <>
      {props.steps.length ? (
        <S.Root flex="1 1 100%" vertical>
          <S.Header justify="space-between">
            <Flex align="center" gap={20}>
              <S.Title>{props.template?.name}</S.Title>
              <S.Divider type="vertical" />
              <Flex gap={12}>
                <Switch
                  value={props.template?.isPublished}
                  onChange={handleTogglePublishClick}
                />
                <Text>Mark as published</Text>
              </Flex>
            </Flex>
            <Flex gap={20}>
              <Button
                ref={headerButtonRef}
                icon={<IconPlus />}
                type="text"
                onClick={handleAddStepClick('header')}
              />
              <Button
                type="text"
                icon={<IconEditUnderline />}
                onClick={() => props.onEditClick()}
              />
              <Button
                type="text"
                icon={<IconTrash />}
                onClick={handleDeleteClick}
              />
            </Flex>
          </S.Header>
          <S.Body gap={32} flex="1 1 auto">
            {props.template && (
              <>
                <DndProvider backend={HTML5Backend}>
                  <ProcessesTemplatesEditSteps
                    steps={props.steps}
                    currentStep={props.currentStep}
                    onChange={props.onChangeSteps}
                    onStepClick={props.onSelectStep}
                    onDeleteStep={props.onDeleteStep}
                  />
                </DndProvider>
                {props.steps?.[props.currentStep] && (
                  <ProcessesTemplatesEditForm
                    template={props.template}
                    step={props.steps[props.currentStep]}
                    onUpdateStep={handleUpdateStep}
                  />
                )}
              </>
            )}
          </S.Body>
        </S.Root>
      ) : (
        <S.Root flex="1 0 auto" ref={emptyScreenRef}>
          <S.EmptyBody
            gap={24}
            flex="1 0 auto"
            align="center"
            justify="center"
            vertical
          >
            <Flex gap={6} vertical>
              <Title level={4}>There are no steps yet</Title>
              <Text>
                Add the steps to this template to publish it and initiate it as
                a new process
              </Text>
            </Flex>
            <Button
              type="primary"
              size="large"
              icon={<IconPlus />}
              onClick={handleAddStepClick('emptyScreen')}
            >
              Add step
            </Button>
          </S.EmptyBody>
        </S.Root>
      )}

      {deletingTemplate && (
        <ProcessesTemplatesDeleteTemplateModal
          template={deletingTemplate}
          isOpen={!!deletingTemplate}
          onClose={() => setDeletingTemplate(null)}
          onDelete={props.onDelete}
        />
      )}

      {style && props.template && (
        <ProcessesTemplatesAddStepModal
          query={query}
          onQueryChange={setQuery}
          templateId={props.template.id!}
          steps={allSteps || []}
          style={style}
          onClose={() => {
            setStyle(undefined);
          }}
          onCreateStep={() => {
            props.onAddStep({});
            setStyle(undefined);
          }}
          onAddExistingStep={(step) => {
            props.onAddStep(step);
            setStyle(undefined);
          }}
        />
      )}
    </>
  );
};

export default ProcessesTemplatesEdit;
