import styled from '@emotion/styled';
import { Flex, Typography } from 'antd';

const { Text } = Typography;

export const Root = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>`
  width: 100%;
  border: 2px solid ${({ theme, isSelected }) => isSelected ? theme.colors.primary : theme.colors.strokeGrey};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.bgWhite};
  cursor: pointer;
  padding: 12px 16px 16px;
`;

export const Title = styled(Text)`
  font-size: 14px;
  font-weight: 600;
`;

export const Status = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.success};
`;

export const Description = styled(Text)`
  font-size: 12px;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EstimatedTime = styled(Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text200};
`;
