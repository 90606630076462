import styled from '@emotion/styled';

export const PreviewContent = styled.div`
  margin-top: 16px;

  img {
    width: 1000px;
    border-radius: 12px;
  }

  iframe {
    width: 100%;
    height: 500px;
    border-radius: 12px;
    border: 0;
  }
`;