import { accessMapping } from '@constants';

type MenuItem = {
  name: string;
  to: string;
  icon: JSX.Element;
};

export const filteredMenu = (
  menuList: MenuItem[],
  adminRole?: string,
): MenuItem[] => {
  if (!adminRole) {
    return [];
  }

  const allowedPaths = accessMapping[adminRole as 'kyc' | 'admin' | 'accountant'];

  return menuList.filter((item) =>
    allowedPaths.some((allowedPath) => item.to.includes(allowedPath))
  );
};
