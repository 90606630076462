import { FC, ReactNode, useState } from 'react';

import {
  Select as SelectAntd,
  type SelectProps as SelectPropsAntd,
} from 'antd';

import { IconArrowDown, IconArrowUp } from '@assets';

import * as S from './styled';

export type SelectVisualType = 'fill-rounded';

export type SelectProps = {
  width?: number;
  withoutContainer?: boolean;
  visualType?: SelectVisualType;
  placeholder?: string | ReactNode;
} & SelectPropsAntd;

const Select: FC<SelectProps> = ({
  width,
  withoutContainer,
  visualType,
  ...rest
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const SelectComponent = (
    <SelectAntd
      style={{ width: width ? `${width}px` : '100%' }}
      suffixIcon={isDropdownVisible ? <IconArrowUp /> : <IconArrowDown />}
      onDropdownVisibleChange={setIsDropdownVisible}
      {...rest}
    />
  );

  if (withoutContainer) {
    return SelectComponent;
  }

  return <S.Container visualType={visualType}>{SelectComponent}</S.Container>;
};

export default Select;
