import { FC, useState } from 'react';
import { Col, Row } from 'antd';

import { ExpandableListItem } from '@entities';

import * as S from './styled';

type ExpandableListProps = {
  list: { value: string; label: string }[] | [];
  identifier: string;
};

const ExpandableList: FC<ExpandableListProps> = ({ list, identifier }) => {
  const [showAll, setShowAll] = useState(false);

  const itemsToShow = showAll ? list : list.slice(0, 20);

  return (
    <S.Wrapper>
      <Row gutter={[16, 4]}>
        {itemsToShow?.map((item) => (
          <Col key={item.value + identifier} span={8}>
            <ExpandableListItem text={item.label} />
          </Col>
        ))}
        {!showAll && list.length > 20 && (
          <Col span={8}>
            <S.ShowAll onClick={() => setShowAll(true)}>Show all</S.ShowAll>
          </Col>
        )}
      </Row>
    </S.Wrapper>
  );
};

export default ExpandableList;
