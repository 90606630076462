//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminStepController_update,
  Response$AdminStepController_update$Status$200,
  ResponseContentType$AdminStepController_update,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminStepController_update = () =>
  useApiClientMutation<
    Params$AdminStepController_update,
    Response$AdminStepController_update$Status$200[ResponseContentType$AdminStepController_update]
  >({ method: 'AdminStepController_update' });
