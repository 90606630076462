//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Params$AdminPayslipDocumentController_create } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminPayslipDocumentController_create = () =>
  useApiClientMutation<Params$AdminPayslipDocumentController_create, never>({
    method: 'AdminPayslipDocumentController_create',
  });
