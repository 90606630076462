import { FC } from 'react';

import { Row, Col } from 'antd';

import { TaxCard, TaxesEmpty } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type TaxesListProps = {
  label: string;
  taxes: Schemas.Tax[];
  period?: string;
  year?: string;
};

const TaxesList: FC<TaxesListProps> = ({ label, period, year, taxes = [] }) => (
  <S.Container>
    <S.Label>{label}</S.Label>

    <S.List>
      {taxes.length ? (
        <Row gutter={[20, 20]}>
          {taxes.map((tax) => (
            <Col span={6} key={tax.id}>
              <TaxCard details={tax} />
            </Col>
          ))}
        </Row>
      ) : (
        <TaxesEmpty period={period} year={year} />
      )}
    </S.List>
  </S.Container>
);

export default TaxesList;
