import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)<FlexProps>`
  height: 40px;
  padding: 0 16px;
`;

export const Title = styled.div`
  font-weight: 600;
`;

export const Action = styled.a`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
  font-weight: 500;
`;

export const AssigneeContainer = styled(Flex)<FlexProps>`
  width: 220px;
`;

export const AssigneeControl = styled(Flex)<FlexProps>`
  padding: 10px 8px;
`;
