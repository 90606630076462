import { FC } from 'react';

import { Flex, Typography } from 'antd';

import { DocumentsContent } from '@entities';

const { Title } = Typography;

const Documents: FC = () => (
  <Flex gap={24} vertical>
    <DocumentsContent title={<Title>Documents</Title>} />
  </Flex>
);

export default Documents;
