import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { NotFoundImage } from '@assets';
import { useAccount } from '@hooks';

import * as S from './styled';

export const NotFound = () => {
  const navigate = useNavigate();
  const { isLogged } = useAccount();

  if (!isLogged) return;
  return (
    <S.Wrapper align="center" justify="center" gap={24} vertical>
      <img src={NotFoundImage} alt="not-found" />
      <S.Title>Page isn't found</S.Title>
      <S.Description>
        The page you’re looking for couldn’t be found
      </S.Description>
      <Button type="primary" size="large" onClick={() => navigate(-1)}>
        Go back to the previous page
      </Button>
    </S.Wrapper>
  );
};
