import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Content = styled(Flex)<FlexProps & { width?: number }>`
  width: ${({ width }) => `${width || 327}px`};
`;

export const Inner = styled.div`
  padding: 8px;
`;

export const Contacts = styled.div`
  margin: 0 -12px 16px -12px;
`;
