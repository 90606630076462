import { FC } from 'react';

import { Spin, type SpinProps } from 'antd';

import * as S from './styled';

const Loader: FC<SpinProps & { noPadding?: boolean }> = ({ ...rest }) => (
  <S.Wrapper noPadding={rest.noPadding}>
    <Spin {...rest} />
  </S.Wrapper>
);

export default Loader;
