import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { IconCheck, IconDrag, IconTrash } from '@assets';

import { STEP } from './constants';
import * as S from './styled';

type ProcessesTemplatesEditStepProps = {
  title: string;
  number: number;
  isCurrent?: boolean;
  moveStep: (dragIndex: number, hoverIndex: number) => void;
  onClick: () => void;
  onDeleteClick: () => void;
};

type DragItem = {
  number: number;
};

const ProcessesTemplatesEditStep = ({
  title,
  number,
  isCurrent,
  moveStep,
  onClick,
  onDeleteClick,
}: ProcessesTemplatesEditStepProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [called, setCalled] = useState(false);

  /**
   * it's a workaround for strict mode.
   * see: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1167427762
   */
  useEffect(() => {
    const animation = requestAnimationFrame(() => setCalled(true));

    return () => {
      cancelAnimationFrame(animation);
      setCalled(false);
    };
  }, [called]);

  const [, dropRef] = useDrop({
    accept: STEP,
    hover: (item: DragItem, monitor) => {
      const dragIndex = item.number - 1;
      const hoverIndex = number - 1;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        ((hoverBoundingRect?.bottom || 0) - (hoverBoundingRect?.top || 0)) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY =
        (clientOffset?.y || 0) - (hoverBoundingRect?.top || 0);

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveStep(dragIndex, hoverIndex);
      item.number = hoverIndex;
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: STEP,
    item: { number },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  previewRef(dropRef(ref));

  return (
    <S.Step
      ref={ref}
      gap={8}
      align="center"
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={onClick}
    >
      <S.StepDragHandler ref={dragRef}>
        <IconDrag />
      </S.StepDragHandler>
      {isCurrent ? (
        <S.StepNumber isCurrent>
          <IconCheck />
        </S.StepNumber>
      ) : (
        <S.StepNumber>{number}</S.StepNumber>
      )}
      <S.Title strong>{title}</S.Title>
      <S.DeleteButton type="text" onClick={onDeleteClick} danger>
        <IconTrash />
      </S.DeleteButton>
    </S.Step>
  );
};

export default ProcessesTemplatesEditStep;
