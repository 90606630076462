import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { type FlexProps, Flex } from 'antd';

export const Container = styled(Flex)<FlexProps>`
  width: 100%;
  max-width: 820px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  padding: 20px 30px;
`;

export const Title = styled(Flex)<FlexProps>`
  font-weight: 600;
  font-size: 20px;
`;
