import styled from '@emotion/styled';

import { type FormItemProps, Form } from 'antd';

export const FormItem = styled(Form.Item)<FormItemProps>`
  && {
    margin-bottom: 12px;

    .ui-form-item-label {
      label {
        font-size: 12px;
      }
    }
  }

  .ui-input-number,
  .ui-picker {
    width: 100%;
  }
`;
