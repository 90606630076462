import { NavLink, type NavLinkProps } from 'react-router-dom';

import styled from '@emotion/styled';

import { Layout, LayoutProps } from 'antd';

export const Sidebar = styled(Layout.Sider)<LayoutProps>`
  &.ui-layout-sider {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .ui-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Menu = styled.nav`
  display: flex;
  flex-direction: column;
`;

export const MenuLink = styled(NavLink)<NavLinkProps>`
  height: 82px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.bgDarkActive};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.bgDarkActive};
    color: ${({ theme }) => theme.colors.bgYellow};
  }
`;
