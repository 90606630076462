import { Button, Flex, Modal } from 'antd';

import { useAdminProcessTemplateController_delete } from '@api-client/generated/AdminProcessTemplateController/delete';
import { Schemas } from '@api-client/generated/types';

type ProcessTemplateResponseDto = Schemas.ProcessTemplateResponseDto;

type ProcessesTemplatesDeleteTemplateModalProps = {
  template: ProcessTemplateResponseDto | null;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const ProcessesTemplatesDeleteTemplateModal = ({
  template,
  isOpen,
  onClose,
  onDelete,
}: ProcessesTemplatesDeleteTemplateModalProps) => {
  const { mutateAsync: deleteTemplate } =
    useAdminProcessTemplateController_delete();

  const handleDeleteTemplateClick = () => {
    deleteTemplate(
      {
        parameter: {
          id: template!.id,
        },
      },
      {
        onSuccess: () => {
          onDelete();
          onClose();
        },
      }
    );
  };

  if (!template) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      footer={
        <Flex gap={8} justify="flex-end">
          <Button type="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleDeleteTemplateClick} danger>
            Delete
          </Button>
        </Flex>
      }
      destroyOnClose
    >
      Are you sure you want to delete template "{template.name}"?
    </Modal>
  );
};

export default ProcessesTemplatesDeleteTemplateModal;
