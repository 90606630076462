import { Button, Flex, Typography, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useAdminProcessTemplateController_create } from '@api-client/generated/AdminProcessTemplateController/create';
import { useAdminProcessTemplateController_findAll } from '@api-client/generated/AdminProcessTemplateController/findAll';
import { useAdminProcessTemplateController_findOneById } from '@api-client/generated/AdminProcessTemplateController/findOneById';
import { useAdminProcessTemplateController_update } from '@api-client/generated/AdminProcessTemplateController/update';
import { Schemas } from '@api-client/generated/types';
import { DEFAULT_TIMEOUT_FOR_DEBOUNCE } from '@constants';
import ProcessesTemplatesEdit from '@entities/processes/ProcessesTemplatesEdit';
import ProcessesTemplatesList, {
  ProcessesTemplatesListTab,
} from '@entities/processes/ProcessesTemplatesList';
import ProcessesTemplatesModal from '@entities/processes/ProcessesTemplatesModal';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';

import * as S from './styled';

type ProcessTemplateListResponseDto = Schemas.ProcessTemplateListResponseDto;
type OrderedStepResponseDto = Schemas.OrderedStepResponseDto;
type ProcessTemplateResponseDto = Schemas.ProcessTemplateResponseDto;
type ProcessTemplateRequestDto = Schemas.ProcessTemplateRequestDto;
type StepPositionRequestDto = Schemas.StepPositionRequestDto;

const { Title } = Typography;

const useGetPaginatedTemplates = (filterOptions: Record<string, unknown>) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isPending, setIsPending] = useState(true);
  const hash = JSON.stringify(filterOptions);

  const { metadata, incrementPage, hasNextPage, plainData, appendData, reset } =
    usePagination<ProcessTemplateListResponseDto>(hash);

  const { data, isFetching, refetch } =
    useAdminProcessTemplateController_findAll({
      params: {
        page: metadata.currentPage,
        ...filterOptions,
      },
    });

  useEffect(() => {
    if (data) {
      appendData(data);
      if (!isFetching) {
        setIsInitialLoading(false);
        setIsPending(false);
      }
    }
  }, [data, isFetching, appendData]);

  const { sentryRef } = useInfiniteScroll({
    isLoading: isPending,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  const refetchData = () => {
    reset();
    if (metadata.currentPage === 1) {
      refetch();
    }
  };

  return {
    currentPage: metadata.currentPage,
    isFetching,
    isInitialLoading,
    plainData,
    sentryRef,
    hasNextPage,
    refetchData,
  };
};

const ProcessesTemplates = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(-1);
  const [search, setSearch] = useState('');
  const [selectedTemplate, setSelectedTemplate] =
    useState<ProcessTemplateListResponseDto | null>(null);
  const [templateForEdit, setTemplateForEdit] =
    useState<ProcessTemplateResponseDto | null>(null);
  const [templatesTab, setTemplatesTab] = useState<ProcessesTemplatesListTab>(
    ProcessesTemplatesListTab.All
  );
  const [steps, setSteps] = useState<Partial<OrderedStepResponseDto>[]>([]);

  const templatesListRef = useRef<HTMLDivElement>(null);

  const [term] = useDebounce(search, DEFAULT_TIMEOUT_FOR_DEBOUNCE);

  const {
    data: template,
    refetch: refetchTemplate,
    isPending,
  } = useAdminProcessTemplateController_findOneById({
    params: {
      id: selectedTemplate?.id || '',
    },
    config: {
      enabled: !!selectedTemplate,
    },
  });

  const { plainData, sentryRef, hasNextPage, refetchData, isInitialLoading } =
    useGetPaginatedTemplates({
      term,
      isPublished:
        templatesTab === ProcessesTemplatesListTab.All
          ? undefined
          : templatesTab === ProcessesTemplatesListTab.Published,
    });

  useEffect(() => {
    if (template) {
      setSteps(template.steps);
    }
  }, [template]);

  const { mutateAsync: createTemplate } =
    useAdminProcessTemplateController_create();
  const { mutateAsync: updateTemplate } =
    useAdminProcessTemplateController_update();

  const getOrderedSteps = (
    steps: Partial<OrderedStepResponseDto>[]
  ): StepPositionRequestDto[] =>
    steps
      .filter((step) => !!step.id)
      .map((step, position) => ({ stepId: step.id!, position }));

  const saveTemplate = (steps: Partial<OrderedStepResponseDto>[]) => {
    if (!selectedTemplate) {
      return;
    }

    try {
      updateTemplate({
        parameter: {
          id: selectedTemplate.id,
        },
        requestBody: { ...selectedTemplate, steps: getOrderedSteps(steps) },
      });
    } catch {
      message.error('Failed to update template');
    }
  };

  const handleSuccess = () => {
    templatesListRef.current?.scroll({
      top: 0,
      behavior: 'smooth',
    });
    refetchData();
  };

  const handleTemplateSubmit = async (values: ProcessTemplateRequestDto) => {
    if (templateForEdit) {
      saveTemplate(steps);
      setTemplateForEdit(null);
    } else {
      await createTemplate(
        {
          requestBody: values,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
  };

  const handleAddStep = (step: Partial<OrderedStepResponseDto>) => {
    setSteps((prev) => [...prev, step]);
    setCurrentStep(steps.length);
    saveTemplate([...steps, step]);
  };

  const handleTemplateUpdate = () => {
    if (selectedTemplate) {
      updateTemplate(
        {
          parameter: {
            id: selectedTemplate!.id,
          },
          requestBody: {
            name: selectedTemplate.name,
            description: selectedTemplate.description,
            steps: getOrderedSteps(steps),
          },
        },
        {
          onSuccess: () => {
            refetchTemplate();
          },
        }
      );
    }
  };

  const handleEditClick = () => {
    if (template) {
      setTemplateForEdit(template);
      setIsModalOpen(true);
    }
  };

  const handleChangeSteps = (steps: Partial<OrderedStepResponseDto>[]) => {
    setSteps(steps);
    saveTemplate(steps);
  };

  const handleDeleteStep = (step: Partial<OrderedStepResponseDto>) => {
    setSteps((steps) => {
      const newSteps = steps.filter((s) => s !== step);
      saveTemplate(newSteps);

      return newSteps;
    });
  };

  return (
    <>
      <S.Root gap={32} vertical>
        <Flex justify="space-between">
          <Title level={2}>Templates</Title>
          <Button
            type="secondary"
            size="large"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Add new template
          </Button>
        </Flex>
        <S.Body gap={32}>
          <ProcessesTemplatesList
            ref={templatesListRef}
            isLoading={isInitialLoading}
            sentryRef={sentryRef}
            currentTab={templatesTab}
            templates={plainData || []}
            selected={selectedTemplate}
            search={search}
            hasNextPage={hasNextPage}
            onTabChange={setTemplatesTab}
            onSelect={setSelectedTemplate}
            onSearch={setSearch}
            onAddTemplateClick={() => {
              setIsModalOpen(true);
            }}
          />
          <ProcessesTemplatesEdit
            isLoading={!!selectedTemplate && isPending}
            template={template}
            currentStep={currentStep}
            steps={steps}
            onSelectStep={setCurrentStep}
            onAddStep={handleAddStep}
            onEditClick={handleEditClick}
            onChangeSteps={handleChangeSteps}
            onUpdate={handleTemplateUpdate}
            onDelete={refetchData}
            onDeleteStep={handleDeleteStep}
          />
        </S.Body>
      </S.Root>

      <ProcessesTemplatesModal
        isOpen={isModalOpen}
        template={templateForEdit}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleTemplateSubmit}
      />
    </>
  );
};
export default ProcessesTemplates;
