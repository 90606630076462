import styled from '@emotion/styled';
import { type FlexProps, Flex } from 'antd';

export const Inner = styled(Flex)<FlexProps>`
  .ui-form-item {
    margin-bottom: 16px;
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrayLight};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 12px;
`;

export const Submit = styled.div`
  margin-top: 20px;
`;

export const Types = styled(Flex)<FlexProps>`
  margin-bottom: 16px;
`;

export const Type = styled.div<{ selected: boolean }>`
  height: 34px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.bgDarkBlue : theme.colors.white};
  border-radius: 34px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.bgDarkBlue : theme.colors.bgDarkGrey};
  cursor: pointer;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.blackSecondary};
  font-weight: 500;
  padding: 6px 12px;
`;
