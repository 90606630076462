import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Preview = styled.div`
  width: 497px;
`;

export const Files = styled(Flex)<FlexProps>`
  width: 100%;
  flex: 1;
`;
