import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Contact = styled(Flex)<FlexProps & { selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.bgGrey : theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  cursor: pointer;
  padding: 16px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgGrey};
  }
`;

export const ContactName = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
`;
