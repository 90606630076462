//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminBobContactController_findAll,
  Response$AdminBobContactController_findAll$Status$200,
  ResponseContentType$AdminBobContactController_findAll,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminBobContactController_findAll = (options: {
  params: Params$AdminBobContactController_findAll['parameter'];
  config?: QueryOptions<
    Response$AdminBobContactController_findAll$Status$200[ResponseContentType$AdminBobContactController_findAll]
  >;
}) =>
  useApiClient<
    Params$AdminBobContactController_findAll,
    Response$AdminBobContactController_findAll$Status$200[ResponseContentType$AdminBobContactController_findAll]
  >({
    method: 'AdminBobContactController_findAll',
    params: { parameter: options.params },
    config: options.config,
  });
