import { type AxiosError } from 'axios';

import apiClient from '@api-client';
import { useMutation } from '@tanstack/react-query';

type ApiClientMutationProps = {
  method: keyof typeof apiClient;
};

type ApiErrorData = {
  error: string;
  statusCode: number;
  message: string[];
};

type ApiError = {
  message: string[];
  data: ApiErrorData;
};

export const useApiClientMutation = <T = unknown, R = unknown>({
  method,
}: ApiClientMutationProps) =>
  useMutation<R, AxiosError<ApiError, never>, T, never>({
    mutationFn: (params) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      apiClient[method](params as any).then((res) => res as R),
  });
