import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { type FlexProps, type ButtonProps, Flex, Button } from 'antd';

export const Container = styled(Flex)<FlexProps>`
  width: 100%;
  height: calc(100vh - 172px);
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  padding: 20px 30px;
`;

export const Header = styled(Flex)<FlexProps>`
  height: 80px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 10px 10px 0 0;
  padding-bottom: 20px;
`;

export const Title = styled(Flex)<FlexProps>`
  font-weight: 600;
  font-size: 20px;
`;

export const Details = styled.div`
  color: ${({ theme }) => theme.colors.blackSecondary};
`;

export const ButtonEdit = styled(Button)<ButtonProps>`
  && {
    color: ${({ theme }) => theme.colors.blackSecondary};
  }
`;
