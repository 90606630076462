import { Button, Flex, Form as FormBase } from 'antd';

import styled from '@emotion/styled';

export const FormWrapper = styled.div`
  flex: 1 1 0;
  min-width: 0;
  overflow: auto;
  height: calc(100% - 56px);
  padding-right: 32px;

  & .ui-form {
    height: 100%;
  }
`;

export const FullFormItem = styled(FormBase.Item)`
  width: 100%;
`;

export const Group = styled(Flex)`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.bgWhite};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
`;

export const Templates = styled(Flex)`
  padding: 20px 24px;
  background-color: ${({ theme }) => theme.colors.bgLightYellow};
  border-radius: 10px;
`;

export const SaveStepButton = styled(Button)`
  flex: 1 1 50%;
`;
