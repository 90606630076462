import { Button, Flex, Form, Row, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { useAdminBobAccountController_findAll } from '@api-client/generated/AdminBobAccountController/findAll';
import { useAdminBobDaybookController_findAll } from '@api-client/generated/AdminBobDaybookController/findAll';
import { useAdminBobVATController_findAll } from '@api-client/generated/AdminBobVATController/findAll';
import { useAdminDocumentController_updateOneById } from '@api-client/generated/AdminDocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { IconLock, IconTrash } from '@assets';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIMEOUT_FOR_DEBOUNCE,
  currencyCodes,
} from '@constants';
import {
  ContactsPopupList,
  DocumentExpenseAmountStatus,
  DocumentExpenseWrapperFormControl,
} from '@entities';
import { useAccount } from '@hooks';

import * as S from './styled';

type BookkeepingDocumentMetadataDto =
  Partial<Schemas.BookkeepingDocumentMetadataDto> & { bobContactId?: string };

type BookingDocumentMetadata = Schemas.BookingDocumentMetadata;

type DocumentExpenseFormProps = {
  document: Schemas.Document;
  onAfterUpdate: () => void;
};

const showUpdatedMessage = () =>
  message.success('Document information has been successfully updated');

const getStatusByAmount = (
  values: BookkeepingDocumentMetadataDto | BookingDocumentMetadata
) => {
  const sumTotalPrice = _.sum(
    (values.items || []).map((item) => item?.totalPrice)
  );

  return values.items?.length ? values?.amount === sumTotalPrice : true;
};

const DocumentExpenseForm: FC<DocumentExpenseFormProps> = ({
  document,
  onAfterUpdate,
}) => {
  const { id: documentId } = useParams();
  const { companyId } = useAccount();

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const isProcessing = document.adminStatus === 'processing';
  const isBooked = document.adminStatus === 'processed';
  const isReady = document.adminStatus === 'ready';

  const reqParameters = {
    id: documentId!,
    companyId: companyId!,
  };

  const [submittable, setSubmittable] = useState(false);

  const [formValues, setFormValues] = useState<
    BookkeepingDocumentMetadataDto | BookingDocumentMetadata
  >();

  const [selectedContact, setSelectedContact] =
    useState<Schemas.BobContact | null>(null);

  const [unlockedDocumentId, setUnlockedDocumentId] = useState(false);

  const { data: daybooks, isPending: loadingDaybooks } =
    useAdminBobDaybookController_findAll({
      params: {
        companyId: companyId!,
      },
    });

  const { data: accounts, isPending: loadingAccounts } =
    useAdminBobAccountController_findAll({
      params: {
        companyId: companyId!,
      },
    });

  const { data: vats, isPending: loadingVats } =
    useAdminBobVATController_findAll({
      params: {
        companyId: companyId!,
      },
    });

  const { mutate: updateDocument } = useAdminDocumentController_updateOneById();

  useEffect(
    () => {
      if (document) {
        form.setFieldsValue({
          ...document.bookeepingMetadata,
          bobContactId: document.bobContact?.id,
          issueDate: dayjs(document.bookeepingMetadata.issueDate),
        });

        setSelectedContact(document.bobContact);
        setFormValues(document.bookeepingMetadata);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document]
  );

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const handleUpdateWithDebounce = useDebouncedCallback(
    (values) => handleUpdate(values),
    DEFAULT_TIMEOUT_FOR_DEBOUNCE
  );

  const handleUpdate = (
    values: BookkeepingDocumentMetadataDto,
    isBookeeping: boolean = true
  ) =>
    updateDocument(
      {
        parameter: reqParameters,
        // @ts-expect-error-next-line
        requestBody: isBookeeping
          ? {
              bookeepingMetadata: {
                ...(formValues as BookkeepingDocumentMetadataDto),
                ...values,
              },
            }
          : { ...values },
      },
      { onSuccess: () => showUpdatedMessage() }
    );

  const handleSubmit = () =>
    updateDocument(
      {
        parameter: reqParameters,
        requestBody: {
          adminStatus: 'processing',
        },
      },
      {
        onSuccess: () => {
          showUpdatedMessage();
          setUnlockedDocumentId(false);
          onAfterUpdate();
        },
      }
    );

  const handleUpdateList = (
    field: string,
    name: number,
    value: string | number
  ) =>
    (formValues?.items || []).map((item, index) =>
      name === index ? { ...item, [field]: value } : item
    );

  const handleUpdateContact = (contact: Schemas.BobContact) => {
    form.setFieldValue('bobContactId', contact.id);
    setSelectedContact(contact);

    handleUpdate({ bobContactId: contact.id }, false);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      onValuesChange={(_, values) => setFormValues(values)}
      layout="vertical"
      requiredMark={false}
      disabled={isBooked}
      initialValues={{
        items: [],
      }}
    >
      <Row gutter={[12, 0]}>
        <DocumentExpenseWrapperFormControl
          type="input"
          span={8}
          form={{
            label: 'Number',
            name: 'number',
            rules: [{ required: true }],
          }}
          control={{
            onChange: (e) =>
              handleUpdateWithDebounce({
                number: e.target.value,
              }),
          }}
        />

        <DocumentExpenseWrapperFormControl
          type="date-picker"
          span={8}
          form={{
            label: 'Issue date',
            name: 'issueDate',
            rules: [{ required: true }],
          }}
          control={{
            format: DEFAULT_DATE_FORMAT,
            onChange: (value) =>
              value &&
              handleUpdate({
                issueDate: dayjs(value as Dayjs).format(DEFAULT_DATE_FORMAT),
              }),
          }}
        />

        <DocumentExpenseWrapperFormControl
          type="input"
          span={8}
          form={{
            label: 'Doc ID',
            name: 'docId',
          }}
          control={{
            addonAfter:
              isBooked || isProcessing || unlockedDocumentId ? (
                <Button
                  type="link"
                  icon={<IconLock width={20} height={20} />}
                  onClick={() => setUnlockedDocumentId(true)}
                />
              ) : null,
            disabled: !isReady
              ? !unlockedDocumentId && (isBooked || isProcessing)
              : true,
            onChange: (e) =>
              handleUpdateWithDebounce({
                docId: e.target.value,
              }),
          }}
        />
      </Row>

      <ContactsPopupList
        placement="bottomLeft"
        onSelect={handleUpdateContact}
        onAfterCreate={handleUpdateContact}
      >
        <Form.Item
          label="Contact"
          name="bobContactId"
          rules={[{ required: true }]}
        >
          <S.SelectContacts
            size="large"
            options={[
              {
                label: selectedContact?.name,
                value: selectedContact?.id,
              },
            ]}
            popupClassName="popup-hidden"
          />
        </Form.Item>
      </ContactsPopupList>

      <Row gutter={[12, 0]}>
        <DocumentExpenseWrapperFormControl
          type="select"
          span={8}
          form={{
            label: 'Journal',
            name: 'daybookId',
            rules: [{ required: true }],
          }}
          control={{
            options: (daybooks || []).map((book) => ({
              label: `${book.id} - ${book.name}`,
              value: book.id,
            })),
            loading: loadingDaybooks,
            onChange: (value) =>
              handleUpdate({
                daybookId: value,
              }),
          }}
        />

        <DocumentExpenseWrapperFormControl
          type="input-number"
          span={8}
          form={{
            label: 'Amount',
            name: 'amount',
            rules: [{ required: true }],
          }}
          control={{
            onChange: (value) =>
              handleUpdateWithDebounce({
                amount: value,
              }),
          }}
        />

        <DocumentExpenseWrapperFormControl
          type="select"
          span={8}
          form={{
            label: 'Currency',
            name: 'currency',
            rules: [{ required: true }],
          }}
          control={{
            options: currencyCodes.map((code) => ({
              label: code,
              value: code,
            })),
            onChange: (value) =>
              handleUpdate({
                currency: value,
              }),
          }}
        />
      </Row>

      <Flex gap={18} vertical>
        <Form.List name="items">
          {(items, { add, remove }) => (
            <>
              <Flex align="center" justify="space-between">
                <S.LinesTitle>Lines</S.LinesTitle>

                <S.LinesAdd disabled={isBooked} onClick={() => add()}>
                  + Add line
                </S.LinesAdd>
              </Flex>

              {items.map(({ key, name, ...rest }) => (
                <S.Card key={key} gap={12} vertical>
                  <Flex align="center" gap={8} justify="space-between">
                    <DocumentExpenseWrapperFormControl
                      type="input"
                      form={{
                        name: [name, 'name'],
                        rules: [{ required: true }],
                        noStyle: true,
                      }}
                      control={{
                        size: 'middle',
                        onChange: (e) =>
                          handleUpdateWithDebounce({
                            items: handleUpdateList(
                              'name',
                              name,
                              e.target.value
                            ),
                          }),
                      }}
                    />

                    <Button
                      type="link"
                      icon={<IconTrash width={24} height={24} />}
                      onClick={() => {
                        handleUpdate({
                          items: (formValues?.items || []).filter(
                            (_, index) => name !== index
                          ),
                        });

                        remove(name);
                      }}
                      danger
                    />
                  </Flex>

                  <Row gutter={[12, 0]}>
                    <DocumentExpenseWrapperFormControl
                      type="select"
                      span={8}
                      form={{
                        name: [name, 'accountCode'],
                        rules: [{ required: true }],
                        noStyle: true,
                        ...rest,
                      }}
                      control={{
                        placeholder: 'Account code',
                        size: 'middle',
                        options: (accounts || []).map((account) => ({
                          label: `${account.id} - ${account.name}`,
                          value: account.id,
                        })),
                        loading: loadingAccounts,
                        onChange: (value) =>
                          handleUpdate({
                            items: handleUpdateList('accountCode', name, value),
                          }),
                      }}
                    />

                    <DocumentExpenseWrapperFormControl
                      type="input-number"
                      span={8}
                      form={{
                        name: [name, 'totalPrice'],
                        rules: [{ required: true }],
                        noStyle: true,
                        ...rest,
                      }}
                      control={{
                        size: 'middle',
                        onChange: (value) =>
                          handleUpdateWithDebounce({
                            items: handleUpdateList(
                              'totalPrice',
                              name,
                              Number(value)
                            ),
                          }),
                      }}
                    />

                    <DocumentExpenseWrapperFormControl
                      type="select"
                      span={8}
                      form={{
                        name: [name, 'vatCode'],
                        rules: [{ required: true }],
                        noStyle: true,
                        ...rest,
                      }}
                      control={{
                        placeholder: 'VAT code',
                        size: 'middle',
                        options: (vats || []).map((vat) => ({
                          label: `${vat.id} - ${vat.name}`,
                          value: vat.id,
                        })),
                        loading: loadingVats,
                        onChange: (value) =>
                          handleUpdate({
                            items: handleUpdateList('vatCode', name, value),
                          }),
                      }}
                    />
                  </Row>
                </S.Card>
              ))}
            </>
          )}
        </Form.List>
      </Flex>

      {!isBooked && (
        <S.Submit
          align="center"
          justify={formValues?.amount ? 'space-between' : 'flex-end'}
        >
          {formValues?.amount && (
            <DocumentExpenseAmountStatus
              isEqual={getStatusByAmount(formValues)}
            />
          )}

          <Form.Item noStyle>
            <Flex justify="flex-end">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                disabled={!getStatusByAmount(formValues || {}) || !submittable}
              >
                Submit for booking
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      )}
    </Form>
  );
};

export default DocumentExpenseForm;
