import {
  FC,
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
} from 'react';

import * as S from './styled';

export type ScrollbarProps = {
  height?: number | string;
  maxHeight?: number;
  withScrollToBottom?: boolean;
  withoutPadding?: boolean;
  scrollToRef?: MutableRefObject<HTMLDivElement | null>;
  effectEventListener?: unknown;
  effectSource?: unknown;
  effectsReload?: unknown[];
  isHidden?: boolean;
  onScrollBottom?: (isBottom: boolean) => void;
};

const Scrollbar: FC<PropsWithChildren<ScrollbarProps>> = ({
  height,
  maxHeight,
  children,
  withScrollToBottom,
  withoutPadding,
  effectEventListener,
  effectSource,
  effectsReload,
  scrollToRef,
  isHidden,
  onScrollBottom,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(
    () => {
      const scrollableElement = containerRef.current;

      if (scrollableElement && onScrollBottom) {
        handleScroll();

        scrollableElement.addEventListener('scroll', handleScroll);

        return () => {
          scrollableElement.removeEventListener('scroll', handleScroll);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [effectEventListener, effectSource]
  );

  useEffect(
    () => {
      if (containerRef) {
        if (scrollToRef?.current) {
          scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        if (withScrollToBottom && !scrollToRef?.current) {
          setTimeout(handleScrollToBottom, 100);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...(effectsReload || []), containerRef, scrollToRef]
  );

  const handleScroll = () => {
    if (containerRef.current && onScrollBottom) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const sumScroll = scrollTop + clientHeight;

      if (sumScroll >= scrollHeight - 50) {
        onScrollBottom(true);
      } else {
        onScrollBottom(false);
      }
    }
  };

  const handleScrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
      });
    }
  };

  return (
    <S.Container
      ref={containerRef}
      height={height}
      maxHeight={maxHeight}
      withoutPadding={withoutPadding}
      isHidden={isHidden}
    >
      {children}
    </S.Container>
  );
};

export default Scrollbar;
