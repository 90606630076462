import { FC } from 'react';

import { Flex, Typography } from 'antd';

import { TransactionsContent } from '@entities';

const { Title } = Typography;

const Transactions: FC = () => (
  <Flex gap={24} vertical>
    <TransactionsContent title={<Title>Transactions</Title>} />
  </Flex>
);

export default Transactions;
