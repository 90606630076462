import { useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import ProcessesTemplatesDeleteStepModal from '@entities/processes/ProcessesTemplatesDeleteStepModal';
import ProcessesTemplatesEditStep from '@entities/processes/ProcessesTemplatesEditSteps/ProcessesTemplatesEditStep';

import * as S from './styled';

type OrderedStepResponseDto = Schemas.OrderedStepResponseDto;

type ProcessesTemplatesEditStepsProps = {
  steps: Partial<OrderedStepResponseDto>[];
  currentStep?: number;
  onChange: (steps: Partial<OrderedStepResponseDto>[]) => void;
  onStepClick: (index: number) => void;
  onDeleteStep: (step: Partial<OrderedStepResponseDto>) => void;
};

const ProcessesTemplatesEditSteps = ({
  steps,
  currentStep = 0,
  onChange,
  onStepClick,
  onDeleteStep,
}: ProcessesTemplatesEditStepsProps) => {
  const [deletingStep, setDeletingStep] =
    useState<Partial<OrderedStepResponseDto> | null>(null);

  const handleChangeSteps = (dragIndex: number, hoverIndex: number) => {
    const newSteps = [...steps];
    const [removed] = newSteps.splice(dragIndex, 1);
    newSteps.splice(hoverIndex, 0, removed);
    onChange(newSteps);
  };

  return (
    <>
      <S.Root flex="0 0 auto" gap={8} vertical>
        {steps.map((step, index) => (
          <ProcessesTemplatesEditStep
            key={step.id}
            title={step.name || 'Unnamed step'}
            number={index + 1}
            isCurrent={index === currentStep}
            onClick={() => onStepClick(index)}
            onDeleteClick={() => setDeletingStep(step)}
            moveStep={handleChangeSteps}
          />
        ))}
      </S.Root>

      <ProcessesTemplatesDeleteStepModal
        isOpen={!!deletingStep}
        onClose={() => setDeletingStep(null)}
        onDelete={onDeleteStep}
        step={deletingStep!}
      />
    </>
  );
};

export default ProcessesTemplatesEditSteps;
