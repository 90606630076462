import { Spin } from 'antd';
import { FC } from 'react';
import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom';

import { useAccount } from '@hooks';

import { ErrorFallback } from './ErrorFallback';

function DefaultErrorComponent() {
  const error = useRouteError();
  const stack = error instanceof Error ? error.stack : undefined;

  return <ErrorFallback error={error} componentStack={stack} />;
}

const AppRouter: FC = () => {
  const { routerPaths } = useAccount();

  const appRoutes = [
    {
      path: '/',
      errorElement: <DefaultErrorComponent />,
      children: routerPaths,
    },
  ];

  appRoutes.forEach((route) => {
    route.children?.forEach((child) => {
      child.errorElement = <DefaultErrorComponent />;
      child.children?.forEach((child) => {
        child.errorElement = <DefaultErrorComponent />;
      });
    });
  });

  const router = createBrowserRouter(appRoutes, {
    basename: import.meta.env.VITE_ROUTE_BASE_URL || '/',
  });

  return <RouterProvider router={router} fallbackElement={<Spin />} />;
};

export default AppRouter;
