import { useEffect, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { useAccount, useSocketClient } from '@hooks';
import { useTasksStore } from '@store';

type ChatMessage = Schemas.ChatMessage;

type ResponseInData<T> = {
  data: T;
};

type SocketChatProps = {
  taskId: string;
  companyId: string;
};

type CreatingMessageParams = {
  companyId: string;
  taskId: string;
  body: string;
  fileIds?: string[];
};

type SocketChatParams = {
  messages: ChatMessage[];
  isLoading: boolean;
  createMessage: (body: string) => void;
  deleteMessage: (id: string) => void;
  markAsSeenMessage: (ids: string[]) => void;
};

const useSocketChat = ({
  taskId,
  companyId,
}: SocketChatProps): SocketChatParams => {
  const { account } = useAccount();

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { updateUnseenCountOfTask } = useTasksStore();

  const { socket } = useSocketClient({
    namespace: 'admin_chat',
  });

  useEffect(() => {
    if (taskId && companyId) {
      setIsLoading(true);
      setMessages([]);

      socket.emit('messages:all', {
        taskId,
        companyId,
      });
    }
  }, [socket, taskId, companyId]);

  useEffect(
    () => {
      const socketEventResponse = (response: ResponseInData<ChatMessage[]>) => {
        setMessages(response.data);
        setIsLoading(false);
      };

      const socketEventCreated = (response: ResponseInData<ChatMessage>) => {
        const message = response.data;

        if (taskId === message.taskId) {
          setMessages((prevState) => [...prevState, message]);
        }

        if (account?.id !== message.author.id) {
          updateUnseenCountOfTask(message.taskId, 'inc');
        }

        setIsLoading(false);
      };

      const socketEventDeleted = (response: ResponseInData<ChatMessage>) => {
        if (taskId === response.data.taskId) {
          setMessages((prevState) =>
            prevState.map((message) =>
              message.id === response.data.id
                ? { ...message, ...response.data }
                : message
            )
          );
        }

        setIsLoading(false);
      };

      socket.off('response', socketEventResponse);
      socket.off('messages:created', socketEventCreated);
      socket.off('messages:deleted', socketEventDeleted);

      socket.on('response', socketEventResponse);
      socket.on('messages:created', socketEventCreated);
      socket.on('messages:deleted', socketEventDeleted);

      return () => {
        socket.off('response', socketEventResponse);
        socket.off('messages:created', socketEventCreated);
        socket.off('messages:deleted', socketEventDeleted);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socket, account, taskId]
  );

  const createMessage = (body: string, fileIds: string[] = []) => {
    const params: CreatingMessageParams = {
      companyId,
      taskId,
      body,
    };

    if (fileIds.length) {
      params['fileIds'] = fileIds;
    }

    setIsLoading(true);

    socket.emit('messages:create', params);
  };

  const deleteMessage = (id: string) => {
    setIsLoading(true);

    socket.emit('messages:delete', {
      companyId,
      id,
    });
  };

  const markAsSeenMessage = (ids: string[]) => {
    socket.emit('messages:mark_as_seen', {
      companyId,
      ids,
    });

    setMessages((prevState) =>
      prevState.map((message) =>
        ids.includes(message.id)
          ? {
              ...message,
              seenData: [
                {
                  userId: account ? account?.id : '',
                  seenAt: '_',
                },
              ],
            }
          : message
      )
    );
  };

  return {
    messages,
    isLoading,
    createMessage,
    deleteMessage,
    markAsSeenMessage,
  };
};

export default useSocketChat;
