import { List } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { Scrollbar } from '@components';
import { getDateRelative } from '@utils';

import ChatSidebarItem from '../ChatSidebarItem';

type ChatSidebarListProps = {
  companies: Schemas.AdminCompanyResponseDto[];
  isLoading: boolean;
};

const ChatSidebarList: FC<ChatSidebarListProps> = ({
  companies,
  isLoading,
}) => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const handleNavigateToCompany = (id: string) => {
    if (companyId !== id) {
      navigate(`/chat-tasks/${id}/chats`);
    }
  };

  return (
    <Scrollbar height="calc(100vh - 275px)" withoutPadding isHidden>
      <List
        dataSource={companies}
        renderItem={(item) => (
          <ChatSidebarItem
            name={item.name}
            date={getDateRelative(new Date())}
            countActive={0}
            isSelected={item.id === companyId}
            onClick={() => handleNavigateToCompany(item.id)}
          />
        )}
        loading={isLoading}
        locale={{ emptyText: <div /> }}
      />
    </Scrollbar>
  );
};

export default ChatSidebarList;
