import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type TaxCardProps = {
  details: Schemas.Tax;
};

const TaxCard: FC<TaxCardProps> = ({ details }) => {
  const navigate = useNavigate();

  return (
    <S.Card>
      <S.Header>{details.paidAt ? 'To be paid' : 'Payment is due'}</S.Header>

      <S.Body>
        <S.SubTitle>{details.details.reference}</S.SubTitle>
        <S.Amount level={4}>€{details.amount}</S.Amount>

        <Button onClick={() => navigate(`/taxes/${details.id}`)} size="large">
          Review
        </Button>
      </S.Body>
    </S.Card>
  );
};

export default TaxCard;
