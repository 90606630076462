import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAdminTaskController_findAll } from '@api-client/generated/AdminTaskController/findAll';
import { ChatSidebar, ChatTabs } from '@entities';
import { useTasksStore } from '@store';

import * as S from './styled';

const ChatAndTasks: FC = () => {
  const { companyId } = useParams();

  const { fetchTasks } = useTasksStore();

  const { data: tasks } = useAdminTaskController_findAll({
    params: {
      companyId,
    },
  });

  useEffect(() => {
    if (tasks?.length) {
      fetchTasks(tasks);
    }
  }, [tasks, fetchTasks]);

  return (
    <S.Container>
      <ChatSidebar />
      <ChatTabs />
    </S.Container>
  );
};

export default ChatAndTasks;
