import { Flex, type FlexProps } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

export const Panel = styled(Flex)<FlexProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
`;

export const Inner = styled.div`
  padding: 30px;
`;

export const Action = styled.a`
  font-size: 12px;
  font-weight: 600;
`;
