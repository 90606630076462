import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

import { Select } from '@components';

export const Card = styled(Flex)<FlexProps>`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 12px;
`;

export const LinesTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const LinesAdd = styled.a<{ disabled: boolean }>`
  font-weight: 600;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const Submit = styled(Flex)<FlexProps>`
  border-top: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  padding-top: 18px;
  margin-top: 8px;

  && {
    .ui-btn {
      height: 48px;
      font-size: 16px;
    }
  }
`;

export const SelectContacts = styled(Select)`
  && {
    .ui-select-arrow {
      transform: rotate(0);
    }
  }
`;
