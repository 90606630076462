import { Link, LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';

import { Form } from 'antd';

export const Container = styled.div`
  padding-bottom: 76px;
`;

export const Header = styled.div`
  margin-bottom: 16px;

  && {
    .ui-typography {
      margin-bottom: 0;
    }
  }
`;

export const TransactionLink = styled(Link)<LinkProps>`
  font-weight: 600;
`;

export const FormItemClear = styled(Form.Item)`
  margin-bottom: 0;
`;
