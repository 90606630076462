import { Typography } from 'antd';

import styled from '@emotion/styled';

const { Title } = Typography;

export const Heading = styled(Title)`
  && {
    margin-bottom: 0;
  }
`;
