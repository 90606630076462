import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Footer = styled.div`
  width: 256px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding-top: 16px;

  .ui-btn {
    height: 46px;
    font-size: 16px;
    flex: 1;
  }
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`

export const Description = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
`

export const Block = styled(Flex)`
  && {
    .ui-form-item {
      width: 100%;
    }
    
    .ui-picker {
      width: 100%; 
    }
    
    .ui-select-clear {
      margin-top: -8px;
      inset-inline-end: 17px;
    }
  }
`