import { Button, Flex, Table, type TableColumnType, Typography } from 'antd';
import { FC, useState } from 'react';
import { capitalize } from 'lodash';
import { useLocation } from 'react-router-dom';

import { useAdminCompanyController_findAll } from '@api-client/generated/AdminCompanyController/findAll';
import { Schemas } from '@api-client/generated/types';
import { FilterConstructor } from '@entities';
import { getSearchParams, transformToOptions } from '@utils';
import { CompanyStatus } from '@constants';
import { useAccount } from '@hooks';

import * as S from './styled';

const { Title } = Typography;

const statusOptions = [
  { label: 'All Statuses', value: '' },
  ...transformToOptions(CompanyStatus),
];

type Company = Schemas.Company;
type FilterOptions = Record<string, unknown>;


const Companies: FC = () => {
  const { companyId, setCompany } = useAccount();
  const { search } = useLocation();
  const searchParams = getSearchParams<{ [key: string]: string }>(search);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(searchParams);

  const {
    data: companies,
    isPending: loading,
  } = useAdminCompanyController_findAll({
    params: {
      page: 1,
      ...filterOptions
    },
  });

  const columns: TableColumnType<Schemas.AdminCompanyResponseDto>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Company',
      width: 160,
      render: (name, record) => (
        <S.CompanyLink to={record.id}>{name}</S.CompanyLink>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      width: 160,
      render: (status) => {
        const formattedStatus = capitalize(status.split("_").join(" "))
        return <S.StatusTag status={status}>{formattedStatus}</S.StatusTag>
      }
    },
    {
      key: 'actions',
      align: 'right',
      render: (_, record) =>
        companyId === record.id ? (
          <S.SelectedTag color="success" bordered={false}>
            Selected
          </S.SelectedTag>
        ) : (
          <Button
            type="primary"
            size="large"
            onClick={() => setCompany(record)}
          >
            Select
          </Button>
        ),
    },
  ];

  return (
    <Flex gap={24} vertical>
      <Title>Companies</Title>
      <S.FilterWrapper>
        <FilterConstructor<Company>
          controls={{
            left: [
              {
                type: 'search',
                formName: 'term',
                params: {
                  placeholder: 'Search companies',
                },
              },
              {
                type: "divider"
              },
            ],
            right: [
              {
                type: 'select',
                formName: 'status',
                params: {
                  width: 150,
                  size: 'large',
                  placeholder: 'All statuses',
                  options: statusOptions,
                  defaultValue: '',
                },
              },
            ],
          }}
          onChange={setFilterOptions}
          withSearchParams
        />
      </S.FilterWrapper>
      <S.TableContainer>
        <Table
          rowKey={({ id }) => id}
          dataSource={companies}
          columns={columns}
          loading={loading}
          pagination={false}
          scroll={{ x: 720 }}
        />
      </S.TableContainer>
    </Flex>
  );
};

export default Companies;
