import { IconChain as IconChainInner, IconCopyInner } from '@assets';
import styled from '@emotion/styled';

export const IconCopy = styled(IconCopyInner)`
  color: ${({ theme }) => theme.colors.text400};
`;

export const IconChain = styled(IconChainInner)`
  color: ${({ theme }) => theme.colors.text400};
`;
