import styled from '@emotion/styled';

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.blackSecondary};
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
`;
