import { type InputProps, InputRef } from 'antd';
import { forwardRef } from 'react';

import { IconSearch } from '@assets';
import { colors } from '@theme';

import * as S from './styled';

export type SearchControlProps = {
  width?: number;
  fitWidth?: boolean;
  isInversedTheme?: boolean;
} & InputProps;

const SearchControl = forwardRef<InputRef, SearchControlProps>(
  ({ width, fitWidth, isInversedTheme, ...rest }, ref) => (
    <S.Control
      ref={ref}
      size="large"
      placeholder="Search"
      prefix={<IconSearch color={colors.gray} />}
      variant="filled"
      width={width}
      fitWidth={fitWidth}
      isInversedTheme={isInversedTheme}
      {...rest}
    />
  )
);

export default SearchControl;
