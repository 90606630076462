import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Select,
  Switch,
  Typography,
  message,
} from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';

import { useAdminProcessTemplateController_findAll } from '@api-client/generated/AdminProcessTemplateController/findAll';
import { useAdminUserController_getUsers } from '@api-client/generated/AdminUserController/getUsers';
import { Schemas } from '@api-client/generated/types';
import ProcessesTemplatesListItem from '@entities/processes/ProcessesTemplatesListItem';

import * as S from './styled';

type StepRequestDto = Schemas.StepRequestDto;
type StepResponseDto = Schemas.StepResponseDto;
type ProcessTemplateResponseDto = Schemas.ProcessTemplateResponseDto;
type StepUpdateRequestDto = StepRequestDto & { id?: string };

const { Text, Title } = Typography;

const types = [
  {
    label: 'Action',
    value: 'action',
  },
  {
    label: 'Notification',
    value: 'notification',
  },
];

const hours = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '1h',
    value: 1,
  },
  {
    label: '2h',
    value: 2,
  },
  {
    label: '3h',
    value: 3,
  },
  {
    label: '4h',
    value: 4,
  },
];

const reminders = [
  {
    label: 'Every week',
    value: 7 * 24 * 60,
  },
];

type ProcessesTemplatesEditFormProps = {
  isEditable?: boolean;
  template: ProcessTemplateResponseDto;
  step: Partial<StepResponseDto>;
  onUpdateStep: (values: StepUpdateRequestDto) => Promise<void>;
};

const ProcessesTemplatesEditForm = (props: ProcessesTemplatesEditFormProps) => {
  const [form] = Form.useForm<Schemas.StepRequestDto>();

  const { data: users } = useAdminUserController_getUsers({});

  const { data: templates } = useAdminProcessTemplateController_findAll({
    params: {
      stepId: props.step.id,
    },
  });

  useEffect(() => {
    form.resetFields();
  }, [props.step.id, form]);

  const assigneeOptions = useMemo(
    () =>
      users?.map(({ id, firstName, lastName }) => ({
        label: `${firstName} ${lastName}`,
        value: id,
      })) || [],
    [users]
  );

  const existingTemplates = useMemo(
    () =>
      templates?.records.filter(
        (template) => template.id !== props.template.id
      ),
    [templates, props.template.id]
  );

  const type = Form.useWatch('type', form);

  const handleUpdateStep = (id?: string) => {
    form.validateFields().then(async (values) => {
      try {
        await props.onUpdateStep({
          id,
          ...values,
        });
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          const { response } = error;
          if (response) {
            const { data } = response;
            if (data.error?.name?.includes('taken')) {
              message.error('Step with this name already exists');
              form.setFields([
                {
                  name: 'name',
                  errors: ['Step with this name already exists'],
                },
              ]);
            }
          }
        }
      }
    });
  };

  return (
    <S.FormWrapper>
      <Form layout="vertical" form={form} initialValues={props.step}>
        <Flex gap={20} vertical>
          <Text strong>Step details</Text>
          <Flex gap={32} vertical>
            <div>
              <Form.Item label="Type" name="type" required>
                <Select size="large" options={types} />
              </Form.Item>
              {type && (
                <>
                  <Divider />
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                    required
                  >
                    <Input size="large" />
                  </Form.Item>
                  <Flex gap={20}>
                    <Flex flex="1 1 50%">
                      <S.FullFormItem
                        label="Assignee"
                        name="assigneeId"
                        required
                      >
                        <Select size="large" options={assigneeOptions} />
                      </S.FullFormItem>
                    </Flex>
                    <Flex flex="1 1 50%">
                      <S.FullFormItem
                        label="SLA"
                        name="hoursToComplete"
                        required
                      >
                        <Select
                          size="large"
                          options={hours}
                          placeholder="Hours"
                        />
                      </S.FullFormItem>
                    </Flex>
                  </Flex>
                  <Form.Item label="Expected result" name="result">
                    <Input.TextArea size="large" rows={3} />
                  </Form.Item>
                  {type === 'notification' && (
                    <Form.Item
                      label="Remind about checking respond"
                      name="reminderInterval"
                      required
                    >
                      <Select size="large" options={reminders} />
                    </Form.Item>
                  )}
                  <S.Group gap={16} vertical>
                    <Flex gap={12}>
                      <Form.Item name="isRequiredNotes" noStyle>
                        <Switch />
                      </Form.Item>
                      <Text>Assignee must add the summary to Activity log</Text>
                    </Flex>
                    <Flex gap={12}>
                      <Form.Item name="isRequiredFiles" noStyle>
                        <Switch />
                      </Form.Item>
                      <Text>Assignee must add files to Activity log</Text>
                    </Flex>
                  </S.Group>
                </>
              )}
            </div>
            {type &&
              (existingTemplates?.length && props.step?.id ? (
                <S.Templates gap={32} vertical>
                  <Flex gap={16} vertical>
                    <Flex gap={6} vertical>
                      <Title level={4}>
                        This step is added to {existingTemplates.length}{' '}
                        template
                        {(existingTemplates.length || 0) > 1 && 's'}
                      </Title>
                      <Text>
                        The current step will be updated if you apply the new
                        changes. If you want to keep them unchanged, you need to
                        edit the name and simply save this step as new.
                      </Text>
                    </Flex>
                    {props.step?.id &&
                      existingTemplates?.map((template) => (
                        <ProcessesTemplatesListItem
                          key={template.id}
                          template={template}
                          isSelected={false}
                          onSelect={() => {}}
                        />
                      ))}
                  </Flex>
                  <Flex gap={16}>
                    <S.SaveStepButton
                      size="large"
                      onClick={() => handleUpdateStep(props.step.id)}
                    >
                      Apply to all the templates
                    </S.SaveStepButton>
                    <S.SaveStepButton
                      size="large"
                      onClick={() => handleUpdateStep()}
                    >
                      Save the step as new
                    </S.SaveStepButton>
                  </Flex>
                </S.Templates>
              ) : (
                <Flex justify="flex-end">
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    onClick={() => handleUpdateStep(props.step.id)}
                  >
                    Save step
                  </Button>
                </Flex>
              ))}
          </Flex>
        </Flex>
      </Form>
    </S.FormWrapper>
  );
};

export default ProcessesTemplatesEditForm;
