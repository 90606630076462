import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Header = styled(Flex)<FlexProps>`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  border-radius: 10px 10px 0 0;
  padding: 0 30px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 12px;
`;
