import { Button, Flex, Tabs, Typography } from 'antd';
import { forwardRef } from 'react';
import { IntersectionObserverHookRefCallback } from 'react-intersection-observer-hook';

import { Schemas } from '@api-client/generated/types';
import { Loader } from '@components';
import { SearchControl } from '@entities/index';
import { ProcessesTemplatesListTab } from '@entities/processes/ProcessesTemplatesList/types';
import ProcessesTemplatesListItem from '@entities/processes/ProcessesTemplatesListItem';

import * as S from './styled';

type ProcessTemplateListResponseDto = Schemas.ProcessTemplateListResponseDto;

const { Text } = Typography;

const tabs = [
  {
    label: 'All',
    key: ProcessesTemplatesListTab.All,
  },
  {
    label: 'Published',
    key: ProcessesTemplatesListTab.Published,
  },
  {
    label: 'Unpublished',
    key: ProcessesTemplatesListTab.Unpublished,
  },
];

type ProcessesTemplatesListProps = {
  sentryRef: IntersectionObserverHookRefCallback;
  isLoading: boolean;
  search: string;
  currentTab: ProcessesTemplatesListTab;
  templates: ProcessTemplateListResponseDto[];
  selected: ProcessTemplateListResponseDto | null;
  hasNextPage: boolean;
  onSearch: (value: string) => void;
  onSelect: (template: ProcessTemplateListResponseDto) => void;
  onTabChange: (tab: ProcessesTemplatesListTab) => void;
  onAddTemplateClick: () => void;
};

const ProcessesTemplatesList = forwardRef<
  HTMLDivElement,
  ProcessesTemplatesListProps
>((props, ref) => (
  <S.Root gap={16} vertical>
    <Flex gap={24} vertical>
      <Tabs
        items={tabs}
        activeKey={props.currentTab}
        onChange={(key: string) =>
          props.onTabChange(key as ProcessesTemplatesListTab)
        }
      />
      <SearchControl
        value={props.search}
        onChange={(event) => props.onSearch(event.target.value)}
        fitWidth
      />
    </Flex>
    {props.isLoading && <Loader />}
    {props.templates.length === 0 && !props.isLoading && (
      <S.EmptyScreen align="center" justify="center" gap={24} vertical>
        <Text strong>There are no templates yet</Text>
        <Button type="primary" onClick={props.onAddTemplateClick}>
          Add template
        </Button>
      </S.EmptyScreen>
    )}
    {
      <S.Body ref={ref} vertical gap={8}>
        {props.templates.map((template) => (
          <ProcessesTemplatesListItem
            key={template.id}
            template={template}
            isSelected={props.selected === template}
            onSelect={() => props.onSelect(template)}
          />
        ))}
        {props.hasNextPage && <div ref={props.sentryRef} />}
      </S.Body>
    }
  </S.Root>
));

export default ProcessesTemplatesList;
