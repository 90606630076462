import { Button, Form, Input } from 'antd';
import { FC } from 'react';

import { useAdminAuthController_login } from '@api-client/generated/AdminAuthController/login';
import { Schemas } from '@api-client/generated/types';
import { useAccount } from '@hooks';

import * as S from './styled';

const Login: FC = () => {
  const { isLogged } = useAccount();
  const { mutate: logIn } = useAdminAuthController_login();

  const handleSubmit = (values: Schemas.AdminLoginDto) =>
    logIn(
      { requestBody: values },
      {
        onSuccess: () => {
          location.replace('/companies');
        },
      }
    );

  if (isLogged) return;

  return (
    <S.Inner>
      <S.Heading>EasyBiz</S.Heading>

      <Form onFinish={handleSubmit} layout="vertical" requiredMark={false}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please enter your email!' }]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </S.Inner>
  );
};

export default Login;
