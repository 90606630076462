import qs, { type ParseOptions, type StringifyOptions } from 'query-string';

const options: ParseOptions | StringifyOptions = {
  arrayFormat: 'bracket-separator',
  arrayFormatSeparator: '|',
  parseNumbers: true,
  parseBooleans: true,
  encode: true,
  skipEmptyString: true,
  skipNull: true,
};

export const setSearchParams = <T>(data: T | undefined) =>
  qs.stringify(data || {}, options);

export const getSearchParams = <T>(search: string): T =>
  qs.parse(search, options) as T;
