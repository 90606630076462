import styled from '@emotion/styled';

import {
  type FormItemProps,
  type InputNumberProps,
  type InputProps,
  InputNumber,
  Input,
  Form,
} from 'antd';

export const FormItem = styled(Form.Item)<FormItemProps>`
  && {
    margin-bottom: 16px;
  }
`;

export const InputControl = styled(Input)<InputProps>`
  && {
    .ui-input-group-addon {
      padding-inline: 0;
    }
  }
`;

export const InputNumberControl = styled(InputNumber)<InputNumberProps>`
  && {
    width: 100%;
  }
`;
