//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Params$AdminProcessTemplateController_publish } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminProcessTemplateController_publish = () =>
  useApiClientMutation<Params$AdminProcessTemplateController_publish, never>({
    method: 'AdminProcessTemplateController_publish',
  });
