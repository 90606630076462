//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminOnfidoDataController_create,
  Response$AdminOnfidoDataController_create$Status$201,
  ResponseContentType$AdminOnfidoDataController_create,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminOnfidoDataController_create = () =>
  useApiClientMutation<
    Params$AdminOnfidoDataController_create,
    Response$AdminOnfidoDataController_create$Status$201[ResponseContentType$AdminOnfidoDataController_create]
  >({ method: 'AdminOnfidoDataController_create' });
