import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

type ContainerProps = {
  padding?: number;
  minHeight?: number;
  minHeightSuffix?: 'vh' | 'px' | '%';
};

export const Container = styled.div<ContainerProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border: 1px solid ${({ theme }) => theme.colors.bgGrey};
  padding: ${({ padding }) =>
          padding || padding === 0 ? `${padding}px` : '30px'};
  min-height: ${({ minHeight, minHeightSuffix }) =>
          minHeight ? `${minHeight}${minHeightSuffix}` : 'unset'};
`;

export const Title = styled.h3`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
`;
