//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminBobVATController_findAll,
  Response$AdminBobVATController_findAll$Status$200,
  ResponseContentType$AdminBobVATController_findAll,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminBobVATController_findAll = (options: {
  params: Params$AdminBobVATController_findAll['parameter'];
  config?: QueryOptions<
    Response$AdminBobVATController_findAll$Status$200[ResponseContentType$AdminBobVATController_findAll]
  >;
}) =>
  useApiClient<
    Params$AdminBobVATController_findAll,
    Response$AdminBobVATController_findAll$Status$200[ResponseContentType$AdminBobVATController_findAll]
  >({
    method: 'AdminBobVATController_findAll',
    params: { parameter: options.params },
    config: options.config,
  });
