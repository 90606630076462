import styled from '@emotion/styled';

import { colors } from '@theme';

import { type Status, type StatusCircleProps } from './StatusCircle';

const getCircleSize = (size?: number) => (size ? `${size}px` : '24px');

const getBadgeColorByStatus = (status?: Status, active?: boolean) => {
  if (status === 'completed' || status === 'inProgress' || active) {
    return colors.primary;
  }

  if (status === 'errorsFound') {
    return colors.error;
  }

  return colors.secondary;
};

export const Wrapper = styled.div``;

export const Circle = styled.div<StatusCircleProps>`
  min-width: ${({ size }) => getCircleSize(size)};
  width: ${({ size }) => getCircleSize(size)};
  height: ${({ size }) => getCircleSize(size)};
  border-radius: 50%;
  background-color: ${({ active, status }) =>
    getBadgeColorByStatus(status, active)};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
`;
