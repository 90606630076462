import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { setSearchParams } from '@utils';

import * as S from './styled';

type TaxesEmptyProps = {
  period?: string;
  year?: string;
};

const TaxesEmpty: FC<TaxesEmptyProps> = ({ period, year }) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.Title>There are no taxes information yet</S.Title>
      <S.Details>Upload taxes to display them for the customer</S.Details>

      <Button
        type="primary"
        icon={<PlusOutlined />}
        size="large"
        onClick={() =>
          navigate(
            period
              ? `/taxes/create?${setSearchParams({ period, year })}`
              : '/taxes/create'
          )
        }
        ghost
      >
        Add tax
      </Button>
    </S.Container>
  );
};

export default TaxesEmpty;
